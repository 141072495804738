import 'rc-slider/assets/index.css';

import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // Utility function to get the cropped image
import Slider from 'rc-slider';

class CropImageComponent extends Component {
	state = {
		crop: { x: 0, y: 0 },
		zoom: 1,
		croppedAreaPixels: null,
	};

	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({ croppedAreaPixels });
	};

	onCrop = async () => {
		const { imageSrc, onCropComplete, inputType } = this.props;
		const { croppedAreaPixels } = this.state;
		const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
		onCropComplete(croppedImage, inputType);
	};

	handleZoomChange = (zoom) => {
		this.setState({ zoom });
	};

	render() {
		const { imageSrc } = this.props;
		const { crop, zoom } = this.state;

		return (
			<div className='row'>
				<div className='col-12'>
					<div className="upload-img-popup">
					<div className="crop-container">
						<Cropper
							image={imageSrc}
							crop={crop}
							zoom={zoom}
							aspect={1}
							onCropChange={this.onCropChange}
							onZoomChange={this.onZoomChange}
							onCropComplete={this.onCropComplete}
							cropSize={{ width: 320, height: 320 }}
							objectFit="contain"
						/>
					</div>
					<div className='crop-btn text-center' >
						<Slider
							value={zoom}
							min={0.5}
							max={3}
							step={0.1}
							onChange={(zoom) => this.handleZoomChange(zoom)}
						/>
						<button type='button' onClick={this.onCrop} className="btn btn-fill">Crop</button>
					</div>
					</div>
				</div>
			</div>
		);
	}
}
export default CropImageComponent;