import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import Auth from '../../helpers/Auth';

import EllipsisPagination from './../Pagination';
import { callBackendAPI, handleFormInputs, getValueByObjectOrArray, upperCaseOfName, customConfirmBox, reFormatSuccess, setOrGetCookiesValue} from './../../helpers/common';

import { translate, formatDate } from './../../helpers/intl_helpers';

import BulkEmployeeModal from './BulkEmployee';
import EmployeeDetailModal from './EmployeeDetailModal';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DEPARTMENT_DROPDOWN, EXPORT_CSV, EXPORT_PDF, USER_ACTIVE_DEACTIVE_DROPDOWN, ACTIVE, FOREMAN, PROJECT_MANAGER, ADMIN, DISPATCHER, JOB_TITLE_TYPE_DROPDOWN, PERMISSION_LEVEL_DROPDOWN, DATERANGE_DATE_TIME_FORMAT } from './../../config/global_constants';

import closeIcon 	from './../../assets/images/close_icon.svg';
import filterImg 	from './../../assets/images/filter.svg';
import loaderGif 	from './../../assets/images/pagination_loader.gif';
import editIcon 	from '../../assets/images/edit-icon.svg';
import deleteIcon 	from '../../assets/images/delete-icon.svg';
import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';

let defaultSelectedFields = ["image","full_name","mobile","job_title","department","location_title","assigned_vehicle","active"];
/** Listing Fields */
let jobFields = [
	{ value: 'image', label: translate('employee.image'), sortable: true},
	{ value: 'full_name', label: translate('employee.employee_name'), sortable: true},
	{ value: 'mobile', label: translate('employee.mobile_number') },
	{ value: 'job_title', label: translate('employee.job_title') },
	{ value: 'department', label: translate('employee.department'), sortable: true },
	{ value: 'location_title', label: translate('employee.location'), sortable: true },
	{ value: 'assigned_vehicle', label: translate('employee.assigned_vehicle'), sortable: true },
	{ value: 'active', label: translate('employee.status'), sortable: true },
	{ value: 'email', label: translate('employee.email'), sortable: true },
	{ value: 'company_name_title', label: translate('employee.company_name'), sortable: true },
	{ value: 'job_title_type', label: translate('employee.job_title_type'), sortable: true },
	{ value: 'skill_set_name', label: translate('employee.skill_sets'), sortable: true },
	{ value: 'driver_tier_level', label: translate('employee.driver_tier_level'), sortable: true },
	{ value: 'permission_level', label: translate('employee.permission_level'), sortable: true },
	{ value: 'termination_date', label: translate('employee.termination_date'), sortable: true },
	{ value: 'hire_date', label: translate('employee.hire_date'), sortable: true },
];

class EmployeeList extends Component {

	constructor(props) {
        super(props);

		/** Get search stats from cookies  */
		let tmpSearchStats = {status :{value : ACTIVE, label:"Active"}};
		let tmpVal =  setOrGetCookiesValue('searchStatsEmployeeList');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length) tmpSearchStats = tmpVal;

        this.state = {
			rowsPerPage		: 	PAGING_LENGTH_MENU[4],
			selectedFields	: 	defaultSelectedFields,
			searchStats 	: 	tmpSearchStats,
            jobList			: 	[],
			totalRecords	: 	0,
			totalPages		:	0,
			isLoading		:	true,
			currentPage		:	1,
			locationList	:	[],
			sortStats		:	{},
			showBulkModel 	:	false,
			fileUrl 		:	"",
			noImgUrl		: 	"",
			showDetailModal	: 	false,
			profileDetails  : 	"",
        };

		this.getEmpList    			=	this.getEmpList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.getImage				=	this.getImage.bind(this);
		this.handleDeleteEmployee 	=	this.handleDeleteEmployee.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getMasterList();
		this.getEmpList();
    }

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getEmpList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getEmpList();
		});
	};

	hideModal = () => {
		this.setState({
			showBulkModel 	:	false,
			equipmentId		: 	"",
		});
	}

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({
			searchStats	: {},
			currentPage	: 1,
		},()=>{
			this.getEmpList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	* For update state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "selectedFields"){
			let tmpArray = [];
			if(fieldVal){
				fieldVal = fieldVal.constructor !== Array  ? [fieldVal] :fieldVal;

				tmpArray = fieldVal.map(element=>{ return element.value });
			}

			if(tmpArray.length >= 5) this.setState({selectedFields: tmpArray});
		}else{
			let newObj = {[fieldName]: fieldVal};
			let updatedFields = {...this.state.searchStats, ...newObj};

			if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;
			if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

			this.setState({searchStats: updatedFields});
		}
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}// end getMasterList()

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'home', method: 'masterData', name: inputValue ? inputValue : ''};
            if(type === "job_title") 	apiReq.type = "job_title";

			/** Add page number */
            apiReq.current_page = page;
			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For get job listing
	*/
	getEmpList = () => {
		const { rowsPerPage, currentPage, searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'employee', method: 'list', limit: rowsPerPage, start: skip};
			apiReq = {...apiReq, ...{sort_by: sortStats.key ? sortStats.key : "full_name", sort_direction: sortStats.dir ? sortStats.dir : "asc"} };
			if(searchStats && Object.keys(searchStats).length){
				apiReq.status	 		=	searchStats.status 			? searchStats.status.value 			: ACTIVE;

				if(searchStats.location && searchStats.location.length) 	  	apiReq.location	 	=	searchStats.location.map(element => element.value);
				if(searchStats.department && searchStats.department.length) 	apiReq.department  	=	searchStats.department.map(element => element.value);
				if(searchStats.job_title && searchStats.job_title.value) 		apiReq.job_title  	=	searchStats.job_title.value;
				if(searchStats.full_name && searchStats.full_name.value) 		apiReq.full_name  	=	searchStats.full_name.value;

			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsEmployeeList', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						fileUrl 	:	response.data[0].file_url,
						jobList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getEmpList()

	getImage = (rowData) =>{
		const { fileUrl } = this.state;
		let completeImgUrl 	= fileUrl;
		let imgName 		= rowData.image 			? rowData.image 			: "";
		let employeeName	= upperCaseOfName(rowData.first_name ? rowData.first_name : "", rowData.last_name ? rowData.last_name : "");

		let nameHtml =<span className="sort-name greenbg">
			{employeeName}
		</span>;
		let employeeImg  = (imgName) ? completeImgUrl+imgName : "";
		let image  		 = (imgName) ? <img src={employeeImg} alt="Img"  className="sort-name" onClick = {()=>{this.handleImageClick(employeeImg)}}/> : nameHtml;

		return image;
	}

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats, selectedFields, sortStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		let updatedFields = selectedFields.filter(field => field !== "image");

		/** Set api params */
		let apiReq = {model: 'employee', method: 'exportList', file_type: fileType, fields: updatedFields};
		if((Auth.getPermission() !== ADMIN && Auth.getPermission() !== DISPATCHER)){
			apiReq = {model: 'employee', method: 'employeeExports', file_type: fileType, fields: updatedFields};
		}
		apiReq = {...apiReq, ...{sort_by: sortStats.key ? sortStats.key : "full_name", sort_direction: sortStats.dir ? sortStats.dir : "asc"} };

		if(searchStats && Object.keys(searchStats).length){
			apiReq.status	 		=	searchStats.status 			? searchStats.status.value 			:ACTIVE;

			if(searchStats.location && searchStats.location.length) 	  	apiReq.location	 	=	searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	apiReq.department  	=	searchStats.department.map(element => element.value);
			if(searchStats.job_title && searchStats.job_title.value) 		apiReq.job_title  	=	searchStats.job_title.value;
			if(searchStats.full_name && searchStats.full_name.value) 		apiReq.full_name  	=	searchStats.full_name.value;
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const link 		=	document.createElement('a');
					link.href 		=	response.data[0].file_url;
					link.rel 		=	'noopener noreferrer'; // Security for '_blank' links
					document.body.appendChild(link); // Required for Firefox
					link.click();
					document.body.removeChild(link);
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}

	/** Function to handle delete button click */
	handleDeleteEmployee = (employeeId) => {
		const confirmation = customConfirmBox(translate('employee.you_want_to_delete_employee'));
		confirmation.then((confirmed) => {
			if(confirmed) {
				callBackendAPI([{ model: 'employee', method: 'deleteOne', employee_id: employeeId }]).then((response) => {
					if(response.success) {
						reFormatSuccess(response.data[0].message);
						this.getEmpList()
					}
				}).catch(err => console.log(err));
			}
		});
	}

	openDetailModal = (userDetails) => {
		this.setState({ showDetailModal: true, profileDetails: userDetails },()=>{
			window.openModal('employeeDetailModal');
		});
	};

	// Close the modal
	closeModal = () => {
		this.setState({ showDetailModal: false, profileDetails: null });
	};

	render() {
		const {jobList, searchStats, currentPage, rowsPerPage, totalPages, isLoading,locationList, selectedFields, showBulkModel, lightBoxShow, selectedImage, showDetailModal, profileDetails,  fileUrl , noImgUrl} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("employee.employee_list")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
						{(Auth.getPermission() !== FOREMAN) &&
							<>
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={() => this.props.history.push('/employee/add')}>{translate("employee.add_employee")}</Link>
								<Link to="#" className="btn btn-fill" onClick={() => this.setState({showBulkModel: true})}>{translate("employee.bulk_employee")}</Link>
							</>
						}
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_employee" className="form-label mb-1">{translate('employee.full_name')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('employee.full_name')} id='search_by_employee' value={searchStats["full_name"] ? searchStats["full_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"full_name")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('employee.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('employee.location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('employee.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="employee">{translate('employee.job_title')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_job_number"
														key={searchStats.job_title  ? searchStats.job_title : null}
														value={searchStats.job_title  ? searchStats.job_title : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("job_title")}
														additional={{ page: 1, type: "job_title"}}
														isClearable={true}
														placeholder={translate('employee.job_title')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="statud">{translate('employee.status')}</label>
													<Select
														className="custom-react-select mx-1"
														options={USER_ACTIVE_DEACTIVE_DROPDOWN}
														value={searchStats.status ? searchStats.status : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("status")}
														placeholder={<label htmlFor="is_flagger">{translate("employee.status")}</label>}
														isClearable={true}
													/>
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<div className="form-group d-flex align-items-center table-count">
								{translate("system.show")}
								<Select
									className="custom-react-select mx-1"
									options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
									value={{label:rowsPerPage, value:rowsPerPage}}
									closeMenuOnSelect={true}
									onChange={this.handlePageLengthChange}
								/>
								{translate("system.entries")}
							</div>
						</div>
						<div className="col-lg-9 text-end my-md-0 my-3">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							</div>
						</div>
						{(Auth.getPermission() == ADMIN || Auth.getPermission() === DISPATCHER) ?
							<div className="col-lg-12 mt-4">
								<div className="form-group">
									<Select
										className="custom-react-select form-control input-with-label table-multi-select"
										inputId="search_by_location"
										value={jobFields.filter(element => selectedFields.includes(element.value))}
										options={jobFields}
										onChange={this.handleChange("selectedFields")}
										closeMenuOnSelect={true}
										isMulti={true}
										placeholder={translate('reports.search_by_location')}
									/>
								</div>
							</div>
						: null}
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									{selectedFields.map((key, colIndex)=>
										<th key={"head-column-"+colIndex} width='15%'>
											{getValueByObjectOrArray(key, jobFields, "value","label")}
										</th>
									)}
									{(Auth.getPermission() !== PROJECT_MANAGER && Auth.getPermission() !== FOREMAN) ?
										<th width='10%'>
											{translate('system.action')}
										</th>
									: null }
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								:
								jobList && jobList && jobList.length  ?
									jobList.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											{selectedFields.map((key, keyIndex)=>
												<td key={"row-value"+rowIndex+keyIndex}>
													{
														key === "full_name" ?
															(
																<Link
																	to="#"
																	className="user-type-link text-black"
																	onClick={(e) => {
																		e.preventDefault();
																		this.openDetailModal(row);
																	}}
																>
																	{row[key] ? row[key] : ""}
																</Link>
															)
														:
														key === "image" ?
															this.getImage(row)
														:
														row[key] && key === "department" ?
														(
															DEPARTMENT_DROPDOWN.filter(department => row[key].includes(department.label.toLowerCase()))
															.map(department => department.label)
															.join(',')
														)
														:
														key === "active" ?
															<span className ={row[key] ? "text-success" : "text-danger"}>
																{(row[key] === ACTIVE)	? 	"Active" : "Terminated"}
															</span>
														:
														key === "email" ?
															row[key] ? (
															  <>
																<img src={mailIcon} alt="img" width={19} height={21} />
																<a href={`mailto:${row[key]}`} className="text-lowercase email-link">
																  {row[key]}
																</a>
															  </>
															) : ""
														:
														row[key] && key === "mobile" ?
															row[key] ? (
																<a href={`tel:${row[key]}`}>
																	<img src={callIcon} alt="Call icon" width={19} height={21} />
																	{row[key]}
																</a>
															) : null
														:
														row[key] && key === "job_title_type" ? (
															JOB_TITLE_TYPE_DROPDOWN.find(item => item.value === row[key])?.label || null
														)
														:
														row[key] && key === "permission_level" ? (
															PERMISSION_LEVEL_DROPDOWN.find(item => item.value === row[key])?.label || null
														)
														:
														row[key] && key === "termination_date" ? (
															row[key] ? formatDate(row[key] , DATERANGE_DATE_TIME_FORMAT) : ""
														)
														:
														row[key] && key === "hire_date" ? (
															row[key] ? formatDate(row[key] , DATERANGE_DATE_TIME_FORMAT) : ""
														)
														:
														row[key] ? row[key] : "-"
													}
												</td>
											)}
											{(Auth.getPermission() !== PROJECT_MANAGER && Auth.getPermission() !== FOREMAN) ?
												<td>
													<div className="moreaction">
														<Link to="#" onClick={() => this.props.history.push('/employee/update/' + row._id)}>
															<img src={editIcon} alt="img" width={21} height={21} />
														</Link>
														<Link to="#">
															<img src={deleteIcon} alt="img" width={19} height={21} onClick={() => this.handleDeleteEmployee(row._id)} />
														</Link>
													</div>
												</td>
											: null }
										</tr>
									)
								:
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>
					{jobList.length && totalPages >1 ?
						<div className='d-flex justify-content-center align-items-center'>
							<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
						</div>
					:null}
				</div>
			</div>
			{showBulkModel ?
				<BulkEmployeeModal hideModal={this.hideModal} />
			:null}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
			{/*Detail Modal component */}
			{showDetailModal ?
				<EmployeeDetailModal profileDetails={profileDetails} fileUrl={fileUrl} noImgUrl={noImgUrl} />
			: null}
		</>
	}
}
export default EmployeeList;
