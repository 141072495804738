import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { translate } from "./../helpers/intl_helpers";
import { PublicClientApplication } from "@azure/msal-browser";
import { UserDetails } from "../UserDetails";
import { WEBSITE_URL, STATUS_SUCCESS } from "./../config/global_constants";
import { callBackendAPI } from '../helpers/common';

import logoIcon from '../assets/images/blended_cannon_logo.svg';

/** Azure SSO login configuration */
const msalConfig = {
    auth: {
        clientId    :   process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority   :   "https://login.microsoftonline.com/" + process.env.REACT_APP_AZURE_AD_TENANT,
        redirectUri :   WEBSITE_URL+"login",
        postLogoutRedirectUri :   WEBSITE_URL,
    },
    cache: {
        cacheLocation           :   "sessionStorage",
        storeAuthStateInCookie  :   false,
    },
    system: {
        loggerOptions: {
            logLevel: "info",
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
            },
        },
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default class Login extends Component {
    static contextType = UserDetails;

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            fields: {},
            submitted: false,
        };
    }

    async componentDidMount() {
        await msalInstance.initialize();
        this.handleAzureRedirect();
    }

    handleAzureRedirect = async () => {
        try {
            let response = await msalInstance.handleRedirectPromise();

            if(response && response.idToken && response.idToken !== ""){
                window.showLoader();
                callBackendAPI([{ model: 'registration', method: 'ssoLogin', token: response.idToken }]).then(response => {
                    window.hideLoader();

                    if (response.success && response.data[0].status === STATUS_SUCCESS) {
                        let resData = response.data[0];
                        let cookieUser = {
                            _id: resData.result._id,
                            full_name: resData.result.full_name,
                            first_name: resData.result.first_name,
                            last_name: resData.result.last_name,
                            email: resData.result.email,
                            mobile_number: resData.result.mobile_number,
                            department: resData.result.department,
                            location: resData.result.location,
                            slug: resData.result.slug,
                            role_id: resData.result.role_id,
                            profile_image: (resData.result.image) ? resData.result.image : "",
                            token: (resData.token) ? resData.token : "",
                            image_url: (resData.result.file_url) ? resData.result.file_url : "",
                            permission_level: (resData.result.permission_level) ? resData.result.permission_level : "",
                            login_with_sso: true,
                        };

                        const cookies = new Cookies();
                        cookies.set('user_details', cookieUser, { path: "/" });
                        this.context.updateUserState(cookieUser, () => {
                            this.props.history.push('/calendar');
                        });
                    }
                }).catch(err => console.log(err));
            }
        } catch (error) {
            /** Write logs */
            callBackendAPI([{ model: 'home', method: 'writeLogs', error : error.toString(), componentStack: "sso login"}],{},false).then(()=>{}).catch(err => console.log(err));
        }
    };

    loginWithAzure = async () => {
        try {
            msalInstance.loginRedirect({
                scopes: ["openid", "profile", "email"], // Add any other scopes needed
            });
        } catch (error) {
            console.error("Error opening login popup:", error);
        }
    };

    render() {
        return (
            <div className="login-page loginsso">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="login-box text-center">
                                <figure>
                                    <img src={logoIcon}alt="Logo" width={300} height={180} />
                                </figure>
                                <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
                                    <Link to="#" className="btn btn-fill w-100" onClick={() => this.loginWithAzure()} >
                                        {translate("login.sso_login")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}