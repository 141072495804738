import React, { Component } from "react";
import { translate, formatDate  } from './../../helpers/intl_helpers';
import { callBackendAPI, toTitleCase } from './../../helpers/common';

import NormalDataTable from './../NormalDataTable';
import { DATERANGE_DATE_TIME_FORMAT}  from './../../config/global_constants';

class CustomerJobList extends Component {
	constructor(props) {
        super(props);

		this.getDropDownList =   this.getDropDownList.bind(this);
    }

	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "view_job" && rowData._id){
			this.props.history.push('/customer/' + rowData._id);
		}
		if(actionKey === "back"){
			this.props.history.push('/customer');
		}
	}

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";
		let element = "";

		if(actionKey === "shift_type"){
			element = (rowData.shift_type) ? toTitleCase(rowData.shift_type) : "";
		}
		if(actionKey === "date"){
			element	= rowData.date ?	formatDate(rowData.date , DATERANGE_DATE_TIME_FORMAT)  :"";
		}
		return element;
	};// end customRender()

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }) {
        try{
			const customerCode  = this.props.match.params ? this.props.match.params.customer_code : "";
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "project_manager_id")  	apiReq.is_project_manager   =   true;
			if(type === "job_number") apiReq = {model: 'calendar', method: 'cannonJobList', customer_code: customerCode, job_number: inputValue ? inputValue :''};

			if(type === "work_order_number"){
				let jobId = searchStats && searchStats.job_number && searchStats.job_number.value ? searchStats.job_number.value :'';

				if(!jobId) return {options: [], hasMore: true, additional: {page: page, type: type }};

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobId, work_order_number: inputValue ? inputValue : '' };
			}

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	render() {
		const customerCode  = this.props.match.params ? this.props.match.params.customer_code : "";
		let pageStats = {
			tableId :	'customerJobList',
			title 	:	translate("customer.customer_jobs"),
			back_url:	'/customer',
			headerLinks	: [
				{name: translate("system.back"), selector: "back", clickEvent: this.customAction }
			],
			columns :	[
				{name: 	translate("customer.job_number"), selector: "job_number", width: "10%", defaultSort: true, sortable: true, direction: "asc"},
				{name: 	translate("customer.work_order_number"), selector: "work_order_number", width: "10%", sortable: true },
				{name: 	translate("reports.project_manager"), selector: "project_manager_name", width: "15%"},
				{name: 	translate("customer.foreman"), selector: "foreman_name", width: "15%",},
				{name: 	translate("customer.shift_type"), selector: "shift_type", width: "15%", customRender: this.customRender},
				{name: 	translate("customer.date"), selector: "date",  width: "15%", is_date :true, sortable: true},
			],
			search : [
				{name: translate("customer.job_number"), selector: "job_number", input_type: "asyncPaginate", child_selector:"work_order_number" },
				{name: translate("customer.work_order_number"), selector: "work_order_number", 	input_type: "asyncPaginate", parent_selector:"job_number" },
				{name: translate("reports.project_manager"), selector: "project_manager_id", 	input_type: "asyncPaginate"},
			]
		}

		return <>
			<NormalDataTable pageStats={pageStats}  apiExport={{model: 'customer', method: 'customerJobExports',allRecords : true, customer_code: customerCode }}  apiParm={{model: 'customer', method: 'customerJobList', customer_code: customerCode}} getDropDownList={this.getDropDownList} />
		</>
	}
}
export default CustomerJobList;
