import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import { translate, formatDate, formatTime  } from '../../../helpers/intl_helpers';
import { DATERANGE_DATE_TIME_FORMAT } from '../../../config/global_constants'

import callIcon 	from '../../../assets/images/call-icon.svg';
import mailIcon 	from '../../../assets/images/mail-icon.svg';

export default class AssetDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('assetDetailsModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { assetDetails } = this.props;
		const { lightBoxShow, selectedImage } = this.state;
		let detailType = assetDetails && assetDetails.state && assetDetails.state.type ? assetDetails.state.type : "";
		let shopDetails = assetDetails && assetDetails.state && assetDetails.state.shop_details ? assetDetails.state.shop_details : "";
		let assetNotes = shopDetails && shopDetails.notes && shopDetails.notes.length ? shopDetails.notes[0] : "";

		return (
			<>
			<div className="modal fade  crew-modal" id="assetDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{translate('calendar.details')}</h3>
							{(detailType === "asset") ?
								(<div className="list-group list-group-flush">
									<div className="row">
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.equipment_code')}</p>
												<div dangerouslySetInnerHTML={{__html:assetDetails.state && assetDetails.state.equipment_code ? assetDetails.state.equipment_code : "N/A"}} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.year')}</p>
												<div>{assetDetails.state && assetDetails.state.year ? assetDetails.state.year : "N/A"}</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.make')}</p>
												<div>{assetDetails.state && assetDetails.state.equipment_make ? assetDetails.state.equipment_make : "N/A"}</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.model')}</p>
												<div>{assetDetails.state && assetDetails.state.equipment_model ? assetDetails.state.equipment_model : "N/A"}</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.description')}</p>
												<div>{assetDetails.state && assetDetails.state.description ? assetDetails.state.description : "N/A"}</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.assignee')}</p>
												<div>{assetDetails.state && assetDetails.state.driver_owner_name ? assetDetails.state.driver_owner_name : "N/A"}</div>
											</div>
										</div>
										{assetNotes &&
												<>
													<div className="col-lg-6">
														<div className="list-group-item full-view">
															<p>{translate('calendar.maintenance')}</p>
															<div className="nl2br">{assetNotes && assetNotes.notes ? assetNotes.notes : "N/A"}</div>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="list-group-item full-view">
															<p>{translate('calendar.last_updated_by')}</p>
															<div>{assetNotes && assetNotes.created_by ? assetNotes.created_by : "N/A"}</div>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="list-group-item full-view">
															<p>{translate('calendar.created_at')}</p>
															<div>{assetNotes && assetNotes.created ? formatDate(assetNotes.created,DATERANGE_DATE_TIME_FORMAT) : "N/A"}</div>
														</div>
													</div>
												</>
											}
									</div>
								</div>) :
								(<div className="list-group list-group-flush">
									<div className="row">

										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('employee.image')}</p>
												{assetDetails.state?.image ?
													<div><img src={assetDetails.state.image} alt="Img"  className="sort-name" onClick = {()=>{this.handleImageClick(assetDetails.state.image)}} /></div>
												: "N/A"}
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('employee.full_name')}</p>
												<div>{assetDetails.state && assetDetails.state.full_name ? assetDetails.state.full_name : "N/A"}</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.mobile')}</p>
												<div>
													{assetDetails.state?.mobile ? (
														<>
															<img src={callIcon} alt="img" width={19} height={21} />
															<a href={`tel:${assetDetails.state.mobile}`}>
																{assetDetails.state.mobile}
															</a>
														</>)
													:  "N/A"}
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.email')}</p>
												<div>
													{assetDetails.state?.email ? (
															<>
																<img src={mailIcon} alt="img" width={19} height={21} />
																<a href={`mailto:${assetDetails.state.email}`} className="text-lowercase email-link">
																	{assetDetails.state.email.toLowerCase()}
																</a>
															</>)
													:  "N/A"}
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="list-group-item full-view">
												<p>{translate('calendar.job_title')}</p>
												<div>{assetDetails.state && assetDetails.state.job_title_name ? assetDetails.state.job_title_name : "N/A"}</div>
											</div>
										</div>
									</div>
								</div>)
							}
						</div>
					</div>
				</div>
			</div>
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
			</>
		)
	}
}
