import React, { Component } from "react";
import { upperCaseOfName  } from '../../helpers/common';
import { translate, formatDate } from './../../helpers/intl_helpers';
import { DEPARTMENT_DROPDOWN, JOB_TITLE_TYPE_DROPDOWN, DATERANGE_DATE_TIME_FORMAT, ACTIVE  } from '../../config/global_constants';

import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';

export default class EmployeeDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('employeeDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl } = this.props;

		return (
			<>
			<div className="modal fade crew-modal" id="employeeDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{translate('calendar.details')}</h3><div className="list-group list-group-flush">
								<div className="row">
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.image')}</p>
											<div>
												<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
													{profileDetails && profileDetails.image ? (
														<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
													) : (
														upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
													)}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.employee_name')}</p>
											<div>{profileDetails.full_name ? profileDetails.full_name : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.employee_number')}</p>
											<div>{profileDetails.employee_number ? profileDetails.employee_number : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.mobile')}</p>
											<div>
												<img src={callIcon} alt="img" width={19} height={21} />
												{profileDetails.mobile ? (
													<a href={`tel:${profileDetails.mobile}`}>{profileDetails.mobile}</a>
												) : "N/A"}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.job_title')}</p>
											<div>{profileDetails.job_title ? profileDetails.job_title : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.department')}</p>
											<div>
												{(profileDetails.department && profileDetails.department.length) &&
													DEPARTMENT_DROPDOWN.filter(department => profileDetails.department.includes(department.label.toLowerCase()))
													.map(department => department.label)
													.join(',')
												}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.location')}</p>
											<div>{profileDetails.location_title ? profileDetails.location_title : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.assigned_vehicle')}</p>
											<div>{profileDetails.assigned_vehicle ? profileDetails.assigned_vehicle : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.status')}</p>
											<div>
												<span className={profileDetails.active ? "text-success" : "text-danger"}>
													{profileDetails.active ? "Active" : "Deactive"}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.email')}</p>
											<div>
												<img src={mailIcon} alt="img" width={19} height={21} />
												{profileDetails.email ? (
													<a href={`mailto:${profileDetails.email}`} className="text-lowercase email-link">
														{profileDetails.email}
													</a>
												) : "N/A"}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.company_name')}</p>
											<div>{profileDetails.company_name_title ? profileDetails.company_name_title : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.job_title_type')}</p>
											<div>{JOB_TITLE_TYPE_DROPDOWN.find(item => item.value === profileDetails.job_title_type)?.label || "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.skill_sets')}</p>
											<div>{profileDetails.skill_set_name ? profileDetails.skill_set_name : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.driver_tier_level')}</p>
											<div>{profileDetails.driver_tier_level ? profileDetails.driver_tier_level : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.termination_date')}</p>
											<div>{profileDetails.termination_date ? formatDate(profileDetails.termination_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.hire_date')}</p>
											<div>{profileDetails.hire_date ? formatDate(profileDetails.hire_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.display_on_calendar')}</p>
											<div>
												<span>
													{profileDetails.display_on_calendar === ACTIVE ? "Yes" : "No"}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
