import React, { Component } from 'react';
import {Pagination, Stack} from 'react-bootstrap';

export default class EllipsisPagination extends Component {
	/** Manage Pagination item  */
	getPaginationItems(currentPage, totalPages) {
		const pageLinksToShow = 5;
		const pages = [];
		let startPage, endPage;

		if (totalPages <= pageLinksToShow) {
			startPage = 1;
			endPage = totalPages;
		} else {
			const maxPagesBeforeCurrentPage = Math.floor(pageLinksToShow / 2);
			const maxPagesAfterCurrentPage = Math.ceil(pageLinksToShow / 2) - 1;

			if (currentPage <= maxPagesBeforeCurrentPage) {
				startPage = 1;
				endPage = pageLinksToShow;
			} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
				startPage = totalPages - pageLinksToShow + 1;
				endPage = totalPages;
			} else {
				startPage = currentPage - maxPagesBeforeCurrentPage;
				endPage = currentPage + maxPagesAfterCurrentPage;
			}
		}

		if (startPage > 1) {
			pages.push(
				<Pagination.Item key={1} onClick={() => this.props.onChange(1)}>
					{1}
				</Pagination.Item>
			);
			if (startPage > 2) {
				pages.push(
					<Pagination.Ellipsis key="start-ellipsis" disabled />
				);
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(
				<Pagination.Item
					key={i}
					active={i === currentPage}
					onClick={() => this.props.onChange(i)}
				>
					{i}
				</Pagination.Item>
			);
		}

		if (endPage < totalPages) {
			if (endPage < totalPages - 1) {
				pages.push(
					<Pagination.Ellipsis key="end-ellipsis" disabled />
				);
			}
			pages.push(
				<Pagination.Item key={totalPages} onClick={() => this.props.onChange(totalPages)}>
					{totalPages}
				</Pagination.Item>
			);
		}

		return pages;
	}

	render(){
		const { currentPage, totalPages } = this.props;
		return (
			<Stack>
				<Pagination className='mt-4 mb-4'>
					{currentPage > 1 && (
						<Pagination.Prev onClick={() => this.props.onChange(currentPage - 1)} />
					)}
					{this.getPaginationItems(currentPage, totalPages)}
					{currentPage < totalPages && (
						<Pagination.Next onClick={() => this.props.onChange(currentPage + 1)} />
					)}
				</Pagination>
			</Stack>
		);
	}
}
