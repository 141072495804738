import React, { Component ,Suspense} from "react";
import { translate  } from './../../helpers/intl_helpers';
import NormalDataTable from './../NormalDataTable';
import {STATUS_SUCCESS}  from './../../config/global_constants';
import { callBackendAPI,customConfirmBox, reFormatSuccess, getRandomString} from './../../helpers/common';

import AddEditForm from './AddEditForm';

class WorkOrderList extends Component {
	constructor(props) {
        super(props)

        this.state = {
			showModel		: 	false,
			searchStats 	: 	{},
			tableKey 		:	getRandomString(),
        };

		this.toggleModal 			= 	this.toggleModal.bind(this);
		this.refreshDataTable   	= 	this.refreshDataTable.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.handleDeleteWorkOrder 	=	this.handleDeleteWorkOrder.bind(this);
		this.anchorRef 				=	React.createRef();
    }

	/** Refresh listing data-table */
	refreshDataTable() {
		this.setState({
			tableKey : getRandomString(),
		});
    }

	/** Function to handle delete button click */
	handleDeleteWorkOrder = (workOrderId) => {
		const confirmation = customConfirmBox(translate('work_order.you_want_to_delete_the_work_order'));
		confirmation.then((confirmed) => {
			if(confirmed) {
				callBackendAPI([{ model: 'work_order', method: 'deleteOne', work_order_id: workOrderId }]).then((response) => {
					if(response.success) {
						reFormatSuccess(response.data[0].message);
						this.setState({tableKey: getRandomString()});
					}
				}).catch(err => console.log(err));
			}
		});
	}

	/** For manage action button click event */
	customAction = (actionKey, rowData) => {
		if(actionKey === "edit" && rowData._id){
			this.setState({
				showModel 	:	true,
				workOrderId	: 	rowData._id
			});
		}else if(actionKey === "add"){
			this.setState({
				showModel:	true
			});
		}else if(actionKey === "delete" && rowData._id) {
			this.handleDeleteWorkOrder(rowData._id)
		}
	}

	/** Manage add / update modal box event */
	toggleModal(close) {
		this.setState({
			showModel	: close,
			workOrderId	: null
		});
	}

	/**
	* For export work order listing
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'work_order', method: 'workOrderNumberExports', file_type: fileType,};
		if(searchStats && Object.keys(searchStats).length){

			apiReq.job_number			=	searchStats.job_number ? searchStats.job_number.value :"";
			apiReq.work_order_number 	=	searchStats.work_order_number ? searchStats.work_order_number.value :[];

		}

		/** Call export API */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					this.anchorRef.current.href 	=	response.data[0].file_url;
					this.anchorRef.current.download = 	response.data[0].file_name;

					// Programmatically click the anchor element
					this.anchorRef.current.click();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	render() {
		const {showModel , workOrderId, tableKey } = this.state;

		let pageStats = {
			tableId 	:	'workOrderList',
			title 		:	translate("work_order.heading_title"),
			headerLinks	: 	[
				{name: translate("work_order.add_work_order"), selector: "add", clickEvent: this.customAction }
			],
			columns :	[
				{name: translate("work_order.job_number"), selector: "job_number", width: "45%"},
				{name: translate("work_order.work_order_number"), selector: "work_order_number", width: "45%"},
				{name: translate("system.action"), selector: "", width: "10%", custom_action: this.customAction, actions: [{key: "edit"}, {key: "delete"}]},

			],
			search :	[
				{name: translate("work_order.job_number"), selector: "job_number", 	input_type: "text"},
				{name: translate("work_order.work_order_number"), selector: "work_order_number", input_type: "text"},
			]
		}

		return <>

			<NormalDataTable ref={(ref) => this.dataTableComponent = ref} apiExport={{model: 'work_order', method: 'workOrderNumberExports',allRecords : true}} pageStats={pageStats} key={tableKey}  apiParm={{model: 'work_order', method: 'workOrderList'}} />

			{showModel ?
				<Suspense fallback={<div></div>}> <AddEditForm isFormVisible={showModel} toggleModal={this.toggleModal} workOrderId={workOrderId} refreshDataTable={this.refreshDataTable}/> </Suspense>
			:null}
		</>
	}
}
export default WorkOrderList;
