import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

class ResourceItem extends Component{

    static propTypes = {
        resource: PropTypes.object.isRequired,
    }

    render(){
        const {resource, isDragging, connectDragSource, connectDragPreview} = this.props;
        let dragContent = <li>{resource.name}</li>;

        return (
            isDragging ? null : (
                connectDragPreview(
                    connectDragSource(dragContent)
                )
            )
        )
    }
}
export default ResourceItem