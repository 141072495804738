import React, {Component} from 'react';
import {PropTypes} from 'prop-types';

import { translate } from '../../../helpers/intl_helpers';
import wrenchImg   from '../../../assets/images/wrench.png';

class ContractoItem extends Component{
    static propTypes = {
        task: PropTypes.object.isRequired,
    }

    render(){
        const {task, isDragging, connectDragSource, connectDragPreview, moveItem, setAssetItem} = this.props;
        let dragContent = <li className="cursor-pointer" style={{backgroundColor: task.state.bgColor}} title={task.state.toolTip ? task.state.toolTip : ""}>
                 <img src={task.state.image}  alt="shop" onClick={() => setAssetItem(task,"asset")} />
                <div>
                    <span onClick={() => setAssetItem(task,"asset")} dangerouslySetInnerHTML={{__html: task.name ? task.name :""}}></span>
                    <span>{task.state && task.state.driver_owner_name ? <small>({task.state.driver_owner_name})</small> : null}</span>
                    </div>
                <button className="box-remove-btn" onClick={(e) => moveItem(e,task.id,"shop")} title={translate("calendar.move_to_assets")}>
                    <img src={wrenchImg} alt="img"/>
                </button>
            </li>;
        return (
            isDragging ? null : (
                connectDragPreview(
                    connectDragSource(dragContent)
                )
            )
        )
    }
}
export default ContractoItem