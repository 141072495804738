import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

import { handleFormValidation } from '../helpers/common';
import { handleFormInputs } from '../helpers/common';
import { translate } from './../helpers/intl_helpers';
import { UserDetails }  from '../UserDetails';

export default class Login extends Component {
	static contextType = UserDetails;
	constructor(props){
		super(props);

		this.state = {
			errors: {},
			fields: {},
			submitted: false
		};

		this.handleFormInputs 	=	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
	}

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		this.setState({submitted: true}, () =>{

			/** Api Requested Data **/
			const {email, password } = this.state.fields;
			let params = [{
				model		: 	'registration',
				method		: 	'login',
				email		: 	(email && email.value)		?	email.value		:"",
				password	:	(password && password.value)?	password.value	:"",
			}];

			/** Validation Rules **/
			let validations = {
				email	: {"label": translate('user.email_address'), "rules" :["required", "email"]},
				password: {"label": translate('user.password'), "rules" :["required"]},
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params).then(response=>{

				this.setState({submitted: false});
				if(!response.success) return this.setState({ errors : response.errors});

				let cookieUser = {
					_id 			: 	response.data.result._id,
					full_name 		: 	response.data.result.full_name,
					first_name 		: 	response.data.result.first_name,
					last_name 		: 	response.data.result.last_name,
					email 			: 	response.data.result.email,
					mobile_number 	:	response.data.result.mobile_number,
					department 		:	response.data.result.department,
					location 		:	response.data.result.location,
					slug 			: 	response.data.result.slug,
					token 			: 	(response.data.token) 	?	response.data.token	:"",
					role_id 		: 	response.data.result.role_id,
					profile_image 	: 	(response.data.result.image) ?	response.data.result.image	:"",
					image_url 	    : 	(response.data.result.file_url) ?	response.data.result.file_url	:"",
					approval_status : 	(response.data.result.approval_status) 	?	response.data.result.approval_status	:"",
					permission_level : 	(response.data.result.permission_level) 	?	response.data.result.permission_level	:"",
				};

				const cookies = new Cookies();
				cookies.set('user_details', cookieUser, { path : "/"});
				this.context.updateUserState(cookieUser,()=>{
					this.props.history.push('/calendar');
				});
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted, fields, errors} = this.state;
		return (
			<div className="login-box">
				<form onSubmit={(event) => this.handleFormSubmit(event, 'login')} noValidate>
					<h1>{translate("login.admin_sign_in")}</h1>
                    <div className="form-floating">
						<input
							type="text"
							name="email"
							placeholder={translate('user.email_address')}
							className="form-control"
							onChange={(event) => this.handleFormInputs(event, "email")}
							value={ fields["email"] ? fields["email"]["value"] : ""}
						/>
						<label htmlFor="email">{translate("user.email_address")}<span className="error text-danger">*</span></label>
						<span className="error">{errors["email"]}</span>
					</div>
					<div className="form-floating">
						<input
							type="password"
							name="password"
							placeholder={translate('user.password')}
							className="form-control"
							onChange={(event) => this.handleFormInputs(event, "password")}
							value={ fields["password"] ? fields["password"]["value"] : ""}
						/>
						<label htmlFor="password">{translate('user.password')}<span className="error text-danger">*</span></label>
						<span className="error" dangerouslySetInnerHTML={{__html: errors["password"] }}></span>
					</div>

					<div className="d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
						<Link to="/forgot-password">
							{translate('login.forgot_your_password')}
						</Link>
						<button type={submitted ? "button" : "submit"} className="btn btn-fill" >
							{translate("login.sign_in")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
						</button>
					</div>
					<div className="other-login-wrap text-center">
						<Link to="/login" className="text-center">
							{translate("login.sso_login")}
						</Link>
					</div>
				</form>
			</div>
		);
	}
}