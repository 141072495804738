import { IntlProvider } from "react-intl";
import messages_en from "./../locales/en.json";
import { DEFAULT_LANGUAGE_CODE, AM_PM_FORMAT_WITH_DATE }  from './../config/global_constants';
import moment from 'moment';

const messages = { 'en': messages_en };
const message	=	messages[DEFAULT_LANGUAGE_CODE];
const intlProvider = new IntlProvider({ locale: "en", defaulLocale: DEFAULT_LANGUAGE_CODE, messages: message });
const { intl } = intlProvider.getChildContext();

/**
* Its used to translate text setting form the locals file
*
* @param id             as  text setting id
* @param defaultMessage as  default message (Its used when passed id not exists in file)
* @param constant       as  replace a value in the text setting string
*
* @return string
*/
export function translate(id, defaultMessage=null, constant){
	if(!defaultMessage) defaultMessage = id;
	let message = intl.formatMessage({id: id, defaultMessage: defaultMessage});
	if(constant && message && typeof constant === "string" && message.indexOf("%s") !== -1){
		message = message.replaceAll("%s",constant);
	}
	return message;
};// end translate()

/**
* Its used to format the number/price in required format
*
* @param amount   as  amount
* @param currency as  currency format (default is USD)
*
* @return string
*/
export function currency(amount, currency = "USD"){
	return intl.formatNumber(amount , {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 2});
};// end currency()

/**
* Its used to format the date in required format
*
* @param dateToBeFormat   as  date string
* @param format as  date format
*
* @return date string
*/
export function formatDate(dateToBeFormat, format = AM_PM_FORMAT_WITH_DATE){
	return moment(dateToBeFormat).format(format);
};// end formatDate()

/**
* Its used to get difference form current date
*
* @param date as  date string
*
* @return date difference string
*/
export function timeAgo(date){
	return moment(new Date(date)).fromNow();
};

/**
* Its used to extract time without seconds and with AM/PM from ISODate string
*
* @param date   as  date string
* @param format as  date format
*
* @return date string after formatted
*/
export function formatTime(date, format= "HH:mm") {
    // Parse ISODate string using moment
    const parsedDate = moment(date);
    const formattedTime = parsedDate.format(format);
    return formattedTime;
}// end formatTime()

/**
* Its used to make a range between two dates
*
* @param startDate  as  range start date
* @param endDate    as  range end date
*
* @return array of dates
*/
export function getDatesBetween(startDate, endDate){
	let dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    // return dates.sort((a,b)=>{ return b-a});
    return dates;
}// end getDatesBetween()

/**
 * Generates the default date range for the date picker.
 *
 * @param {number} daysBack - Number of days to subtract from today for the start date.
 *
 * @returns {[Date, Date]} - Array containing the start and end dates as Date objects.
 */
export function getDefaultDateRange(daysBack = 6,isSingleDay= false) {
    if(isSingleDay){
        return moment().subtract(daysBack, 'days').toDate();
    }else{
        return [moment().subtract(daysBack, 'days').toDate(), moment().toDate()];
    }
}// end getDefaultDateRange()

/**
 * Its used to get monday or friday date from current date
 *
 * @param null
 *
 * @returns json date of monday or friday
 */
export function getCurrentWeekDates() {
    let today = new Date();

    // Calculate the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let dayOfWeek = today.getDay();

    // Calculate the date for Monday
    let monday = new Date(today);
    monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Adjust for Sunday

    // Calculate the date for Friday
    let friday = new Date(monday);
    friday.setDate(monday.getDate() + 4); // Friday is 4 days after Monday

    return {
        monday: monday,
        friday: friday
    };
}// end getCurrentWeekDates()