import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import { formatDate } from './../../helpers/intl_helpers'
import { CALENDAR_DATE_TIME_FORMAT } from './../../config/global_constants'

import moonIcon  from '../../assets/images/moon_stars_icon.svg';
import sunIcon   from '../../assets/images/sun_icon.svg';

class BodyView extends Component {

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        isMonthView: PropTypes.bool,
    }

    render() {

        const {schedulerData,isMonthView} = this.props;
        const {renderData, headers, config, behaviors} = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();

        let displayRenderData = renderData.filter(o => o.render);
        let tableRows = displayRenderData.map((item) => {
            let rowCells = headers.map((header, index) => {

                let key = item.slotId + '_' + header.time;
                let style = index === headers.length - 1 ? {} : {width: cellWidth};
                if(!!header.nonWorkingTime)
                    style = {...style, backgroundColor: config.nonWorkingTimeBodyBgColor};
                if(item.groupOnly)
                    style = {...style, backgroundColor: config.groupOnlySlotColor};
                if(!!behaviors.getNonAgendaViewBodyCellBgColorFunc){
                    let cellBgColor = behaviors.getNonAgendaViewBodyCellBgColorFunc(schedulerData, item.slotId, header);
                    if(!!cellBgColor)
                        style = {...style, backgroundColor: cellBgColor};
                }
                if(header.time === formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
                    style = {...style, backgroundColor: "#FFECEC"};
                }

                return (
                    // <td key={key} style={style}><div></div></td>
                    <td key={key} style={style}>
                        <div key={index}>
                            {(!isMonthView && item && item.groupOnly && item.parentId=== undefined) ?
                                <div className="d-flex align-items-center justify-content-center" key={index}>
                                    <div className="job-counter-list day-counter">
                                        <img src={sunIcon} alt="img" />
                                        <span>
                                            {item.slotData.job_count && item.slotData.job_count[header.time] && item.slotData.job_count[header.time].day ? item.slotData.job_count[header.time].day :0}
                                        </span>
                                    </div>
                                    <div className="job-counter-list day-counter">
                                        <img src={moonIcon} alt="img" />
                                        <span>
                                            {item.slotData.job_count && item.slotData.job_count[header.time] && item.slotData.job_count[header.time].night ? item.slotData.job_count[header.time].night :0}
                                        </span>
                                    </div>
                                </div> : ""
                            }
                        </div>
                    </td>
                )
            });
            let resourceHeaderClass = (!isMonthView && item && (item.parentId === undefined)) ? "contractor-class" : "";
        
            return (
                <tr className={resourceHeaderClass} key={item.slotId} style={{height: item.rowHeight}}>
                    {rowCells}
                </tr>
            );
        });

        return (
            <tbody>
                {tableRows}
            </tbody>
        );
    }
}

export default BodyView