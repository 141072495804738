import React, { Component } from "react";
import { translate  } from '../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../helpers/common';
import { AGREEMENT_TYPE_DROPDOWN  } from '../../config/global_constants';

import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';

export default class SubcontractorDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('subcontractorDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl } = this.props;

		return (
			<>
			<div className="modal fade crew-modal" id="subcontractorDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{translate('calendar.details')}</h3><div className="list-group list-group-flush">
								<div className="row">
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.image')}</p>
											<div>
												<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
													{profileDetails && profileDetails.image ? (
														<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
													) : (
														upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
													)}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.name')}</p>
											<div>{profileDetails.company_name ? profileDetails.company_name : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.contact_name')}</p>
											<div>{profileDetails.contact_name ? profileDetails.contact_name : ""}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.email')}</p>
											<div>
												<img src={mailIcon} alt="img" width={19} height={21} />
												{profileDetails.email ? (
													<a href={`mailto:${profileDetails.email}`} className="text-lowercase email-link">
														{profileDetails.email}
													</a>
												) : ""}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.mobile')}</p>
											<div>
												<img src={callIcon} alt="img" width={19} height={21} />
												{profileDetails.mobile ? (
													<a href={`tel:${profileDetails.mobile}`}>{profileDetails.mobile}</a>
												) : ""}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.status')}</p>
											<div>
												<span className={profileDetails.active ? "text-success" : "text-danger"}>
													{profileDetails.active ? "Active" : "Deactive"}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.project_manager')}</p>
											<div>{profileDetails.project_manager ? profileDetails.project_manager : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.vendor_code')}</p>
											<div>{profileDetails.vendor_code ? profileDetails.vendor_code : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.street')}</p>
											<div>{profileDetails.street ? profileDetails.street : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.city')}</p>
											<div>{profileDetails.city ? profileDetails.city : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.state')}</p>
											<div>{profileDetails.state ? profileDetails.state : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.zip')}</p>
											<div>{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.is_flagger')}</p>
											<div>
												{profileDetails.is_flagger ? "Yes" : "No"}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('subcontractor.agreement_type')}</p>
											<div>
												{AGREEMENT_TYPE_DROPDOWN.find(item => item.value === profileDetails.agreement_type)?.label || "N/A"}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
