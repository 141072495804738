
import React, {Component} from 'react'
import Select from 'react-select';
import { translate } from '../../../helpers/intl_helpers'

import { handleFormValidation } from '../../../helpers/common';
import { PAID_UNPAID_DROPDOWN } from '../../../config/global_constants';

class PaidUnpaidAssignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignEmployeeForPtoJob : this.props.assignEmployeeForPtoJob,
            fields      : {},
            errors      : {},
            submitted   : false,
        };
    }

    /**
	* For manage form submit event
	*
	* @param null
	*
	* @return null
	*/
    handleOnClick = () => {
        const { assignEmployeeForPtoJob } = this.state;
        const { employee_type } = this.state.fields;
        let params 			= [];
		let validations 	= {};
        let sendToAPI       = false;

        params = [{employee_type : 	(employee_type && employee_type.value)    ?	employee_type.value		 :""}];

        validations = {employee_type : { "label": translate('calendar.employee_type'), "rules" :["select"] }}

        this.setState({submitted: true},()=>{
            handleFormValidation(validations,this.state.fields, params, sendToAPI,true,true).then(async(response)=>{
                if(!response.success) return this.setState({ errors : response.errors});

                this.setState({
                    submitted: false,
                    errors: {},
                    fields: {}
                },()=>{
                    assignEmployeeForPtoJob(employee_type.value);
                });
            }).catch(err => console.log(err));
        })
    }

    /**
	 * Handle dropdown value state
	 */
    handleChange = (fieldName) => (fieldVal) => {
        this.setState({
            foremanId: fieldVal.foreman_id,
            fields: {
                ...this.state.fields,
                [fieldName]: fieldVal
            }
        });
    };

    render() {
        const { fields, submitted } = this.state;
        return (
            <div className="modal fade  crew-modal" id="assignPaidUnpdaidModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-s">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h3>{translate("calendar.assign_employee")}</h3>
                                <div className='add-job-group'>

                                    <div className="form-group form-floating ">
                                        <Select
                                            className="custom-react-select"
                                            value={fields.employee_type ? fields.employee_type: null}
                                            options={ PAID_UNPAID_DROPDOWN }
                                            onChange={this.handleChange("employee_type")}
                                            closeMenuOnSelect={true}
                                            placeholder={<div><label htmlFor="job">{translate('calendar.select_type')}<span className="error text-danger">*</span></label></div>}
                                        />
                                        <span className="error text-danger">{this.state.errors["employee_type"]}</span>
                                    </div>
                                    <button type={"button"} className="btn btn-fill width-initial mt-0" onClick={() => this.handleOnClick()}>{translate("system.submit")}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PaidUnpaidAssignModal