import 'react-image-lightbox/style.css';

import React, { Component } from 'react';
import { NavLink as Link} 	from "react-router-dom";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {AsyncPaginate} from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { components } from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Lightbox from 'react-image-lightbox';

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  } from 'react-places-autocomplete';
import { DateRangePicker } from 'rsuite';

import { translate, formatDate, formatTime, getDefaultDateRange } from '../../../helpers/intl_helpers'
import { CALENDAR_DATE_TIME_FORMAT, DEPARTMENT_DROPDOWN, OSP_DEPARTMENT, ISP_DEPARTMENT, FIBER_DEPARTMENT,CTR_DEPARTMENT,HDD_DEPARTMENT, STATUS_SUCCESS, RADIO_YES,RADIO_NO, ACTIVE, ELEC_DEPARTMENT, OFFICE_DEPARTMENT, TIME_PICKER_FORMAT, DATE_PICKER_DATE_FORMAT, DATE_RANGE_PICKER_FORMAT, ALLOWED_IMAGE_EXTENSIONS} from '../../../config/global_constants'

import { handleFormValidation, handleFormInputs,  callBackendAPI, callBackendAPIAndImageUpload, reFormatSuccess, reFormatErrors, manageShiftTime, manageCTRShiftTime, customConfirmBox, isWeekendDay } from '../../../helpers/common';

import DeleteIcon 	 from '../../../assets/images/delete-icon.svg';
import documentIcon  from '../../../assets/images/document.svg';
import downloadIcon  from '../../../assets/images/download_icon.svg';
import infoIcon      from '../../../assets/images/info-icon.svg';
import boxRemoveIcon from '../../../assets/images/box-remove-Icon.svg';
import addBlueIcon from '../../../assets/images/add.svg';

const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
    <input ref={ref} value={value} onClick={onClick} readOnly placeholder="Select time range" className="form-control" style={{ cursor: 'pointer' }} />
));

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        {translate("calendar.file_extension_validation")}
    </Tooltip>
);

const customStyles = {
    menu: base => ({
      ...base,
      top: 'unset',
      bottom: '100%',
      marginTop: '-1px'
    })
};

const customComponents = {
    Menu: props => (
      <components.Menu {...props} />
    )
};

/** Step wise input (For show error flag on step circle) */
let inputStepWise = {
    step_1 : ["location","department","start_date","end_date","start_time","end_time","project_manager","foreman","job_number","work_order","project_name","crew_size","employee_id","employees","equipment","equipment_id","work_order_number"],
    step_2 : ["jobsite_address","job_address_city","jobsite_address_zip","jobsite_address_state","scope_of_work_type","scope_of_work","daily_project_value","materials","additional_equipment","contact_name","contact_email","contact_phone","is_permit_required","is_prevailing_wage","is_out_of_town","lodging_information","is_badge_required","badges_required","is_safety_orientation_required","jobsite_restrictions"],
    step_3 : ["attachment","notes"],
}

function getErrorStep(errorObj = {}){
    let tmpArray = [];
    if(errorObj.constructor === Object){
        Object.keys(errorObj).forEach(key=>{
            if(inputStepWise.step_1.indexOf(key) !== -1){
                tmpArray.push("step_1");
            }else if(inputStepWise.step_2.indexOf(key) !== -1){
                tmpArray.push("step_2");
            }else if(inputStepWise.step_3.indexOf(key) !== -1){
                tmpArray.push("step_3");
            }
        });
    }

    return tmpArray;
}// end getErrorStep()

function isWorkingDay(date) {
    const day = date.getDay();
    return day !== 0 && day !== 6;
}

export default  class TaskModal extends Component {
    constructor(props) {
        super(props);
        const defaultTime = new Date();
        defaultTime.setHours(6, 30, 0, 0); // Set default time to 6:30 AM
        let dateObj = (this.props.dateObj) ? this.props.dateObj : "";
        let dragItem= (dateObj && dateObj.drag_item && (dateObj.drag_item !== undefined)) ? dateObj.drag_item : "";

        let isMobile	=	(dateObj && dateObj.isMobileScreen) ? dateObj.isMobileScreen : false;

        let defaultDate = dateObj.start_date ? new Date(dateObj.start_date) : new Date();
        defaultDate.setDate(defaultDate.getDate() - 1);
        while (!isWorkingDay(defaultDate)) {
            defaultDate.setDate(defaultDate.getDate() - 1);
        }

        /** Initial stats */
        let inputFields = {
            start_time	                : 	{value: manageShiftTime()},
			end_time	                : 	{value: manageShiftTime(true)},
            is_safety_orientation_required: {value: String(RADIO_NO)},
            is_permit_required          :   {value: String(RADIO_NO)},
            is_prevailing_wage          :   {value: String(RADIO_NO)},
            is_out_of_town              :   {value: String(RADIO_NO)},
            is_badge_required           :   {value: String(RADIO_NO)},
            is_locates_required         :   {value: String(RADIO_NO)},
            is_flagger_required         :   {value: String(RADIO_NO)},
            is_subcontractor_required   :   {value: String(RADIO_NO)},
            is_inspector_required       :   {value: String(RADIO_NO)},
            is_potholing_required       :   {value: String(RADIO_NO)},
            is_tracer_wire_required     :   {value: String(RADIO_NO)},
            final_inspection_status     :   {value: String(RADIO_NO)},
            is_electricians_required    :   {value: String(RADIO_NO)},
            is_have_order               :   {value: String(RADIO_NO)},
            is_tbd                      :   {value: String(RADIO_YES)},
            start_date                  :   {value: dateObj.start_date},
            end_date                    :   {value:dateObj.end_date},
            employees                   :   (dragItem && dragItem.state && dragItem.state && !dragItem.state.type) ? [{_id:dragItem.id, value:dragItem.id,label: dragItem.name}] : ((dateObj && dateObj.foreman_id && dateObj.foreman_id !== undefined && dateObj.parent_name && dateObj.parent_name!== undefined) ? [{_id:dateObj.foreman_id,value: dateObj.foreman_id, label: dateObj.parent_name}] : null),
            equipment                   :   (dragItem && dragItem.state && dragItem.state && (dragItem.state.type === "asset")) ? [{_id:dragItem.id,value:dragItem.id,label: dragItem.name}] : null,
            foreman                     :   (dateObj && dateObj.foreman_id && dateObj.foreman_id !== undefined) ? {value: dateObj.foreman_id, label: dateObj.parent_name} : null,
            importEmployeeForeman       :   (dateObj && dateObj.foreman_id && dateObj.foreman_id !== undefined) ? {value: dateObj.foreman_id, label: dateObj.parent_name} : null,
            importEquipmentForeman      :   (dateObj && dateObj.foreman_id && dateObj.foreman_id !== undefined) ? {value: dateObj.foreman_id, label: dateObj.parent_name} : null,
            latitude                    :   "",
            longitude                   :   "",
            jobsite_address             :   "",
            is_hide_weekend_date        :   {value: String(RADIO_NO)},
            date_range 	                : 	(dateObj.start_date && dateObj.end_date) ? [new Date(dateObj.start_date), new Date(dateObj.end_date)] : [new Date(), new Date()],
            importEmployeeDate 	        : 	{value:defaultDate},
            importEquipmentDate 	    : 	{value:defaultDate},
            image: {
                file_count: 0,
                files: []
            }
        };

        /** Set values in edit or copy the job */
		let jobDetails 	=	(this.props.jobDetails) ? this.props.jobDetails :{};

		let isDraft		=	true;
		let isEdit		=	jobDetails.isEdit ? jobDetails.isEdit : false;
		let isCopy		=	jobDetails.isCopy ? jobDetails.isCopy : false;
		let jobId		=	"";

		if(jobDetails && jobDetails._id){
            let tmpDepartment = jobDetails.department;
            // Basic Details
			if(isEdit){
                isDraft = jobDetails.is_draft ? jobDetails.is_draft : false;
				jobId =  jobDetails._id;
				if(jobDetails.date && formatDate(jobDetails.date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
					inputFields.date_range	=	[new Date(jobDetails.date), new Date(jobDetails.date)];
				}
                inputFields.move_date = {value: jobDetails.date};
			}

            if(isCopy && jobDetails.selected_date && formatDate(jobDetails.selected_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
                inputFields.start_date	=	{value: jobDetails.selected_date};
                inputFields.end_date	=	{value: jobDetails.selected_date};
                inputFields.date_range	=	[new Date(jobDetails.selected_date), new Date(jobDetails.selected_date)];
            }

            if(jobDetails.start_time_24_hour) 	inputFields.start_time 	=	{value: manageShiftTime(null, jobDetails.start_time_24_hour)};
			if(jobDetails.end_time_24_hour) 	inputFields.end_time 	= 	{value: manageShiftTime(true, jobDetails.end_time_24_hour)};

            if(tmpDepartment === CTR_DEPARTMENT) {
                if(jobDetails.start_time_24_hour) 	inputFields.start_time 	=	{value: manageCTRShiftTime(null, jobDetails.start_time_24_hour)};
			    if(jobDetails.end_time_24_hour) 	inputFields.end_time 	= 	{value: manageCTRShiftTime(true, jobDetails.end_time_24_hour)};
            }

            if(jobDetails.location && jobDetails.location_title)    inputFields.location   =	{value: jobDetails.location, label: jobDetails.location_title, department: (jobDetails.location_department) ? jobDetails.location_department : []};
			if(tmpDepartment && jobDetails.department_title)        inputFields.department =	{value: tmpDepartment, label: jobDetails.department_title};
			if(jobDetails.scope_of_work_type_title)        inputFields.scope_of_work_type  =	{value: jobDetails.scope_of_work_type, label: jobDetails.scope_of_work_type_title};
            if(jobDetails.scope_of_work) inputFields.scope_of_work = {value: jobDetails.scope_of_work};

            if(jobDetails.is_have_order)  inputFields.is_have_order =   {value: String(jobDetails.is_have_order)};
            if(jobDetails && jobDetails.is_tbd !== "")  inputFields.is_tbd =   {value: String(jobDetails.is_tbd)};

            if(jobDetails.is_hide_weekend_date)  inputFields.is_hide_weekend_date =   {value: String(jobDetails.is_hide_weekend_date)};

            // Job Details
            if(jobDetails.project_manager_id && jobDetails.project_manager_name) 	inputFields.project_manager	=	{value: jobDetails.project_manager_id, label: jobDetails.project_manager_name};
			if(jobDetails.foreman_id && jobDetails.foreman_name) 	inputFields.foreman	    =	{value: jobDetails.foreman_id, label: jobDetails.foreman_name};
			if(jobDetails.foreman_id && jobDetails.foreman_name) 	inputFields.importEmployeeForeman	    =	{value: jobDetails.foreman_id, label: jobDetails.foreman_name};
			if(jobDetails.foreman_id && jobDetails.foreman_name) 	inputFields.importEquipmentForeman	    =	{value: jobDetails.foreman_id, label: jobDetails.foreman_name};

            /**Condition for set new foreman when copy job */
            if(isCopy) inputFields.foreman = {value: dateObj.foreman_id, label: dateObj.parent_name};
            if(isCopy) inputFields.importEmployeeForeman = {value: dateObj.foreman_id, label: dateObj.parent_name};
            if(isCopy) inputFields.importEquipmentForeman = {value: dateObj.foreman_id, label: dateObj.parent_name};

			if(jobDetails.job_number_id && jobDetails.job_number) inputFields.job_number	=	{value: jobDetails.job_number_id, label: jobDetails.job_number, job_number: jobDetails.job_number};
			if(jobDetails.work_order_id && jobDetails.work_order_number) inputFields.work_order = {value: jobDetails.work_order_id, label: jobDetails.work_order_number, work_order_number: jobDetails.work_order_number};
            if(jobDetails.project_name) inputFields.project_name    =	{value: jobDetails.project_name};
            if(jobDetails.crew_size) 	inputFields.crew_size	    =	{value: jobDetails.crew_size};

            if(jobDetails.crewMembers && jobDetails.crewMembers.length){
                inputFields.employees = jobDetails.crewMembers.map(element => {
                    return {_id: element.id, value: element.id, label: element.full_name};
                });
            }

            if(jobDetails.assets && jobDetails.assets.length){
                inputFields.equipment = jobDetails.assets.map(element => {
                    return {_id: element.id, value: element.id, label: element.label};
                });
            }

            // Site Details
            if(jobDetails.latitude) 	            inputFields.latitude 	            =	{value: jobDetails.latitude};
            if(jobDetails.longitude) 	            inputFields.longitude 	            =	{value: jobDetails.longitude};
            if(jobDetails.jobsite_address) 	        inputFields.jobsite_address 	    =	{value: jobDetails.jobsite_address};
            if(jobDetails.jobsite_address_city) 	inputFields.job_address_city 	    =	{value: jobDetails.jobsite_address_city};
			if(jobDetails.jobsite_address_zip) 		inputFields.jobsite_address_zip 	=	{value: jobDetails.jobsite_address_zip};
			if(jobDetails.jobsite_address_state)	inputFields.jobsite_address_state 	=	{value: jobDetails.jobsite_address_state};
			if(jobDetails.jobsite_address_street) 	inputFields.jobsite_address_street	=	{value: jobDetails.jobsite_address_street};
			if(jobDetails.jobsite_building) 	    inputFields.jobsite_building	    =	{value: jobDetails.jobsite_building};
            if(jobDetails.jobsite_restrictions) 	inputFields.jobsite_restrictions	=	{value: jobDetails.jobsite_restrictions};
            if(jobDetails.lodging_information) 	    inputFields.lodging_information	    =	{value: jobDetails.lodging_information};
            if(jobDetails.is_permit_required) 	    inputFields.is_permit_required	    =	{value: String(RADIO_YES)};
            if(jobDetails.is_prevailing_wage) 	    inputFields.is_prevailing_wage	    =	{value: String(RADIO_YES)};
            if(jobDetails.is_out_of_town) 	        inputFields.is_out_of_town	        =	{value: String(RADIO_YES)};
            if(jobDetails.is_badge_required) 	    inputFields.is_badge_required	    =	{value: String(RADIO_YES)};
            if(jobDetails.is_safety_orientation_required) inputFields.is_safety_orientation_required = {value: String(RADIO_YES)};
            if(jobDetails.badges_required && jobDetails.badges_required_title) inputFields.badges_required = {value: jobDetails.badges_required, label: jobDetails.badges_required_title};

            // Other Details
            if(jobDetails.daily_project_value) 	    inputFields.daily_project_value	    =	{value: jobDetails.daily_project_value};
            if(jobDetails.materials) 	            inputFields.materials	            =	{value: jobDetails.materials};
			if(jobDetails.additional_equipment) 	inputFields.additional_equipment	=	{value: jobDetails.additional_equipment};

            // POC
            if(jobDetails.contact_name)     inputFields.contact_name    =   {value: jobDetails.contact_name};
			if(jobDetails.contact_email)    inputFields.contact_email   =   {value: jobDetails.contact_email};
			if(jobDetails.contact_phone)    inputFields.contact_phone   =   {value: jobDetails.contact_phone};

            // Attachment
            if(isEdit & jobDetails.fileUrl)     inputFields.fileUrl 	= 	jobDetails.fileUrl;
            if(isEdit & jobDetails.attachment) 	inputFields.attachment 	=	jobDetails.attachment;

            // Department
            if(tmpDepartment === ISP_DEPARTMENT){
                if(jobDetails.is_electricians_required) inputFields.is_electricians_required = {value: String(RADIO_YES)};

                if(jobDetails.tco_date && formatDate(jobDetails.tco_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
                    inputFields.tco_date	= {value: jobDetails.tco_date};
                }
                if(jobDetails.po_number)   inputFields.po_number   =   {value: jobDetails.po_number};
                if(jobDetails.po_value)    inputFields.po_value   =   {value: jobDetails.po_value};
            }

            if(tmpDepartment !== ISP_DEPARTMENT){

                if(jobDetails.is_locates_required)  inputFields.is_locates_required =   {value: String(RADIO_YES)};
                if(jobDetails.locate_type)  inputFields.locate_type =	{value: jobDetails.locate_type};

                if(jobDetails.locate_expiration_date && formatDate(jobDetails.locate_expiration_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
                    inputFields.expiration_date	= {value: jobDetails.locate_expiration_date};
                }
            }

            if([HDD_DEPARTMENT, CTR_DEPARTMENT, OSP_DEPARTMENT, FIBER_DEPARTMENT].indexOf(tmpDepartment) !== -1){

                if(jobDetails.is_flagger_required)      inputFields.is_flagger_required         =   {value: String(RADIO_YES)};
                if(jobDetails.is_subcontractor_required)inputFields.is_subcontractor_required   =   {value: String(RADIO_YES)};
                if(jobDetails.subcontractor_id && jobDetails.subcontractor_name_list && jobDetails.subcontractor_name_list.length) inputFields.subcontractor_id=	jobDetails.subcontractor_name_list;
                if(jobDetails.flagger_subcontractor_id && jobDetails.flagger_subcontractor_name_list && jobDetails.flagger_subcontractor_name_list.length) inputFields.flagger_subcontractor_id = jobDetails.flagger_subcontractor_name_list;
            }

            if(tmpDepartment === OSP_DEPARTMENT && jobDetails.approved_line_items) inputFields.approved_line_items = {value: jobDetails.approved_line_items};

            if(jobDetails.ticket_number)  inputFields.ticket_number   =	{value: jobDetails.ticket_number};

            if([CTR_DEPARTMENT, OSP_DEPARTMENT, ISP_DEPARTMENT, FIBER_DEPARTMENT, HDD_DEPARTMENT].indexOf(tmpDepartment) !== -1){
                if(jobDetails.inspector_required)   inputFields.is_inspector_required   =   {value: String(RADIO_YES)};
                if(jobDetails.inspector_name)       inputFields.inspector_name 	        =	{value: jobDetails.inspector_name};
                if(jobDetails.inspector_email)      inputFields.inspector_email 	    =	{value: jobDetails.inspector_email};
                if(jobDetails.inspector_phone)      inputFields.inspector_phone 	    =	{value: jobDetails.inspector_phone};
            }

            if(tmpDepartment === CTR_DEPARTMENT){
                if(jobDetails.traffic_control_requirements) inputFields.traffic_control_requirements 	=	{value: jobDetails.traffic_control_requirements};
                if(jobDetails.final_inspection_status)      inputFields.final_inspection_status         =   {value: String(RADIO_YES)};

                if(jobDetails.specialized_tools_ctr && jobDetails.specialized_tools_ctr_title) inputFields.specialized_tools_ctr = {value: jobDetails.specialized_tools_ctr, label: jobDetails.specialized_tools_ctr_title};
            }

            if(tmpDepartment === HDD_DEPARTMENT){
                if(jobDetails.hdd_drill_size_list && jobDetails.hdd_drill_size_list.length) inputFields.drill_size = jobDetails.hdd_drill_size_list;

                if(jobDetails.is_potholing_required)    inputFields.is_potholing_required    =   {value: String(RADIO_YES)};
                if(jobDetails.is_tracer_wire_required)  inputFields.is_tracer_wire_required  =   {value: String(RADIO_YES)};

                if(jobDetails.surface_type && jobDetails.surface_type.length){
                    inputFields.surface_type = jobDetails.surface_type_list ?  jobDetails.surface_type_list : null;
                }

                if(jobDetails.hdd_product_size)         inputFields.product_size    =	{value: jobDetails.hdd_product_size};
                if(jobDetails.dump_site_location)       inputFields.dump_site       =	{value: jobDetails.dump_site_location};
                if(jobDetails.hdd_length_of_bore)       inputFields.length_of_bore  =	{value: jobDetails.hdd_length_of_bore};
                if(jobDetails.water_source_location)    inputFields.water_source    =	{value: jobDetails.water_source_location};

                if(jobDetails.hdd_locator_type_title)    inputFields.hdd_locator_type  =	{value: jobDetails.hdd_locator_type, label: jobDetails.hdd_locator_type_title};

            }

            if(tmpDepartment === FIBER_DEPARTMENT){
                if(jobDetails.e2e_ticket_number)  inputFields.e2e_ticket   =	{value: jobDetails.e2e_ticket_number};
                if(jobDetails.cm_ticket_number)   inputFields.cm_ticket    =	{value: jobDetails.cm_ticket_number};
                if(jobDetails.headend_hub_location && jobDetails.headend_hub_location_title) inputFields.hub_location = {value: jobDetails.headend_hub_location, label: jobDetails.headend_hub_location_title};

                if(jobDetails.fiber_task)        inputFields.fiber_task  =	{value: jobDetails.fiber_task, label: jobDetails.fiber_task_title};
            }
		}

        this.state = {
            isLoading	: 	true,
            form_step           : "step_1",
            selectedTime        : defaultTime,
            errorStepList       : [],
            fields              : inputFields,
            projectManager      : [],
            foreman             : [],
            jobNumber           : [],
            workOrder           : [],
            employees           : [],
            equipment           : [],
            hddDrillSize        : [],
            headendHubLocation  : [],
            specializedToolsTtr : [],
            location            : [],
            badges              : [],
            fiberTask           : [],
            scopeWorkType       : [],
            hddLocatorType      : [],
            locateType          : [],
            surfaceType         : [],
			errors              : {},
            loading             : true,
            submittedAsDraft	: false,
            submitted			: false,
            jobId  		        : jobId,
			isEdit  	        : isEdit,
			isCopy  	        : isCopy,
			isDraft  	        : isDraft,
            isMobileScreen      : isMobile,
            is_move_date        : false,
            job_data            : (this.props.jobDetails) ? this.props.jobDetails :{},
            fileUrl		        : (this.props.jobDetails && this.props.jobDetails.file_url) ? this.props.jobDetails.file_url :"",
            lightBoxShow	    : false,
			lightBoxImgUrl	    : "",
            random_key 		    : Math.random(),
            employeesList 		: [],
            equipmentList 		: [],
        };
        this.componentUnmounted     =   false;
		this.handleChange           =   this.handleChange.bind(this);
        this.handleFormInputs       =   this.handleFormInputs.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
        this.closeModal				=  	this.closeModal.bind(this);
    }

    /**
	* For get master listing
	*/
	componentDidMount() {
        const { fields} = this.state;
        window.showLoader();
        this.getEmpList();
        this.getEquipList();

        /** Api Params Request */
        let apiParams = [{model: 'home', method: 'masterList', type: ["location","hdd_drill_size","headend_hub_location","specialized_tools_ctr","badges","fiber_task","scope_of_work_type","hdd_locator_type","locate_type","surface_type"] }];

        /** Call API */
        callBackendAPI(apiParams).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {

                /** Set update stats */
                let updateStats = {
					hddDrillSize 		: response.data[0].result.hdd_drill_size,
					headendHubLocation 	: response.data[0].result.headend_hub_location,
					specializedToolsTtr : response.data[0].result.specialized_tools_ctr,
					location            : response.data[0].result.location,
					badges              : response.data[0].result.badges,
					fiberTask           : response.data[0].result.fiber_task,
					scopeWorkType       : response.data[0].result.scope_of_work_type,
					hddLocatorType      : response.data[0].result.hdd_locator_type,
					locateType          : response.data[0].result.locate_type,
					surfaceType         : response.data[0].result.surface_type,
				};

				this.setState(updateStats,()=>{
                    if(fields.job_number) this.getWorkOrderList(fields.job_number);
                    window.hideLoader();
                });
			}
		}).catch(err => console.log(err));
	}

    /**
	* For get drop down list
	*/
    async getDropDownList(inputValue, loadedOptions, { page , type }) {
        try{
            const { /*start_date, end_date,*/ importEmployeeDate, importEmployeeForeman, importEquipmentDate, importEquipmentForeman, start_time, end_time, date_range } = this.state.fields;

            // let startDate = (date_range && date_range[0])   ?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)	:"";
            // let endDate = (date_range && date_range[1])     ?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)		:"";

            let apiReq = null;
            if(type === "projectmanager") apiReq = { model: 'calendar', method: 'employeeList', is_project_manager: true, emp_name: inputValue ? inputValue : '', current_page: page };
            if(type === "foreman") apiReq = { model: 'calendar', method: 'employeeList', is_foreman:true, emp_name: inputValue ? inputValue : '', current_page: page };
            if(type === "cannonJob") apiReq = { model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : '', current_page: page };
            // if(type === "equipment") apiReq = { model: 'calendar', method: 'assetsList', add_job: true, asset_name: inputValue ? inputValue : '', current_page: page, start_date:(start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): startDate, end_date: (end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT) : endDate, start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "" };
            // if(type === "employee") apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, add_job: true, emp_name: inputValue ? inputValue : '', complete_list:true, start_date:(start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): startDate, end_date: (end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT) : endDate, start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "" };
            if(type === "flagger")       apiReq = { model: 'calendar', method: 'employeeList', is_flagger: true, is_subcontractor: true, emp_name: inputValue ? inputValue : '', current_page: page };
            if(type === "subcontractor") apiReq = { model: 'calendar', method: 'employeeList', is_subcontractor: true, emp_name: inputValue ? inputValue : '', current_page: page };

            if(type === "importEmployeeJobNumber" || type === "importEquipmentJobNumber"){
				let impForemanId = type === "importEmployeeJobNumber" ? (importEmployeeForeman && importEmployeeForeman.value ? importEmployeeForeman.value : '') : (importEquipmentForeman && importEquipmentForeman.value ? importEquipmentForeman.value : '');
				let impDate      = type === "importEmployeeJobNumber" ? (importEmployeeDate && importEmployeeDate.value ? importEmployeeDate.value : '') : (importEquipmentDate && importEquipmentDate.value ? importEquipmentDate.value : '');

				if(!impForemanId || !impDate) return {options: [], hasMore: true, additional: {page: page, type: type }};

                apiReq = { model: 'calendar', method: 'importEmployeeAndEquipment', job_number: inputValue ? inputValue : '', current_page: page, import_date: impDate, import_foreman_id: impForemanId , is_employee: (type === "importEmployeeJobNumber" ? true: false), is_equipment: (type === "importEquipmentJobNumber" ? true: false) };
			}

            if(!apiReq) return {options: [], hasMore: true, additional: {page: page, type: type }};

            let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options: result,
				hasMore: completeArray.length  === recordsTotal ? false :true,
				additional: {
					page: page + 1,
					type: type,
				},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: false, additional: {type: type, page: page}};
		}
    }// end getDropDownList()

    /**
	* For get equipment listing
	*/
    getEquipList = (onlyAssign = false) =>{
        const { start_date, end_date, start_time, end_time, date_range, equipment, employees, move_date } = this.state.fields;
        const {equipmentList } = this.state;

        let startDate   = (date_range && date_range[0]) ?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
        let endDate     = (date_range && date_range[1]) ?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)	:"";

        if(move_date && move_date.value){
            startDate   =   formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
            endDate     =   formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
        }

        /** Set API request params */
        let apiReq = { model: 'calendar', method: 'assetsList', add_job: true, complete_list:true, start_date: startDate ? startDate : ((start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): ""), end_date: endDate ? endDate :((end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT): ""), start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "" };

        if(onlyAssign){
            if(!employees || !employees.length) return false;

            apiReq.assignee_employees = employees;
        }

        callBackendAPI([apiReq]).then(response => {
           if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let result = response.data[0].result;
                let transformedOptions  =  [];
                let selectedOptions     =  !result.length && equipment && equipment.length ? equipment :[];
                result.forEach((equip) => {
                    // Apply strikethrough if `isUsed` is true, else leave label as it is
                    let tmpObj = {...equip, label: (equip.isUsed === true)  ? <span className="strikethrough">{equip.label}</span> : equip.label};

                    let isMatched = false;
                    if(equipment && equipment.length){
                        equipment.forEach(data=>{
                            if(data.value === equip.value) isMatched = true
                        });
                    }

                    if(isMatched || onlyAssign) selectedOptions.push(tmpObj);

                    transformedOptions.push(tmpObj);
                });

                if(onlyAssign && equipment && equipment.length && selectedOptions.length){
                    equipment.forEach(data=>{
                        let isMatch = false;
                        selectedOptions.forEach(selData=>{
                            if(selData.value === data.value) isMatch = true;
                        });

                        if(!isMatch) selectedOptions.push(data);
                    });
                }


                let updateStats = {fields: {...this.state.fields, ...{equipment: selectedOptions.length ? selectedOptions : ""}}};
                if(!onlyAssign) updateStats.equipmentList = transformedOptions;
                this.setState(updateStats);
            }
       }).catch(err => console.log(err));
    }// end getEquipList()

    /**
	* For get emp listing
	*/
    getEmpList = () =>{
        const { start_date, end_date, start_time, end_time, date_range, employees, move_date } = this.state.fields;

        let startDate   = (date_range && date_range[0]) ?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
        let endDate     = (date_range && date_range[1]) ?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)	:"";

        if(move_date && move_date.value){
            startDate   =   formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
            endDate     =   formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
        }

        /** Set API request params */
        let apiReq = { model: 'calendar', method: 'employeeList', is_crew_member: true, add_job: true, complete_list:true, start_date: startDate ? startDate : ((start_date && start_date.value) ? formatDate(start_date.value,CALENDAR_DATE_TIME_FORMAT): ""), end_date: endDate ? endDate :((end_date && end_date.value) ? formatDate(end_date.value,CALENDAR_DATE_TIME_FORMAT): ""), start_time: (start_time && start_time.value) ? formatTime(start_time.value,"HH:mm") : "",end_time: (end_time && end_time.value) ? formatTime(end_time.value,"HH:mm") : "" };

        callBackendAPI([apiReq]).then(response => {
           if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let result = response.data[0].result;
                let transformedOptions  =  [];
                let selectedOptions     =  [];
                result.map(emp => {
                    // Apply strikethrough if `isUsed` is true, else leave label as it is
                    let tmpObj = {...emp, label: (emp.isUsed === true)  ? <span className="strikethrough">{emp.label}</span> : emp.label};

                    if(employees && employees.length){
                        employees.map(data=>{
                            if(data.value === emp.value){
                                selectedOptions.push(tmpObj);
                            }
                        });
                    }
                    transformedOptions.push(tmpObj);
                });

                this.setState({
                    employeesList : transformedOptions,
                    fields        : {...this.state.fields, ...{employees: selectedOptions.length ? selectedOptions : ""}},
                });
            }
       }).catch(err => console.log(err));
   }// end getEmpList()

    /**
	* For get work order listing
	*/
    getWorkOrderList = (fieldVal) =>{
         /** Set API request params */
         let apiReq = [{ model: 'calendar', method: 'workOrderList', job_id: fieldVal.value, is_all: true }]
		callBackendAPI(apiReq).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					workOrder : response.data[0].result,
				});
			}
		}).catch(err => console.log(err));
    }// end getWorkOrderList()

    handleChangeFormStep = (formStep) =>{
        this.setState({form_step: formStep});
    }// end handleChangeFormStep()

    handleNextFormState = (e,submitType,isForceAssign) => {
        let sendToAPI= false;
        const { department, start_time, end_time, project_manager, foreman, job_number, work_order, project_name, jobsite_address_street,jobsite_address_state,job_address_city, jobsite_address_zip, is_permit_required, is_prevailing_wage, is_out_of_town, lodging_information, is_badge_required,badges_required,is_safety_orientation_required, daily_project_value,scope_of_work_type, scope_of_work, materials,additional_equipment,jobsite_restrictions, crew_size, employees, equipment,contact_name, contact_email, contact_phone, notes, approved_line_items, is_locates_required, locate_type, expiration_date,ticket_number, is_flagger_required,is_subcontractor_required, is_inspector_required, inspector_name, inspector_email, inspector_phone, e2e_ticket, cm_ticket, fiber_task, fiber_other_task, hub_location, drill_size, hdd_locator_type, hdd_locator_type_other, length_of_bore, product_size, dump_site, water_source, is_potholing_required, surface_type, is_tracer_wire_required, traffic_control_requirements, specialized_tools_ctr, final_inspection_status, tco_date, is_electricians_required,po_number, po_value, image, subcontractor_id, flagger_subcontractor_id, location, is_have_order,jobsite_address, latitude, longitude, is_tbd, date_range, is_hide_weekend_date, jobsite_building, move_date } = this.state.fields;
        const {submitted, submittedAsDraft, is_move_date} = this.state;

        if(submitted || submittedAsDraft) return false;

        let empArr      = [];
        let equipArr    = [];
        let drilSizeArr   = [];
        let subcontractorArr   = [];
        let flaggerSubcontractorArr   = [];
        let surfaceTypeArr   = [];
        if(employees && employees.length) empArr = employees.map(empArr => empArr._id);
        if(equipment && equipment.length) equipArr = equipment.map(equipArr => equipArr._id);
        if(drill_size && drill_size.length) drilSizeArr = drill_size.map(element => element.value);
        if(subcontractor_id && subcontractor_id.length) subcontractorArr = subcontractor_id.map(element => element.value);
        if(flagger_subcontractor_id && flagger_subcontractor_id.length) flaggerSubcontractorArr = flagger_subcontractor_id.map(element => element.value);
        if(surface_type && surface_type.length) surfaceTypeArr = surface_type.map(element => element.value);


        this.setState({
            // errors      :   {},
            submitted           :   (submitType === "saveAsPublish")    ? true :  false ,
            submittedAsDraft    :   (submitType === "saveAsDraft")      ? true :  false ,
            fields      :   {
                ...this.state.fields,
                ...{
                    employee_id: {value: empArr.length ? empArr : null},
                    equipment_id: {value: equipArr.length ? equipArr : null},
                    drill_size_id: {value: drilSizeArr.length ? drilSizeArr : null},
                    subcontractor: {value: subcontractorArr.length ? subcontractorArr : null},
                    flagger_subcontractor: {value: flaggerSubcontractorArr.length ? flaggerSubcontractorArr : null},
                    surface_type_ids: {value: surfaceTypeArr.length ? surfaceTypeArr : null},
                }
            }
        },()=>{

            let startDate = (date_range && date_range[0])  ?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)		:"";
            let endDateDate = (date_range && date_range[1])  ?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)		:"";

            if(move_date && move_date.value){
                startDate = formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
                endDateDate = formatDate(move_date.value,CALENDAR_DATE_TIME_FORMAT);
            }
            /** Api Requested Data **/
            let params = [{
                model				    : 	'jobs',
                method				    : 	'add',
                job_id		    		: 	this.state.jobId ?	this.state.jobId :"",
                start_time				:	(start_time && start_time.value)    ?   formatTime(start_time.value,"HH:mm")    :"",
				end_time				:	(end_time && end_time.value)	    ?   formatTime(end_time.value,"HH:mm")	    :"",
                location			    : 	(location && location.value)        ?   location.value		                    :"",
                department			    : 	(department && department.value)    ?   department.value		                :"",

                start_date			    : 	startDate,
				end_date			    :	endDateDate,
                project_manager_id		: 	(project_manager && project_manager.value)                  ?	project_manager.value	        :"",
                foreman_id		        : 	(foreman && foreman.value)                                  ?	foreman.value	                :"",
                job_number_id		    : 	(job_number && job_number.value)                            ?	job_number.value	            :"",
                job_number		        : 	(job_number && job_number.job_number)                       ?	job_number.job_number	        :"",
                is_tbd 			        :   (is_tbd && is_tbd.value) 						            ? 	is_tbd.value 			        :"",
                is_have_order 			:   (is_have_order && is_have_order.value) 						? 	is_have_order.value 			:"",
                work_order_id		    : 	(work_order && !work_order["__isNew__"] && work_order.value)?	work_order.value	            :"",
                work_order_number		: 	(work_order && work_order.work_order_number)                ?	work_order.work_order_number	:(work_order && work_order["__isNew__"] ? work_order.value :""),
                project_name		    : 	(project_name && project_name.value)                        ?	project_name.value	            :"",
                crew_size		        : 	(crew_size && crew_size.value)                              ?	crew_size.value	                :"",
                employee_id		        : 	(empArr && empArr.length)                                   ?	empArr	                        :"",
                equipment_id		    : 	(equipArr && equipArr.length)                               ?	equipArr	                    :"",

                latitude	            : 	(latitude && latitude.value)                                ?	latitude.value	                :"",
                longitude	            : 	(longitude && longitude.value)                              ?	longitude.value	                :"",
                jobsite_address	        : 	(jobsite_address && jobsite_address.value)                  ?	jobsite_address.value	        :"",
                jobsite_building	    : 	(jobsite_building && jobsite_building.value)                ?	jobsite_building.value	    :"",
                jobsite_address_state	: 	(jobsite_address_state && jobsite_address_state.value)      ?	jobsite_address_state.value	    :"",
                jobsite_address_street	: 	(jobsite_address_street && jobsite_address_street.value)    ?	jobsite_address_street.value	:"",
                jobsite_address_city	: 	(job_address_city && job_address_city.value)                ?	job_address_city.value	        :"",
                jobsite_address_zip		: 	(jobsite_address_zip && jobsite_address_zip.value)          ?	jobsite_address_zip.value	    :"",

                is_permit_required		: 	(is_permit_required && is_permit_required.value)            ?	is_permit_required.value	    :"",
                is_prevailing_wage		: 	(is_prevailing_wage && is_prevailing_wage.value)            ?	is_prevailing_wage.value	    :"",
                is_out_of_town		    : 	(is_out_of_town && is_out_of_town.value)                    ?	is_out_of_town.value	        :"",
                lodging_information		: 	(lodging_information && lodging_information.value)          ?	lodging_information.value	    :"",
                is_badge_required		: 	(is_badge_required && is_badge_required.value)              ?	is_badge_required.value	        :"",
                badges_required		    : 	(badges_required && badges_required.value)                  ?	badges_required.value	        :"",
                is_safety_orientation_required:(is_safety_orientation_required && is_safety_orientation_required.value) ? is_safety_orientation_required.value:"",
                daily_project_value		: 	(daily_project_value && daily_project_value.value)          ?	daily_project_value.value	    :"",

                scope_of_work_type		: 	(scope_of_work_type && scope_of_work_type.value)            ?	scope_of_work_type.value	    :"",
                scope_of_work		    : 	(scope_of_work && scope_of_work.value)                      ?	scope_of_work.value	            :"",
                materials		        : 	(materials && materials.value)                              ?	materials.value	                :"",
                additional_equipment	: 	(additional_equipment && additional_equipment.value)        ?	additional_equipment.value	    :"",
                jobsite_restrictions	: 	(jobsite_restrictions && jobsite_restrictions.value)        ?	jobsite_restrictions.value	    :"",

                contact_name		    : 	(contact_name && contact_name.value)                        ?	contact_name.value	            :"",
                contact_email		    : 	(contact_email && contact_email.value)                      ?	contact_email.value	            :"",
                contact_phone		    : 	(contact_phone && contact_phone.value)                      ?	contact_phone.value             :"",

                notes		            : 	(notes && notes.value)                                      ?	notes.value	                    :"",
                approved_line_items		: 	(approved_line_items && approved_line_items.value)          ?	approved_line_items.value	    :"",
                is_locates_required		: 	(is_locates_required && is_locates_required.value)          ?	is_locates_required.value	    :"",
                locate_type		        : 	(locate_type && locate_type.value)                          ?	locate_type.value	            :"",
                expiration_date		    : 	(expiration_date && expiration_date.value)                  ?	formatDate(expiration_date.value,CALENDAR_DATE_TIME_FORMAT)	        :"",
                ticket_number		    : 	(ticket_number && ticket_number.value)                      ?	ticket_number.value	            :"",
                is_flagger_required		: 	(is_flagger_required && is_flagger_required.value)          ?	is_flagger_required.value	    :"",
                is_subcontractor_required: 	(is_subcontractor_required && is_subcontractor_required.value)?	is_subcontractor_required.value	:"",
                flagger_subcontractor_id: 	flaggerSubcontractorArr,
                subcontractor_id		: 	subcontractorArr,

                is_inspector_required	: 	(is_inspector_required && is_inspector_required.value)      ?	is_inspector_required.value	    :"",
                inspector_name		    : 	(inspector_name && inspector_name.value)                    ?	inspector_name.value	        :"",
                inspector_email		    : 	(inspector_email && inspector_email.value)                  ?	inspector_email.value	        :"",
                inspector_phone		    : 	(inspector_phone && inspector_phone.value)                  ?	inspector_phone.value	        :"",

                e2e_ticket		        : 	(e2e_ticket && e2e_ticket.value)                            ?	e2e_ticket.value	            :"",
                cm_ticket		        : 	(cm_ticket && cm_ticket.value)                              ?	cm_ticket.value	                :"",
                fiber_task		        : 	(fiber_task && fiber_task.value)                            ?	fiber_task.value	            :"",
                fiber_other_task		: 	(fiber_other_task && fiber_other_task.value)                ?	fiber_other_task.value	        :"",
                hub_location		    : 	(hub_location && hub_location.value)                        ?	hub_location.value	            :"",
                drill_size		        : 	drilSizeArr,
                hdd_locator_type		: 	(hdd_locator_type && hdd_locator_type.value)                ?	hdd_locator_type.value	        :"",
                hdd_locator_type_other	: 	(hdd_locator_type_other && hdd_locator_type_other.value)    ?	hdd_locator_type_other.value	:"",
                length_of_bore		    : 	(length_of_bore && length_of_bore.value)                    ?	length_of_bore.value	        :"",
                product_size		    : 	(product_size && product_size.value)                        ?	product_size.value	            :"",
                dump_site		        : 	(dump_site && dump_site.value)                              ?	dump_site.value	                :"",
                water_source		    : 	(water_source && water_source.value)                        ?	water_source.value	            :"",
                is_potholing_required	: 	(is_potholing_required && is_potholing_required.value)      ?	is_potholing_required.value	    :"",
                surface_type		    : 	(surfaceTypeArr && surfaceTypeArr.length)                   ?	surfaceTypeArr	                :"",
                surface_type_ids		: 	(surfaceTypeArr && surfaceTypeArr.length)                   ?	surfaceTypeArr	                :"",
                is_tracer_wire_required	: 	(is_tracer_wire_required && is_tracer_wire_required.value)  ?	is_tracer_wire_required.value	:"",
                traffic_control_requirements:(traffic_control_requirements && traffic_control_requirements.value)?traffic_control_requirements.value:"",
                specialized_tools_ctr	: 	(specialized_tools_ctr && specialized_tools_ctr.value)      ?	specialized_tools_ctr.value	    :"",
                final_inspection_status	: 	(final_inspection_status && final_inspection_status.value)  ?	final_inspection_status.value	:"",

                tco_date			    : 	(tco_date && tco_date.value)		                        ?	formatDate(tco_date.value,CALENDAR_DATE_TIME_FORMAT)		            :"",
                is_electricians_required: 	(is_electricians_required && is_electricians_required.value)?	is_electricians_required.value	:"",
                po_number	            : 	(po_number && po_number.value)                              ?	po_number.value	                :"",
                po_value	            : 	(po_value && po_value.value)                                ?	po_value.value	                :"",
                is_hide_weekend_date    :   (is_hide_weekend_date && is_hide_weekend_date.value)        ? 	is_hide_weekend_date.value 		:"",
                is_move_date            :   is_move_date
            }];

            if(isForceAssign) params[0]['force_assign'] = true;

            let validations = {
                foreman	        :   { "label": translate('job.foreman'), "rules" :["select"] },
                location	    :   { "label": translate('job.location'), "rules" :["select"] },
                department	    :   { "label": translate('job.department'), "rules" :["select"] },
                project_manager :   { "label": translate('job.project_manager'), "rules" :["select"] },
                project_name    :   { "label": translate('job.project_name'), "rules" :["required"] },
            };
            if(is_tbd && is_tbd.value === String(RADIO_YES)){
                validations['job_number'] = { "label": translate('job.job_number'), "rules" :["select"] };
                if(is_have_order && is_have_order.value === String(RADIO_YES)) validations['work_order'] = { "label": translate('job.work_order'), "rules" :["select"] };
            }
            if(is_out_of_town && is_out_of_town.value === String(RADIO_YES)){
                validations['lodging_information'] = { "label": translate('job.lodging_information'), "rules" :["required"] };
            }
            if(is_badge_required && is_badge_required.value === String(RADIO_YES)){
                validations['badges_required'] = { "label": translate('job.badges_required'), "rules" :["select"] };
            }
            if(is_locates_required && is_locates_required.value === String(RADIO_YES)){
                validations['locate_type'] = { "label": translate('job.locate_type'), "rules" :["select"] };
            }
            if(is_flagger_required && is_flagger_required.value === String(RADIO_YES)){
                validations['flagger_subcontractor'] = { "label": translate('job.subcontractor'), "rules" :["select"] };
            }
            if(is_subcontractor_required && is_subcontractor_required.value === String(RADIO_YES)){
                validations['subcontractor'] = { "label": translate('job.subcontractor'), "rules" :["select"] };
            }
            if(is_inspector_required && is_inspector_required.value === String(RADIO_YES)){
                validations['inspector_name'] = { "label": translate('job.inspector_name'), "rules" :["required"] };
                validations['inspector_email'] = { "label": translate('job.inspector_email'), "rules" :["required", "email"] };
                validations['inspector_phone'] = { "label": translate('job.inspector_phone'), "rules" :["required","mobile_number"] };
            }
            if(is_potholing_required && is_potholing_required.value === String(RADIO_YES)){
                validations['surface_type_ids'] = { "label": translate('job.surface_type'), "rules" :["select"] };
            }

            params[0]['is_draft'] = true;

            if(submitType !== "saveAsPublish" && contact_name && contact_email){
                validations = {...validations,...{
                    contact_email               : { "label": translate('job.contact_email'), "rules" :["required", "email"] },
                    contact_phone               : { "label": translate('job.contact_phone'), "rules" :["required","mobile_number"] },
                }}
            }

            if(submitType === "saveAsPublish"){
                validations = {...validations,...{
                    scope_of_work_type : { "label": translate('job.scope_of_work_type'), "rules" :["select"] },
                    scope_of_work : { "label": translate('job.scope_of_work'), "rules" :["required"] },
                    jobsite_address : { "label": translate('job.jobsite_address'), "rules" :["required"] },
                }}
                params[0]['is_draft'] = false;
            }

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params, sendToAPI,true,true).then(async(response)=>{
                this.setState({ submitted: false, submittedAsDraft: false});
                if(!response.success){
                    return this.setState({
                        errors          :   response.errors,
                        errorStepList   :   getErrorStep(response.errors)
                    });
                }

                if(e === "step_1" && submitType === "changeFormStep"){
                    this.setState({form_step: "step_1"});
                    return;
                }else if(e === "step_2" && submitType === "changeFormStep"){
                    this.setState({form_step: "step_2"});
                    return;
                }else if(e === "step_3" && submitType === "changeFormStep"){
                    this.setState({form_step: "step_3"});
                    return;
                }

                this.setState({
                    submitted           :   (submitType === "saveAsPublish")   ? true :  false ,
                    submittedAsDraft    :   (submitType === "saveAsDraft")     ? true :  false ,
                },()=>{

                    let filesArray = [];
                    if (image && image.files  && image.files.length) {
                        filesArray = image.files;
                    }

                    /** Set form data */
                    let formData = new FormData();
                    // if(image && image.value) formData.append("attachment", image.value);
                    filesArray.forEach(file => {
                        formData.append("attachment", file);
                    });

                    callBackendAPIAndImageUpload({ model: 'jobs', method: 'add', fileData: formData, formData: params[0]},true).then(response => {
                        if(response.success && response.data.status === STATUS_SUCCESS) {
                            /** Show success flash message **/
                            reFormatSuccess(response.data.message);

                            /** Remove disable attribute from submit button */
                            this.setState({
                                form_step   : "step_1",
                                fields      : {},
                                submitted   : false,
                                errors      : {},
                                isEdit      : false,
                                isCopy      : false,
                                submittedAsDraft: false,
                                errorStepList   : []
                            },()=>{
                                this.props.handleCloseTaskModal();
                                window.closeModal('myModal');
                                /**Reset calendar after job added */
                                this.props.updateEventCalendar(null,"complete_calendar");
                            });
                        }else if(response.data.message){
                            let tmpErrors =  reFormatErrors(response.data.message);
                            let isEmpOrEquip = (tmpErrors.employee_id || tmpErrors.equipment_id) ? true : false;

                            this.setState({
                                submitted       :   false,
                                submittedAsDraft:   false,
                                errorStepList   :   tmpErrors ? getErrorStep(tmpErrors) :[],
                                errors          :   reFormatErrors(response.data.message),
                            },()=>{
                                let message = tmpErrors.employee_id || tmpErrors.equipment_id;
                                if(isEmpOrEquip){
                                    window.closeModal('myModal');

                                    let confirmation = customConfirmBox(message);
                                    confirmation.then((confirmed) => {
                                        if (confirmed) {
                                            this.handleNextFormState(e,submitType,true)
                                        }else{
                                            window.openModal('myModal');
                                        }
                                    });
                                }
                            });

                        }
                    }).catch(err => console.log(err));
                })
            }).catch(err => console.log(err));
        });
    }// end handleNextFormState()

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response },()=>{
            if(field === "is_tbd" && this.state.fields.is_tbd && this.state.fields.is_tbd.value !== "1"){
                this.setState({ fields: {...this.state.fields, ...{job_number: null },...{is_have_order: {type: "radio",value: "0"} },...{work_order: null }} });
            }
            if(field === "is_have_order" && this.state.fields.is_have_order && this.state.fields.is_have_order.value !== "1"){
				this.setState({ fields: {...this.state.fields, ...{work_order: null }} });
			}
        });
	}// end handleFormInputs()

    /**Handle to set previous form step */
    handlePrevFormState = (step) => {
        this.setState({
            form_step: step,
        });
    }// end handlePrevFormState()

    /**Handle dropdown value state */
    handleChange = (fieldName) => (fieldVal) => {

        if(fieldName === "start_time") 	fieldVal = {value: fieldVal};
		if(fieldName === "end_time") 	fieldVal = {value: fieldVal};

        let newObj = {[fieldName]: fieldVal};
        let updatedFields = {...this.state.fields, ...newObj};

        if(fieldName === "start_time" || fieldName === "end_time" || fieldName === "date_range"){
            updatedFields['random_key'] = Math.random();
        }

        if(fieldName === "move_date"){
            updatedFields['move_date'] = {value: fieldVal}
        }

        if (fieldName === "date_range") {
            let startDate = fieldVal[0];
            if (startDate) updatedFields['is_hide_weekend_date'] = { value: isWeekendDay(startDate) ? String(RADIO_YES) : String(RADIO_NO) };
        }

        if(fieldName === "job_number"){
            updatedFields['work_order'] = null;
            this.getWorkOrderList(fieldVal);
            updatedFields.project_name = (fieldVal && fieldVal.project_name) ? {value: fieldVal.project_name}  : "";
        }

        if(fieldName === "location"){
            updatedFields['department'] = null;
            if(fieldVal.zip_code) updatedFields.jobsite_address_zip = {value: fieldVal.zip_code};
            this.handleChange(null, "address", fieldVal);
        }

        if(fieldName === "foreman"){
            if (fieldName === "foreman") {
                if (!updatedFields.employees) updatedFields.employees = [];
                const employeeExists = updatedFields.employees.some(employee => employee._id === fieldVal.id);
                if (!employeeExists) {
                    updatedFields.employees = [
                        ...updatedFields.employees,
                        {
                            _id: fieldVal.id,
                            label: fieldVal.full_name,
                            value: fieldVal.value
                        }
                    ];
                }
            }
        }

        if(fieldName === "importEmployeeForeman")  updatedFields.importEmployeeJobNumber = null;
        if(fieldName === "importEquipmentForeman") updatedFields.importEquipmentJobNumber = null;

        if(fieldName === "department" && fieldVal.value === CTR_DEPARTMENT){
            updatedFields.start_time    = {value: manageCTRShiftTime()}
            updatedFields.end_time      = {value: manageCTRShiftTime(true)}
        }
        if(fieldName === "department" && fieldVal.value !== CTR_DEPARTMENT){
            updatedFields.start_time    = {value: manageShiftTime()}
            updatedFields.end_time      = {value: manageShiftTime(true)}
        }
        console.log("fieldName ",fieldName)

        this.setState({fields: updatedFields},()=>{
            if(fieldName === "start_time" || fieldName === "end_time" || fieldName === "date_range"){
                this.getEmpList();
                this.getEquipList();
            }
        });
    }; // end handleChange()

    /**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        this.setState({ submitted: false, submittedAsDraft: false, errors: {}, fields: {} ,form_step: "step_1"});
        this.props.handleCloseTaskModal();
        window.closeModal('myModal');
	}// end closeModal()

    /**Handle onclick function for remove job */
    handleRemoveJob = (e) =>{
        e.preventDefault();
        const { jobId } = this.state;

        let confirmation = customConfirmBox(translate("calendar.you_want_to_remove_this_job"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [ { model: 'jobs', method: 'deleteJob', job_id: jobId},]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        reFormatSuccess(response.data[0].message)
                        this.closeModal()
                        this.props.updateEventCalendar(null,"calendar");
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

    handlePlaceChange = (fieldName) => (fieldVal) => {
        let newObj = {[fieldName]: {value: fieldVal}};
        let updatedFields = {...this.state.fields, ...newObj};

        this.setState({
            fields: updatedFields
        });
    };

    handleSelect = jobsite_address => {
        let updatedFields = { ...this.state.fields };

        geocodeByAddress(jobsite_address).then(results => {
            // Extract latitude and longitude
            return getLatLng(results[0]).then(latLng => {
                // Extract address components
                const addressComponents = results[0].address_components;
                let city = '';
                let state = '';
                let streetName = '';
                let zipCode = '';

                addressComponents.forEach(component => {
                    if (component.types.includes('locality')) {
                        city = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name; // State code (e.g., CA)
                    }
                    if (component.types.includes('route')) {
                        streetName = component.long_name; // Street name (e.g., Main St)
                    }
                    if (component.types.includes('postal_code')) {
                        zipCode = component.long_name; // ZIP code (e.g., 94103)
                    }
                });

                // Update state with extracted information
                updatedFields = {
                    ...updatedFields,
                    jobsite_address: { value: jobsite_address },
                    jobsite_address_state: { value: state },
                    job_address_city: { value: city },
                    jobsite_address_street: { value: streetName },
                    jobsite_address_zip: { value: zipCode },
                    latitude: (latLng && latLng.lat) ? { value : latLng.lat} : "",
                    longitude: (latLng && latLng.lng) ? { value : latLng.lng} : "",
                };

                this.setState({
                    fields: updatedFields
                });
            });
        }).catch(error => console.error('Error', error));
    };

    handleDrop = (acceptedFiles) => {
		this.setState(prevState => ({
			fields: {
			...prevState.fields,
			image: {
				file_count: acceptedFiles.length,
				files: acceptedFiles
			}
			},
			errors: { ...prevState.errors, image: '' } // Reset image errors on change
		}));
	};

    /**
	* For delete attachment image
	*/
	deleteAttachment = (e,job_id,attachment_id,image_id,parentIndex,childIndex) =>{
		e.preventDefault();
        const { job_data } = this.state;
		let confirmation = customConfirmBox(translate("calendar.you_want_to_delete_this_attachment"));
		confirmation.then((confirmed) => {
            if (confirmed) {
				callBackendAPI([{ model: 'jobs', method: 'deleteAttachment',job_id: job_id, attachment_id: attachment_id, image_id: image_id}]).then(response=>{
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						/** Show success flash message **/
						reFormatSuccess(response.data[0].message);

                        job_data.attachment[parentIndex].image.splice(childIndex, 1);
						this.setState({
                            job_data : job_data
                        });
					}
				}).catch(err => console.log(err));
			}
		});
	}// end deleteAttachment()

    /**
	* For delete whole attachment data
	*/
	deleteJobAttachment = (e,job_id,attachment_id,indexPos) =>{
		e.preventDefault();
        const { job_data } = this.state;
		let confirmation = customConfirmBox(translate("calendar.you_want_to_delete_this_attachment"));
		confirmation.then((confirmed) => {
            if (confirmed) {
				callBackendAPI([{ model: 'jobs', method: 'deleteJobAttachment',job_id: job_id, attachment_id: attachment_id}]).then(response=>{
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						/** Show success flash message **/
						reFormatSuccess(response.data[0].message);

                       job_data.attachment.splice(indexPos, 1);
						this.setState({
                            job_data : job_data
                        });
					}
				}).catch(err => console.log(err));
			}
		});
	}// end deleteJobAttachment()

    /** Function to handle image lightbox */
	showImageLightbox = (imageUrl)=>{
		this.setState({
			lightBoxShow	:	true,
			lightBoxImgUrl	:	imageUrl
		});
	}

    /** For download image */
	handleDownload = () => {
		const {lightBoxImgUrl} = this.state;
		const link = document.createElement('a');
		link.href = lightBoxImgUrl;
		link.target = "_new";
		link.download = lightBoxImgUrl;
		link.click(); // Trigger the download
	};

    handleMenuOpen = () => {
        this.getEmpList();
    };
    handleEquipMenuOpen = () => {
        this.getEquipList();
    };

    filterArrayValue = (arrayOne = [], arrayTwo =[])=>{
        return [...arrayOne, ...arrayTwo.filter(itemB => !arrayOne.some(itemS => itemS.value === itemB.value))];
    };

    render() {
        const {submitted, submittedAsDraft, form_step, fields, workOrder, hddDrillSize, headendHubLocation, specializedToolsTtr, location, badges, isCopy, isEdit,isDraft, fiberTask, scopeWorkType, errorStepList, hddLocatorType, locateType, surfaceType, isMobileScreen, job_data, fileUrl, lightBoxShow, lightBoxImgUrl, random_key, employeesList, equipmentList} = this.state;

        let nextStep    =   form_step === "step_1" ? "step_2" : (form_step === "step_2") ? "step_3" : "";
        let preStep     =   form_step === "step_3" ? "step_2" : (form_step === "step_2") ? "step_1" : "";

        return <>
            <div className="modal fade  crew-modal" id="myModal"  data-bs-backdrop='static' data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
                        {isEdit &&
                            <button type="button" className="job-delete-btn" onClick={(e)=> { this.handleRemoveJob(e)}}><img src={DeleteIcon} alt="img" /></button>
                        }
                        <div className="modal-body">
                            <h3>{isCopy ? translate('calendar.clone_job') :(isEdit ? translate('calendar.update_job') :translate('calendar.add_job'))}</h3>
                            <form onSubmit={(event) => this.handleNextFormState(event, 'addUser')} noValidate>
                                <div className='add-job-group'>
                                    <div className={"row"}>

                                        <ul className="checkout-process-strip d-flex align-items-center">
                                            <li className={(form_step === "step_1" ? 'completed' :"")+" "+(errorStepList.indexOf('step_1') !== -1 ? 'incompleted' : "" )}>
                                                <p>{translate('calendar.step_1')}</p>
                                                <div className="process-inner-wrap">
                                                    <span>
                                                        <button type="button" className="btn btn-fill" onClick={() => this.handleChangeFormStep('step_1',"changeFormStep")}> 1 </button>
                                                    </span>
                                                </div>
                                            </li>
                                            <li className={(form_step === "step_2" ? 'completed' :"")+" "+(errorStepList.indexOf('step_2') !== -1 ? 'incompleted' : "" )}>
                                                <p>{translate('calendar.step_2')}</p>
                                                <div className="process-inner-wrap">
                                                    <span>
                                                        <button type="button" className="btn btn-fill" onClick={() => this.handleChangeFormStep('step_2',"changeFormStep")}> 2 </button>
                                                    </span>
                                                </div>
                                            </li>
                                            <li className={(form_step === "step_3" ? 'completed' :"")+" "+(errorStepList.indexOf('step_3') !== -1 ? 'incompleted' : "" )}>
                                                <p>{translate('calendar.step_3')}</p>
                                                <div className="process-inner-wrap">
                                                    <span>
                                                        <button type="button" className="btn btn-fill" onClick={() => this.handleChangeFormStep('step_3',"changeFormStep")}> 3 </button>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>

                                        {form_step === "step_1" ?
                                            <>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.basic_details')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-6">
                                                                <div className='form-group'>
                                                                    <label htmlFor="location" className="form-label">{translate('calendar.location')} <span className="error text-danger">*</span></label>
                                                                    <Select
                                                                        className="custom-react-select"
                                                                        value={fields.location  ? fields.location : null}
                                                                        options={location}
                                                                        onChange={this.handleChange("location")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={<label htmlFor="location">{translate('calendar.select_location')}</label>}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["location"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className='form-group'>
                                                                    <label htmlFor="department" className="form-label">{translate('calendar.department')} <span className="error text-danger">*</span></label>
                                                                    <Select
                                                                        className="custom-react-select"
                                                                        value={fields.department ? fields.department: null}
                                                                        options={(fields.location && fields.location.department) ? DEPARTMENT_DROPDOWN.filter(department => fields.location.department.includes(department.value)) : []}
                                                                        onChange={this.handleChange("department")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={<div><label htmlFor="department">{translate('calendar.select_department')}</label></div>}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["department"]}</span>
                                                                </div>
                                                            </div>
                                                            {!isEdit ?
                                                                <>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group" id="datwwwdatwww">
                                                                            <label htmlFor="start_date" className="form-label">{translate('calendar.start_date')}<span className="error text-danger">*</span></label>
                                                                            <DateRangePicker
                                                                                value={fields.date_range ? fields.date_range :null}
                                                                                format={DATE_RANGE_PICKER_FORMAT}
                                                                                size="lg"
                                                                                className='form-control input-with-label date-range-picker-common'
                                                                                onChange={this.handleChange("date_range")}
                                                                                placeholder={translate('reports.search_by_date')}
                                                                                cleanable={false}
                                                                                ranges={[]}
                                                                                editable={false}
                                                                                showOneCalendar={isMobileScreen ? true : false}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["start_date"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="is_hide_weekend_date" className="form-label">{translate('calendar.is_hide_weekend_date')} </label>
                                                                            <div className='form-group checkbox-row order-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_YES) ? true :false}/>
                                                                                    <label className="form-check-label" htmlFor="is_hide_weekend_dateyes">
                                                                                    {translate('calendar.yes')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_hide_weekend_dateno">
                                                                                    {translate('calendar.no')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="move_date" className="form-label">{translate('calendar.date')} </label>
                                                                        <DatePicker
                                                                            selected={fields.move_date && fields.move_date.value ? fields.move_date.value : null}
                                                                            onChange={(date) => {
                                                                                this.setState({
                                                                                    fields: {
                                                                                        ...fields,
                                                                                        move_date: { value: date }
                                                                                    },
                                                                                    is_move_date: true
                                                                                },()=>{
                                                                                    this.getEmpList();
                                                                                    this.getEquipList();
                                                                                });
                                                                            }}
                                                                            className="form-control"
                                                                            dateFormat={DATE_PICKER_DATE_FORMAT}
                                                                            minDate={getDefaultDateRange(true)}
                                                                            placeholderText={translate('calendar.select_date')}
                                                                            dropdownMode="select"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                        />
                                                                        <span className="error text-danger">{this.state.errors["move_date"]}</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="start_time" className="form-label">{translate('calendar.start_time')}</label>
                                                                    <DatePicker
                                                                        selected={fields.start_time && fields.start_time.value ? fields.start_time.value : null}
                                                                        onChange={this.handleChange('start_time')}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={1}
                                                                        timeCaption={translate("calendar.time")}
                                                                        dateFormat={TIME_PICKER_FORMAT}
                                                                        timeFormat={TIME_PICKER_FORMAT}
                                                                        className="form-control"
                                                                        placeholderText={translate('calendar.start_time')}
                                                                        customInput={<CustomTimeInput />}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["start_time"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="end_time" className="form-label">{translate('calendar.end_time')}</label>
                                                                    <DatePicker
                                                                        selected={fields.end_time && fields.end_time.value ? fields.end_time.value : null}
                                                                        onChange={this.handleChange('end_time')}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={1}
                                                                        timeCaption={translate("calendar.time")}
                                                                        dateFormat={TIME_PICKER_FORMAT}
                                                                        timeFormat={TIME_PICKER_FORMAT}
                                                                        className="form-control"
                                                                        placeholderText={translate('calendar.end_time')}
                                                                        customInput={<CustomTimeInput />}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["end_time"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields ">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.job_details')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="project_manager" className="form-label">{translate('calendar.project_manager')} <span className="error text-danger">*</span></label>
                                                                    <AsyncPaginate
                                                                        className="custom-react-select"
                                                                        value={fields.project_manager  ? fields.project_manager : null}
                                                                        loadOptions={this.getDropDownList}
                                                                        onChange={this.handleChange("project_manager")}
                                                                        additional={{ page: 1, type: "projectmanager"}}
                                                                        placeholder={<label htmlFor="project_manager">{translate('calendar.select_project_manager')}</label>}
                                                                        styles={customStyles}
                                                                        components={customComponents}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["project_manager"]}</span>
                                                                    <span className="error text-danger">{this.state.errors["project_manager_id"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="foreman" className="form-label">{translate('calendar.foreman')} <span className="error text-danger">*</span></label>
                                                                    <AsyncPaginate
                                                                        className="custom-react-select"
                                                                        value={fields.foreman  ? fields.foreman : null}
                                                                        loadOptions={this.getDropDownList}
                                                                        onChange={this.handleChange("foreman")}
                                                                        additional={{ page: 1, type: "foreman"}}
                                                                        placeholder={<label htmlFor="foreman">{translate('calendar.select_foreman')}</label>}
                                                                        styles={customStyles}
                                                                        components={customComponents}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["foreman"]}</span>
                                                                    <span className="error text-danger">{this.state.errors["foreman_id"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="project_name" className="form-label">{translate('calendar.project_name')} <span className="error text-danger">*</span></label>
                                                                    <input type="text" className="form-control" name='project_name' placeholder="Project Name" id='project_name' value={fields["project_name"] ? fields["project_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "project_name")}/>
                                                                    <span className="error text-danger">{this.state.errors["project_name"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 crew-modal">
                                                                <div className="form-group">
                                                                    <label htmlFor="crew_size" className="form-label">{translate('calendar.crew_size')} </label>
                                                                    <input
                                                                        type="text"
                                                                        id='crew_size'
                                                                        name='crew_size'
                                                                        className="form-control"
                                                                        placeholder="Crew Size"
                                                                        value={fields["crew_size"] ? fields["crew_size"]["value"] : ""}
                                                                        onChange={(event) => this.handleFormInputs(event, "crew_size")}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["crew_size"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="employees" className="form-label import-data-label d-flex justify-content-between align-items-center">{translate('calendar.employees')}
                                                                        <a data-bs-toggle="collapse" href="#importCollapse" className="import-collapse-btn" role="button" aria-expanded="false" aria-controls="importCollapse">{translate('calendar.import_from')}...</a>
                                                                    </label>
                                                                    <Select
                                                                        key={random_key}
                                                                        className="custom-react-select"
                                                                        value={fields.employees  ? fields.employees : null}
                                                                        options={employeesList}
                                                                        onChange={this.handleChange("employees")}
                                                                        closeMenuOnSelect={true}
                                                                        onMenuOpen={this.handleMenuOpen}
                                                                        isMulti
                                                                        placeholder={<label htmlFor="employees">{translate('calendar.select_employees')}</label>}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["employees"]}</span>
                                                                    <span className="error text-danger">{this.state.errors["employee_id"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">

                                                                <div className="form-group ">
                                                                    <label htmlFor="equipment" className="form-label import-data-label d-flex justify-content-between align-items-center">{translate('calendar.equipment')}

                                                                        {fields.employees && fields.employees.length ?
                                                                            <Link to="#" className="ms-auto equipment-add-btn" onClick={()=>{this.getEquipList(true)}} >
                                                                                <img src={addBlueIcon} className='' alt="info-icon" />
                                                                            </Link>
                                                                        :null}
                                                                        <a data-bs-toggle="collapse" href="#importCollapse2" className="import-collapse-btn" role="button" aria-expanded="false" aria-controls="importCollapse2">{translate('calendar.import_from')}...</a>
                                                                    </label>
                                                                    <Select
                                                                        key={random_key}
                                                                        className="custom-react-select"
                                                                        value={fields.equipment  ? fields.equipment : null}
                                                                        options={equipmentList}
                                                                        onChange={this.handleChange("equipment")}
                                                                        closeMenuOnSelect={true}
                                                                        onMenuOpen={this.handleEquipMenuOpen}
                                                                        isMulti
                                                                        placeholder={<label htmlFor="equipment">{translate('calendar.select_equipments')}</label>}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["equipment"]}</span>
                                                                    <span className="error text-danger">{this.state.errors["equipment_id"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="collapse import-collapse" id="importCollapse">
                                                                            <div className="card card-body">
                                                                                {fields.importEmployeeJobNumber &&
                                                                                    <div className="card-head d-flex align-items-center justify-content-between">
                                                                                        <h3>{translate('calendar.import_from')}</h3>
                                                                                        <button type="button" className="btn btn-fill"
                                                                                            onClick={() => {
                                                                                                if (fields.importEmployeeJobNumber) {
                                                                                                    this.setState({
                                                                                                        fields      :   {
                                                                                                            ...this.state.fields,
                                                                                                            ...{
                                                                                                                employees: this.filterArrayValue(fields.employees  ? fields.employees :[], fields.importEmployeeJobNumber.employee_list),
                                                                                                            }
                                                                                                        }
                                                                                                    });
                                                                                                }
                                                                                            }}>{translate('system.submit')}
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                                <div className="row">
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEmployeeForeman" className="form-label">
                                                                                                {translate('calendar.foreman')} <span className="error text-danger">*</span>
                                                                                            </label>
                                                                                            <AsyncPaginate
                                                                                                className="custom-react-select"
                                                                                                value={fields.importEmployeeForeman ? fields.importEmployeeForeman : null}
                                                                                                loadOptions={this.getDropDownList}
                                                                                                onChange={this.handleChange("importEmployeeForeman")}
                                                                                                additional={{ page: 1, type: "foreman"}}
                                                                                                placeholder={<label htmlFor="importEmployeeForeman">{translate('calendar.select_foreman')}</label>}
                                                                                                styles={customStyles}
                                                                                                components={customComponents}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEmployeeDate" className="form-label">
                                                                                                {translate('calendar.date')} <span className="error text-danger">*</span>
                                                                                            </label>
                                                                                            <DatePicker
                                                                                                selected={fields.importEmployeeDate && fields.importEmployeeDate.value ? fields.importEmployeeDate.value : null}
                                                                                                onChange={(date) => {this.setState({ fields : {...fields, ...{importEmployeeDate:{value: date}, importEmployeeJobNumber: null, importEmployeeJobNumberKey: ((fields.importEmployeeForeman && fields.importEmployeeForeman.value ? fields.importEmployeeForeman.value :'')+ formatDate(date)) }} }) }}
                                                                                                className="form-control"
                                                                                                dateFormat={DATE_PICKER_DATE_FORMAT}
                                                                                                placeholderText={translate('calendar.select_date')}
                                                                                                dropdownMode="select"
                                                                                                showMonthDropdown
                                                                                                showYearDropdown
                                                                                                disabled={!fields.importEmployeeForeman || !fields.importEmployeeForeman.value ? true :false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEmployeeJobNumber" className="form-label">
                                                                                            {translate('calendar.job_work_order')}
                                                                                               <span className="error text-danger">*</span>
                                                                                            </label>
                                                                                            <AsyncPaginate
                                                                                                className="custom-react-select"
                                                                                                key={fields.importEmployeeJobNumberKey ? fields.importEmployeeJobNumberKey :1}
                                                                                                value={fields.importEmployeeJobNumber  ? fields.importEmployeeJobNumber : null}
                                                                                                loadOptions={this.getDropDownList}
                                                                                                onChange={this.handleChange("importEmployeeJobNumber")}
                                                                                                additional={{ page: 1, type: "importEmployeeJobNumber"}}
                                                                                                placeholder={<label htmlFor="importEmployeeJobNumber">{translate('calendar.select_job_work_order')}</label>}
                                                                                                styles={customStyles}
                                                                                                components={customComponents}
                                                                                                isDisabled={
                                                                                                    !fields.importEmployeeForeman || !fields.importEmployeeForeman.value  ||
                                                                                                    !fields.importEmployeeDate || !fields.importEmployeeDate.value
                                                                                                    ? true :false
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="collapse import-collapse2" id="importCollapse2">
                                                                            <div className="card card-body">
                                                                                {fields.importEquipmentJobNumber &&
                                                                                    <div className="card-head d-flex align-items-center justify-content-between">
                                                                                        <h3>{translate('calendar.import_from')}</h3>
                                                                                        <button type="button" className="btn btn-fill"
                                                                                            onClick={() => {
                                                                                                if (fields.importEquipmentJobNumber) {
                                                                                                    this.setState({
                                                                                                        fields      :   {
                                                                                                            ...this.state.fields,
                                                                                                            ...{
                                                                                                                equipment: this.filterArrayValue(fields.equipment  ? fields.equipment :[], fields.importEquipmentJobNumber.equipment_list),
                                                                                                            }
                                                                                                        }
                                                                                                    });
                                                                                                }
                                                                                            }}>{translate('system.submit')}
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                                <div className="row">
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEquipmentForeman" className="form-label">
                                                                                                {translate('calendar.foreman')} <span className="error text-danger">*</span>
                                                                                            </label>
                                                                                            <AsyncPaginate
                                                                                                className="custom-react-select"
                                                                                                value={fields.importEquipmentForeman ? fields.importEquipmentForeman : null}
                                                                                                loadOptions={this.getDropDownList}
                                                                                                onChange={this.handleChange("importEquipmentForeman")}
                                                                                                additional={{ page: 1, type: "foreman"}}
                                                                                                placeholder={<label htmlFor="importEquipmentForeman">{translate('calendar.select_foreman')}</label>}
                                                                                                styles={customStyles}
                                                                                                components={customComponents}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEquipmentDate" className="form-label">
                                                                                                {translate('calendar.date')} <span className="error text-danger">*</span>
                                                                                            </label>
                                                                                            <DatePicker
                                                                                                selected={fields.importEquipmentDate && fields.importEquipmentDate.value ? fields.importEquipmentDate.value : null}
                                                                                                onChange={(date) => {this.setState({ fields : {...fields, ...{importEquipmentDate:{value: date}, importEquipmentJobNumber: null, importEquipmentJobNumberKey: ((fields.importEquipmentForeman && fields.importEquipmentForeman.value ? fields.importEquipmentForeman.value :'')+ formatDate(date)) }} }) }}
                                                                                                className="form-control"
                                                                                                dateFormat={DATE_PICKER_DATE_FORMAT}
                                                                                                // minDate={new Date()}
                                                                                                placeholderText={translate('calendar.select_date')}
                                                                                                dropdownMode="select"
                                                                                                showMonthDropdown
                                                                                                showYearDropdown
                                                                                                disabled={!fields.importEquipmentForeman || !fields.importEquipmentForeman.value ? true :false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div className="form-group mb-3 mb-lg-0">
                                                                                            <label htmlFor="importEquipmentJobNumber" className="form-label"> {translate('calendar.job_work_order')}</label>
                                                                                            <AsyncPaginate
                                                                                                className="custom-react-select"
                                                                                                value={fields.importEquipmentJobNumber  ? fields.importEquipmentJobNumber : null}
                                                                                                key={fields.importEquipmentJobNumberKey ? fields.importEquipmentJobNumberKey :1}
                                                                                                loadOptions={this.getDropDownList}
                                                                                                onChange={this.handleChange("importEquipmentJobNumber")}
                                                                                                additional={{ page: 1, type: "importEquipmentJobNumber"}}
                                                                                                placeholder={<label htmlFor="importEquipmentJobNumber">{translate('calendar.select_job_work_order')}</label>}
                                                                                                styles={customStyles}
                                                                                                components={customComponents}
                                                                                                isDisabled={
                                                                                                    !fields.importEquipmentForeman || !fields.importEquipmentForeman.value  ||
                                                                                                    !fields.importEquipmentDate || !fields.importEquipmentDate.value
                                                                                                    ? true :false
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_tbd" className="form-label">{translate('calendar.is_tbd')} </label>
                                                                    <div className='form-group checkbox-row order-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_tbd" id="is_tbdyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_tbd")} defaultChecked={fields.is_tbd && fields.is_tbd.value === String(RADIO_YES) ? true :false}/>
                                                                            <label className="form-check-label" htmlFor="is_tbdyes">
                                                                            {translate('calendar.yes')}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_tbd" id="is_tbdno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_tbd")} defaultChecked={fields.is_tbd && fields.is_tbd.value === String(RADIO_NO) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_tbdno">
                                                                            {translate('calendar.no')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(fields.is_tbd && fields.is_tbd.value === String(ACTIVE)) &&
                                                                <>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="job_number" className="form-label">{translate('calendar.job_number')} # <span className="error text-danger">*</span></label>
                                                                            <AsyncPaginate
                                                                                className="custom-react-select"
                                                                                value={fields.job_number  ? fields.job_number : null}
                                                                                loadOptions={this.getDropDownList}
                                                                                onChange={this.handleChange("job_number")}
                                                                                additional={{ page: 1, type: "cannonJob"}}
                                                                                placeholder={<label htmlFor="job_number">{translate('calendar.select_job_number')}</label>}
                                                                                styles={customStyles}
                                                                                components={customComponents}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["job_number"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["job_number_id"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="is_have_order" className="form-label">{translate('calendar.not_have_work_order')} </label>
                                                                            <div className='form-group checkbox-row order-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_have_order" id="is_have_orderyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_have_order")} defaultChecked={fields.is_have_order && fields.is_have_order.value === String(RADIO_YES) ? true :false}/>
                                                                                    <label className="form-check-label" htmlFor="is_have_orderyes">
                                                                                    {translate('calendar.yes')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_have_order" id="is_have_orderno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_have_order")} defaultChecked={fields.is_have_order && fields.is_have_order.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_have_orderno">
                                                                                    {translate('calendar.no')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="work_order" className="form-label">{translate('calendar.work_order')} #
                                                                                {(fields.is_have_order && fields.is_have_order.value === String(ACTIVE)) ?
                                                                                    <span className="error text-danger"> *</span> : ""
                                                                                }
                                                                            </label>
                                                                            <Creatable
                                                                                className="custom-react-select"
                                                                                value={fields.work_order  ? fields.work_order : null}
                                                                                options={workOrder}
                                                                                onChange={this.handleChange("work_order")}
                                                                                closeMenuOnSelect={true}
                                                                                placeholder={<label htmlFor="work_order">{translate('calendar.select_work_order')}</label>}
                                                                                styles={customStyles}
                                                                                components={customComponents}
                                                                                isDisabled={(fields.is_have_order && fields.is_have_order.value !== String(ACTIVE)) ? true : false}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["work_order"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["work_order_id"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["work_order_number"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :null}

                                        {form_step === "step_2" ?
                                            <>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields related-label-fields-2">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.site_address')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-6">
                                                                <div className="form-group google_field">
                                                                    <label htmlFor="jobsite_address" className="form-label">{translate('calendar.jobsite_address')} <span className="error text-danger">*</span></label>
                                                                    <PlacesAutocomplete
                                                                        value={fields["jobsite_address"] ? fields["jobsite_address"]["value"] : ""}
                                                                        onChange={this.handlePlaceChange("jobsite_address")}
                                                                        onSelect={this.handleSelect}
                                                                        >
                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                            <div>
                                                                                <input
                                                                                    {...getInputProps({
                                                                                    placeholder: 'Search Places ...',
                                                                                    className: 'location-search-input form-control',
                                                                                    })}
                                                                                />
                                                                                <div className="autocomplete-dropdown-container">
                                                                                    {loading && <div>Loading...</div>}
                                                                                    {suggestions.map((suggestion,index) => {
                                                                                        const className = suggestion.active
                                                                                            ? 'suggestion-item--active'
                                                                                            : 'suggestion-item';
                                                                                        // inline style for demonstration purpose
                                                                                        const style = suggestion.active
                                                                                            ? { backgroundColor: '#ddd', cursor: 'pointer' }
                                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                        return (
                                                                                            <div
                                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                                    className,
                                                                                                    style,
                                                                                                })}
                                                                                                key={suggestion.placeId || index}
                                                                                            >
                                                                                                <span>{suggestion.description}</span>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </PlacesAutocomplete>

                                                                    <span className="error text-danger">{this.state.errors["jobsite_address"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobsite_building" className="form-label">{translate('calendar.jobsite_building')} #</label>
                                                                    <input type="text" className="form-control" placeholder="Building/Suite #" name='jobsite_building' id='jobsite_building' value={fields["jobsite_building"] ? fields["jobsite_building"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "jobsite_building")} />
                                                                    <span className="error text-danger">{this.state.errors["jobsite_building"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.scope_of_work')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-6">
                                                                <div className='form-group'>
                                                                    <label htmlFor="location" className="form-label">{translate('calendar.scope_of_work_type')} <span className="error text-danger">*</span></label>
                                                                    <Select
                                                                        className="custom-react-select"
                                                                        value={fields.scope_of_work_type  ? fields.scope_of_work_type : null}
                                                                        options={scopeWorkType}
                                                                        onChange={this.handleChange("scope_of_work_type")}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={<label htmlFor="scope_of_work_type">{translate('calendar.select_scope_of_work_type')}</label>}
                                                                    />
                                                                    <span className="error text-danger">{this.state.errors["scope_of_work_type"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                <label htmlFor="scope_of_work" className="form-label">{translate('calendar.scope_of_work')} <span className="error text-danger">*</span></label>
                                                                    <textarea type="scope_of_work" className="form-control" name='scope_of_work' id='scope_of_work' value={fields["scope_of_work"] ? fields["scope_of_work"]["value"] : ""} placeholder={translate('calendar.scope_of_work')} onChange={(event) => this.handleFormInputs(event, "scope_of_work")}></textarea>
                                                                    <span className="error text-danger">{this.state.errors["scope_of_work"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="daily_project_value" className="form-label">{translate('calendar.daily_project_value')} ($) </label>
                                                                    <input type="text" className="form-control" placeholder={translate('calendar.daily_project_value')} name='daily_project_value' id='daily_project_value'  value={fields["daily_project_value"] ? fields["daily_project_value"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "daily_project_value")}/>
                                                                    <span className="error text-danger">{this.state.errors["daily_project_value"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="materials" className="form-label">{translate('calendar.materials')} </label>
                                                                    <textarea type="date" className="form-control" placeholder={translate('calendar.materials')} name='materials' id='materials' value={fields["materials"] ? fields["materials"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "materials")}></textarea>
                                                                    <span className="error text-danger">{this.state.errors["materials"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="additional_equipment" className="form-label">{translate('calendar.additional_equipment')} </label>
                                                                    <textarea type="date" className="form-control" placeholder={translate('calendar.additional_equipment')} name='additional_equipment' id='additional_equipment' value={fields["additional_equipment"] ? fields["additional_equipment"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "additional_equipment")}></textarea>
                                                                    <span className="error text-danger">{this.state.errors["additional_equipment"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.poc')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="contact_name" className="form-label">{translate('calendar.contact_name')} </label>
                                                                    <input type="text" className="form-control" placeholder={translate('calendar.contact_name')} name='contact_name' id='contact_name'  value={fields["contact_name"] ? fields["contact_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_name")}/>
                                                                    <span className="error text-danger">{this.state.errors["contact_name"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="contact_email" className="form-label">{translate('calendar.contact_email')} </label>
                                                                    <input type="text" className="form-control" placeholder={translate('calendar.contact_email')} name='contact_email' id='contact_email' value={fields["contact_email"] ? fields["contact_email"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_email")}/>
                                                                    <span className="error text-danger">{this.state.errors["contact_email"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="contact_phone" className="form-label">{translate('calendar.contact_phone')} </label>
                                                                    <input type="text" className="form-control" placeholder={translate('calendar.contact_phone')} name='contact_phone' id='contact_phone' value={fields["contact_phone"] ? fields["contact_phone"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_phone")}/>
                                                                    <span className="error text-danger">{this.state.errors["contact_phone"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields related-label-fields-2">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.other_details')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_permit_required_yes" className="form-label">{translate('calendar.is_permit_required')} </label>
                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_permit_required" id="is_permit_required_yes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_permit_required")} defaultChecked={fields.is_permit_required && fields.is_permit_required.value === String(RADIO_YES) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_permit_required_yes">{translate('calendar.yes')}</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_permit_required" id="is_permit_required_no" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_permit_required")} defaultChecked={fields.is_permit_required && fields.is_permit_required.value === String(RADIO_NO) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_permit_required_no">{translate('calendar.no')}</label>
                                                                        </div>
                                                                    </div>
                                                                    <span className="error text-danger">{this.state.errors["is_permit_required"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_prevailing_wage" className="form-label">{translate('calendar.is_prevailing_wage')} </label>
                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_prevailing_wage" id="is_prevailing_wageyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_prevailing_wage")} defaultChecked={fields.is_prevailing_wage && fields.is_prevailing_wage.value === String(RADIO_YES) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_prevailing_wageyes">{translate('calendar.yes')}</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_prevailing_wage" id="is_prevailing_wageno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_prevailing_wage")} defaultChecked={fields.is_prevailing_wage && fields.is_prevailing_wage.value === String(RADIO_NO) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_prevailing_wageno">
                                                                            {translate('calendar.no')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <span className="error text-danger">{this.state.errors["is_prevailing_wage"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_out_of_town" className="form-label">{translate('calendar.is_out_of_town')} </label>
                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_out_of_town" id="is_out_of_townyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_out_of_town")} defaultChecked={fields.is_out_of_town && fields.is_out_of_town.value === String(RADIO_YES) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_out_of_townyes">
                                                                            {translate('calendar.yes')}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_out_of_town" id="is_out_of_townno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_out_of_town")} defaultChecked={fields.is_out_of_town && fields.is_out_of_town.value === String(RADIO_NO) ? true :false}  />
                                                                            <label className="form-check-label" htmlFor="is_out_of_townno">
                                                                            {translate('calendar.no')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <span className="error text-danger">{this.state.errors["is_out_of_town"]}</span>
                                                                </div>
                                                            </div>
                                                            {(fields.is_out_of_town && fields.is_out_of_town.value === String(RADIO_YES)) &&
                                                                (<div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="lodging_information" className="form-label">{translate('calendar.lodging_information')}
                                                                            <span className="error text-danger"> *</span>
                                                                        </label>
                                                                        <textarea className="form-control" placeholder={translate('calendar.lodging_information')} name='lodging_information' id='lodging_information' value={fields["lodging_information"] ? fields["lodging_information"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "lodging_information")}></textarea>
                                                                        <span className="error text-danger">{this.state.errors["lodging_information"]}</span>
                                                                    </div>
                                                                </div>)
                                                            }
                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_badge_required" className="form-label">{translate('calendar.is_badge_required')} </label>
                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_badge_required" id="is_badge_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_badge_required")} defaultChecked={fields.is_badge_required && fields.is_badge_required.value === String(RADIO_YES) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_badge_requiredyes">
                                                                            {translate('calendar.yes')}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" type="radio" name="is_badge_required" id="is_badge_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_badge_required")} defaultChecked={fields.is_badge_required && fields.is_badge_required.value === String(RADIO_NO) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_badge_requiredno">
                                                                            {translate('calendar.no')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <span className="error text-danger">{this.state.errors["is_badge_required"]}</span>
                                                                </div>
                                                            </div>
                                                            {(fields.is_badge_required && fields.is_badge_required.value === String(RADIO_YES)) &&
                                                                (<div className="col-lg-12">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="badges_required" className="form-label">{translate('calendar.badges_required')} <span className="error text-danger"> *</span></label>
                                                                        <Select
                                                                            className="custom-react-select"
                                                                            value={fields.badges_required ? fields.badges_required : null}
                                                                            options={badges}
                                                                            onChange={this.handleChange("badges_required")}
                                                                            closeMenuOnSelect={true}
                                                                            placeholder={<label htmlFor="badges_required">{translate('calendar.select_badges_required')}</label>}
                                                                        />
                                                                        <span className="error text-danger">{this.state.errors["badges_required"]}</span>
                                                                    </div>
                                                                </div>)
                                                            }

                                                            <div className="col-lg-4">
                                                                <div className='form-group'>
                                                                    <label htmlFor="is_safety_orientation_required" className="form-label">{translate('calendar.is_safety_orientation_required')} </label>
                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" placeholder="Jobsite Address State" type="radio" name="is_safety_orientation_required" id="is_safety_orientation_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_safety_orientation_required")} defaultChecked={fields.is_safety_orientation_required && fields.is_safety_orientation_required.value === String(RADIO_YES) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_safety_orientation_requiredyes">
                                                                            {translate('calendar.yes')}
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline custom-checkbox">
                                                                            <input className="form-check-input" placeholder="Jobsite Address State" type="radio" name="is_safety_orientation_required" id="is_safety_orientation_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_safety_orientation_required")} checked={fields.is_safety_orientation_required && fields.is_safety_orientation_required.value === String(RADIO_NO) ? true :false} />
                                                                            <label className="form-check-label" htmlFor="is_safety_orientation_requiredno">
                                                                            {translate('calendar.no')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <span className="error text-danger">{this.state.errors["is_safety_orientation_required"]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="jobsite_restrictions" className="form-label">{translate('calendar.jobsite_restrictions')} </label>
                                                                    <textarea type="date" className="form-control" placeholder={translate('calendar.jobsite_restrictions')} name='jobsite_restrictions' id='jobsite_restrictions' value={fields["jobsite_restrictions"] ? fields["jobsite_restrictions"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "jobsite_restrictions")}></textarea>
                                                                    <span className="error text-danger">{this.state.errors["jobsite_restrictions"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :null}

                                        {form_step === "step_3" ?
                                            <>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="related-label-fields check">
                                                        <label className="related-label-head" htmlFor="fields-1">{translate('calendar.attachment')}</label>
                                                        <div className="row" id="fields-1">
                                                            <div className="col-lg-6">
                                                                <label htmlFor="attachment" className="form-label">
                                                                    {translate('calendar.attachment')}
                                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip} >
                                                                        <img src={infoIcon} className='ms-1' alt="info-icon" />
                                                                    </OverlayTrigger>
                                                                </label>
                                                                <Dropzone onDrop={this.handleDrop} multiple>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                    <div
                                                                        {...getRootProps({ className: 'dropzone' })}
                                                                        style={{ border: '2px dashed #ccc', padding: '25px', textAlign: 'center' }}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <p>
                                                                        {fields.image && fields.image.file_count ? `${fields.image.file_count} Attachment` : translate("system.select_attachment")}
                                                                        </p>
                                                                    </div>
                                                                    )}
                                                                </Dropzone>
                                                                <span className="error text-danger">{this.state.errors["image"]}</span>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="notes" className="form-label">{translate('calendar.attachment_description')} </label>
                                                                    <textarea type="notes" className="form-control" name='notes' placeholder={translate('calendar.attachment_description')} id='notes' value={fields["notes"] ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
                                                                    <span className="error text-danger">{this.state.errors["notes"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {job_data.attachment && job_data.attachment.length ?
                                                            <div className="attachmentBoxes-wrap">
                                                                <div class="attachmentBoxes--top d-block">
                                                                <button class="attachmentBoxes-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                                    All Attachment
                                                                </button>
                                                                </div>
                                                                <div class="collapse" id="collapseExample">
                                                                    <div class="card card-body p-0">
                                                                        <div className="attachment--boxes">
                                                                            {job_data.attachment.map((val,index) => (
                                                                                <div className="attachment--boxe">
                                                                                    {val.image && val.image.length ?
                                                                                        <div className="images-column">
                                                                                            {val.image.map((fileData,childIndex) => (
                                                                                                fileData.file_name && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileData.file_name.split('.').pop().toLowerCase()) !== -1 ?

                                                                                                   (<div className="image--box">
                                                                                                        <div className="image--box_iner">
                                                                                                            <figure>
                                                                                                                <img src={`${fileUrl}${fileData.name}`} alt="Img"  className="sort-name" onClick = {()=>{this.showImageLightbox(`${fileUrl}${fileData.name}`)}} />
                                                                                                            </figure>
                                                                                                            <button class="box-remove-btn" onClick={(e) => {this.deleteAttachment(e,job_data._id,val.id,fileData.id,index,childIndex)}}><img src={boxRemoveIcon} alt="img"/></button>
                                                                                                        </div>
                                                                                                    </div>)
                                                                                                :
                                                                                                (<>
                                                                                                    <a className="image--box document--box" href={`${fileUrl}${fileData.name}`} target="_blank" rel="noreferrer">
                                                                                                            <div className="image--box_iner">
                                                                                                                <figure><img src={documentIcon} alt="" width="50" height="50"/></figure>
                                                                                                                <button class="box-remove-btn" onClick={(e) => {this.deleteAttachment(e,job_data._id,val.id,fileData.id,index,childIndex)}}><img src={boxRemoveIcon} alt="img"/></button>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </>)
                                                                                            ))}
                                                                                        </div>
                                                                                        : ""
                                                                                    }
                                                                                    {val.notes &&
                                                                                        <div className="description-column">
                                                                                            <p>{val.notes}</p>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="attachmentActive-btn">
                                                                                        <span class="action-btn" onClick={(e) => {this.deleteJobAttachment(e,job_data._id,val.id,index)}}>
                                                                                            <img src={DeleteIcon} alt="user" width="16" height="16"/>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                                {(fields.department && fields.department.value !== ELEC_DEPARTMENT && fields.department.value !== OFFICE_DEPARTMENT) ?
                                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                        <div className="related-label-fields related-label-fields-3">
                                                            <label className="related-label-head" htmlFor="fields-1">{translate('calendar.department_type',null,fields.department ? fields.department.label : "")}</label>
                                                            <div className="row" id="fields-1">
                                                            {(fields.department && (fields.department.value === OSP_DEPARTMENT)) &&
                                                                <>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-group">
                                                                            <label htmlFor="approved_line_items" className="form-label">{translate('calendar.approved_line_items')} </label>
                                                                            <textarea type="approved_line_items" className="form-control" name='approved_line_items' id='approved_line_items' value={fields["approved_line_items"] ? fields["approved_line_items"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "approved_line_items")}></textarea>
                                                                            <span className="error text-danger">{this.state.errors["approved_line_items"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {(fields.department && (fields.department.value === OSP_DEPARTMENT || fields.department.value === CTR_DEPARTMENT || fields.department.value === HDD_DEPARTMENT || fields.department.value === FIBER_DEPARTMENT)) &&
                                                                <>
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="is_locates_required" className="form-label">{translate('calendar.is_locates_required')} </label>
                                                                        <div className='form-group checkbox-row employee-checkbox-row'>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_locates_required" id="is_locates_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_locates_required")} defaultChecked={fields.is_locates_required && fields.is_locates_required.value === String(RADIO_YES) ? true :false}
        />
                                                                                <label className="form-check-label" htmlFor="is_locates_requiredyes">
                                                                                {translate('calendar.yes')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_locates_required" id="is_locates_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_locates_required")} defaultChecked={fields.is_locates_required && fields.is_locates_required.value === String(RADIO_NO) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_locates_requiredno">
                                                                                {translate('calendar.no')}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span className="error text-danger">{this.state.errors["is_locates_required"]}</span>
                                                                    </div>
                                                                </div>
                                                                {(fields.is_locates_required && fields.is_locates_required.value=== String(RADIO_YES)) && (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="locate_type" className="form-label">{translate('calendar.locate_type')} <span className="error text-danger">*</span></label>
                                                                                    <div className='form-group checkbox-row employee-checkbox-row'>
                                                                                        {locateType.map((element, index)=>
                                                                                            <div className="form-check form-check-inline custom-checkbox mb-3" key={"locate_type-"+index}>
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"
                                                                                                    name="locate_type"
                                                                                                    id={element.value}
                                                                                                    value={element.value}
                                                                                                    onChange={(event) => this.handleFormInputs(event, "locate_type")}
                                                                                                    checked={fields.locate_type && fields.locate_type.value ?
                                                                                                        (fields.locate_type.value.constructor === Array && fields.locate_type.value.indexOf(element.value) !== -1 ?
                                                                                                            true
                                                                                                            :
                                                                                                            fields.locate_type.value.constructor === String && fields.locate_type.value === element.value?
                                                                                                                true
                                                                                                            :false
                                                                                                        )
                                                                                                    :false }
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor={element.value}>
                                                                                                    {element.label}
                                                                                                </label>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <span className="error text-danger">{this.state.errors["locate_type"]}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="expiration_date" className="form-label">{translate('calendar.expiration_date')} </label>
                                                                                    <DatePicker
                                                                                        selected={fields.expiration_date && fields.expiration_date.value ? fields.expiration_date.value : null}
                                                                                        onChange={(date) => {this.setState({ fields : {...fields, ...{expiration_date:{value: date} }} }) }}
                                                                                        className="form-control"
                                                                                        dateFormat={DATE_PICKER_DATE_FORMAT}
                                                                                        minDate={new Date()}
                                                                                        placeholderText={translate('calendar.expiration_date')}
                                                                                        dropdownMode="select"
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                    />
                                                                                    <span className="error text-danger">{this.state.errors["expiration_date"]}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="ticket_number" className="form-label">{translate('calendar.ticket_number')} </label>
                                                                                    <input type="text" className="form-control" name='ticket_number' id='ticket_number' value={fields["ticket_number"] ? fields["ticket_number"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "ticket_number")} placeholder={translate('calendar.ticket_number')} />
                                                                                    <span className="error text-danger">{this.state.errors["ticket_number"]}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>)
                                                                }
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="is_flagger_required" className="form-label">{translate('calendar.is_flagger_required')} </label>
                                                                        <div className='form-group checkbox-row employee-checkbox-row'>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_flagger_required" id="is_flagger_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_flagger_required")} defaultChecked={fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_YES) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_flagger_requiredyes">
                                                                                {translate('calendar.yes')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_flagger_required" id="is_flagger_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_flagger_required")} defaultChecked={fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_NO) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_flagger_requiredno">
                                                                                {translate('calendar.no')}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span className="error text-danger">{this.state.errors["is_flagger_required"]}</span>
                                                                    </div>
                                                                </div>
                                                                {(fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_YES)) &&
                                                                    <>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="subcontractor" className="form-label">{translate('calendar.flagging_subcontractor')}
                                                                                <span className="error text-danger">*</span>
                                                                            </label>
                                                                            <AsyncPaginate
                                                                                className="custom-react-select"
                                                                                value={fields.flagger_subcontractor_id  ? fields.flagger_subcontractor_id : null}
                                                                                loadOptions={this.getDropDownList}
                                                                                onChange={this.handleChange("flagger_subcontractor_id")}
                                                                                additional={{ page: 1,type: "flagger"}}
                                                                                placeholder={<label htmlFor="flagger_subcontractor_id">{translate('calendar.select_subcontractor')}</label>}
                                                                                styles={customStyles}
                                                                                isMulti
                                                                                components={customComponents}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["flagger_subcontractor"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["flagger_subcontractor_id"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                }
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="is_subcontractor_required" className="form-label">{translate('calendar.is_subcontractor_required')} </label>
                                                                        <div className='form-group checkbox-row employee-checkbox-row'>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_subcontractor_required" id="is_subcontractor_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_subcontractor_required")} defaultChecked={fields.is_subcontractor_required && fields.is_subcontractor_required.value === String(RADIO_YES) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_subcontractor_requiredyes">
                                                                                {translate('calendar.yes')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_subcontractor_required" id="is_subcontractor_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_subcontractor_required")} defaultChecked={fields.is_subcontractor_required && fields.is_subcontractor_required.value === String(RADIO_NO) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_subcontractor_requiredno">
                                                                                {translate('calendar.no')}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span className="error text-danger">{this.state.errors["is_subcontractor_required"]}</span>
                                                                    </div>
                                                                </div>
                                                                {(fields.is_subcontractor_required && fields.is_subcontractor_required.value === String(RADIO_YES)) &&
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="subcontractor" className="form-label">{translate('calendar.subcontractor_name')}
                                                                                <span className="error text-danger"> *</span>
                                                                            </label>
                                                                            <AsyncPaginate
                                                                                className="custom-react-select"
                                                                                value={fields.subcontractor_id  ? fields.subcontractor_id : null}
                                                                                loadOptions={this.getDropDownList}
                                                                                onChange={this.handleChange("subcontractor_id")}
                                                                                additional={{ page: 1, type: "subcontractor"}}
                                                                                placeholder={<label htmlFor="subcontractor_id">{translate('calendar.select_subcontractor')}</label>}
                                                                                isMulti
                                                                                styles={customStyles}
                                                                                components={customComponents}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["subcontractor"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["subcontractor_id"]}</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                </>
                                                            }

                                                            {(fields.department && (fields.department.value === OSP_DEPARTMENT || fields.department.value === CTR_DEPARTMENT || fields.department.value === HDD_DEPARTMENT || fields.department.value === FIBER_DEPARTMENT || fields.department.value === ISP_DEPARTMENT)) &&
                                                                <>
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                    <label htmlFor="is_inspector_required" className="form-label">{translate('calendar.is_inspector_required')} </label>
                                                                        <div className='form-group checkbox-row employee-checkbox-row'>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_inspector_required" id="is_inspector_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_inspector_required")} defaultChecked={fields.is_inspector_required && fields.is_inspector_required.value === String(RADIO_YES) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_inspector_requiredyes">
                                                                                {translate('calendar.yes')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline custom-checkbox">
                                                                                <input className="form-check-input" type="radio" name="is_inspector_required" id="is_inspector_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_inspector_required")} defaultChecked={fields.is_inspector_required && fields.is_inspector_required.value === String(RADIO_NO) ? true :false} />
                                                                                <label className="form-check-label" htmlFor="is_inspector_requiredno">
                                                                                {translate('calendar.no')}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span className="error text-danger">{this.state.errors["is_inspector_required"]}</span>
                                                                    </div>
                                                                </div>
                                                                {(fields.is_inspector_required && fields.is_inspector_required.value=== String(RADIO_YES)) &&
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="inspector_name" className="form-label">{translate('calendar.inspector_name')} <span className="error text-danger"> *</span></label>
                                                                                    <input type="text" className="form-control" name='inspector_name' id='inspector_name' value={fields["inspector_name"] ? fields["inspector_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "inspector_name")} placeholder={translate('calendar.inspector_name')} />
                                                                                    <span className="error text-danger">{this.state.errors["inspector_name"]}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="inspector_email" className="form-label">{translate('calendar.inspector_email')} <span className="error text-danger"> *</span></label>
                                                                                    <input type="text" className="form-control" name='inspector_email' id='inspector_email' value={fields["inspector_email"] ? fields["inspector_email"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "inspector_email")} placeholder={translate('calendar.inspector_email')} />
                                                                                    <span className="error text-danger">{this.state.errors["inspector_email"]}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="inspector_phone" className="form-label">{translate('calendar.inspector_phone')} <span className="error text-danger"> *</span></label>
                                                                                    <input type="text" className="form-control" name='inspector_phone' id='inspector_phone' value={fields["inspector_phone"] ? fields["inspector_phone"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "inspector_phone")} placeholder={translate('calendar.inspector_phone')} />
                                                                                    <span className="error text-danger">{this.state.errors["inspector_phone"]}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }
                                                                </>
                                                            }

                                                            {/** ISP_DEPARTMENT */}
                                                            {(fields.department && fields.department.value === ISP_DEPARTMENT) &&
                                                                <>
                                                                <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="is_electricians_required" className="form-label">{translate('calendar.is_electricians_required')} </label>
                                                                            <div className='form-group checkbox-row employee-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_electricians_required" id="is_electricians_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_electricians_required")} defaultChecked={fields.is_electricians_required && fields.is_electricians_required.value === String(RADIO_YES) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_electricians_requiredyes">
                                                                                    {translate('calendar.yes')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_electricians_required" id="is_electricians_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_electricians_required")} defaultChecked={fields.is_electricians_required && fields.is_electricians_required.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_electricians_requiredno">
                                                                                    {translate('calendar.no')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <span className="error text-danger">{this.state.errors["is_electricians_required"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="tco_date" className="form-label">{translate('calendar.tco_date')} </label>
                                                                            <DatePicker
                                                                                selected={fields.tco_date && fields.tco_date.value ? fields.tco_date.value : null}
                                                                                onChange={(date) => {this.setState({ fields : {...fields, ...{tco_date:{value: date} }} }) }}
                                                                                className="form-control"
                                                                                dateFormat={DATE_PICKER_DATE_FORMAT}
                                                                                minDate={new Date()}
                                                                                placeholderText={translate('calendar.tco_date')}
                                                                                dropdownMode="select"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["tco_date"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="po_number" className="form-label">{translate('calendar.po_number')} </label>
                                                                            <input type="text" className="form-control" name='po_number' id='po_number' value={fields["po_number"] ? fields["po_number"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "po_number")} placeholder={translate('calendar.po_number')} />
                                                                            <span className="error text-danger">{this.state.errors["po_number"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="po_value" className="form-label">{translate('calendar.po_value')} ($) </label>
                                                                            <input type="text" className="form-control" name='po_value' id='po_value' value={fields["po_value"] ? fields["po_value"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "po_value")} placeholder={translate('calendar.po_value')} />
                                                                            <span className="error text-danger">{this.state.errors["po_value"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/** FIBER_DEPARTMENT */}
                                                            {(fields.department && fields.department.value === FIBER_DEPARTMENT) &&
                                                                <>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="e2e_ticket" className="form-label">{translate('calendar.e2e_ticket')} </label>
                                                                        <input type="text" className="form-control" name='e2e_ticket' id='e2e_ticket' value={fields["e2e_ticket"] ? fields["e2e_ticket"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "e2e_ticket")} placeholder={translate('calendar.e2e_ticket')} />
                                                                        <span className="error text-danger">{this.state.errors["e2e_ticket"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="cm_ticket" className="form-label">{translate('calendar.cm_ticket')} </label>
                                                                        <input type="text" className="form-control" name='cm_ticket' id='cm_ticket' value={fields["cm_ticket"] ? fields["cm_ticket"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "cm_ticket")} placeholder={translate('calendar.cm_ticket')} />
                                                                        <span className="error text-danger">{this.state.errors["cm_ticket"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="fiber_task" className="form-label">{translate('calendar.fiber_task')} </label>
                                                                        <Select
                                                                            className="custom-react-select"
                                                                            value={fields.fiber_task  ? fields.fiber_task : null}
                                                                            options={fiberTask}
                                                                            onChange={this.handleChange("fiber_task")}
                                                                            closeMenuOnSelect={true}
                                                                            placeholder={<label htmlFor="fiber_task">{translate('calendar.select_fiber_task')}</label>}
                                                                        />
                                                                        <span className="error text-danger">{this.state.errors["fiber_task"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="fiber_other_task" className="form-label">{translate('calendar.fiber_other_task')} </label>
                                                                        <input type="text" className="form-control" name='fiber_other_task' id='fiber_other_task' value={fields["fiber_other_task"] ? fields["fiber_other_task"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "fiber_other_task")} placeholder={translate('calendar.fiber_other_task')} />
                                                                        <span className="error text-danger">{this.state.errors["fiber_other_task"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className='form-group'>
                                                                        <label htmlFor="hub_location" className="form-label">{translate('calendar.hub_location')} </label>
                                                                        <Select
                                                                            className="custom-react-select"
                                                                            value={fields.hub_location  ? fields.hub_location : null}
                                                                            options={headendHubLocation}
                                                                            onChange={this.handleChange("hub_location")}
                                                                            closeMenuOnSelect={true}
                                                                            placeholder={<label htmlFor="hub_location">{translate('calendar.select_hub_location')}</label>}
                                                                        />
                                                                        <span className="error text-danger">{this.state.errors["hub_location"]}</span>
                                                                    </div>
                                                                </div>
                                                                </>
                                                            }
                                                            {/** HDD_DEPARTMENT */}
                                                            {(fields.department && fields.department.value === HDD_DEPARTMENT) &&
                                                                <>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="drill_size" className="form-label">{translate('calendar.drill_size')} </label>
                                                                            <Select
                                                                                className="custom-react-select"
                                                                                value={fields.drill_size  ? fields.drill_size : null}
                                                                                options={hddDrillSize}
                                                                                isMulti
                                                                                onChange={this.handleChange("drill_size")}
                                                                                closeMenuOnSelect={true}
                                                                                placeholder={<label htmlFor="drill_size">{translate('calendar.drill_size')}</label>}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["drill_size"]}</span>
                                                                            <span className="error text-danger">{this.state.errors["drill_size_id"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="hdd_locator_type" className="form-label">{translate('calendar.hdd_locator_type')} </label>
                                                                            <Select
                                                                                className="custom-react-select"
                                                                                value={fields.hdd_locator_type  ? fields.hdd_locator_type : null}
                                                                                options={hddLocatorType}
                                                                                onChange={this.handleChange("hdd_locator_type")}
                                                                                closeMenuOnSelect={true}
                                                                                placeholder={<label htmlFor="hdd_locator_type">{translate('calendar.hdd_locator_type')}</label>}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["hdd_locator_type"]}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="hdd_locator_type_other" className="form-label">{translate('calendar.hdd_locator_type_other')} </label>
                                                                            <input type="text" className="form-control" name='hdd_locator_type_other' id='hdd_locator_type_other' value={fields["hdd_locator_type_other"] ? fields["hdd_locator_type_other"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "hdd_locator_type_other")} placeholder={translate('calendar.hdd_locator_type_other')} />
                                                                            <span className="error text-danger">{this.state.errors["hdd_locator_type_other"]}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="length_of_bore" className="form-label">{translate('calendar.length_of_bore')} </label>
                                                                            <input type="text" className="form-control" name='length_of_bore' id='length_of_bore' value={fields["length_of_bore"] ? fields["length_of_bore"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "length_of_bore")} placeholder={translate('calendar.length_of_bore')} />
                                                                            <span className="error text-danger">{this.state.errors["length_of_bore"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="product_size" className="form-label">{translate('calendar.product_size')} </label>
                                                                            <input type="text" className="form-control" name='product_size' id='product_size' value={fields["product_size"] ? fields["product_size"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "product_size")} placeholder={translate('calendar.product_size')} />
                                                                            <span className="error text-danger">{this.state.errors["product_size"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="dump_site" className="form-label">{translate('calendar.dump_site')} </label>
                                                                            <input type="text" className="form-control" name='dump_site' id='dump_site' value={fields["dump_site"] ? fields["dump_site"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "dump_site")} placeholder={translate('calendar.dump_site')} />
                                                                            <span className="error text-danger">{this.state.errors["dump_site"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="water_source" className="form-label">{translate('calendar.water_source')} </label>
                                                                            <input type="text" className="form-control" name='water_source' id='water_source' value={fields["water_source"] ? fields["water_source"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "water_source")} placeholder={translate('calendar.water_source')} />
                                                                            <span className="error text-danger">{this.state.errors["water_source"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="is_potholing_required" className="form-label">{translate('calendar.is_potholing_required')} </label>
                                                                            <div className='form-group checkbox-row employee-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_potholing_required" id="is_potholing_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_potholing_required")} defaultChecked={fields.is_potholing_required && fields.is_potholing_required.value === String(RADIO_YES) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_potholing_requiredyes">
                                                                                    {translate('calendar.yes')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_potholing_required" id="is_potholing_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_potholing_required")} defaultChecked={fields.is_potholing_required && fields.is_potholing_required.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_potholing_requiredno">
                                                                                    {translate('calendar.no')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <span className="error text-danger">{this.state.errors["is_potholing_required"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    {(fields.is_potholing_required && fields.is_potholing_required.value === String(RADIO_YES)) &&
                                                                        <>
                                                                            <div className="col-lg-6">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="surface_type" className="form-label">{translate('calendar.surface_type')}  <span className="error text-danger">*</span></label>
                                                                                    <div className='form-group'>
                                                                                        <Select
                                                                                            className="custom-react-select"
                                                                                            value={fields.surface_type  ? fields.surface_type : null}
                                                                                            options={surfaceType}
                                                                                            onChange={this.handleChange("surface_type")}
                                                                                            closeMenuOnSelect={true}
                                                                                            isMulti
                                                                                            placeholder={<label htmlFor="surface_type">{translate('calendar.surface_type')}</label>}
                                                                                        />
                                                                                    </div>
                                                                                    <span className="error text-danger">{this.state.errors["surface_type"]}</span>
                                                                                    <span className="error text-danger">{this.state.errors["surface_type_ids"]}</span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="is_tracer_wire_required" className="form-label">{translate('calendar.is_tracer_wire_required')} </label>
                                                                            <div className='form-group checkbox-row employee-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_tracer_wire_required" id="is_tracer_wire_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_tracer_wire_required")} defaultChecked={fields.is_tracer_wire_required && fields.is_tracer_wire_required.value === String(RADIO_YES) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_tracer_wire_requiredyes">
                                                                                    {translate('calendar.yes')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="is_tracer_wire_required" id="is_tracer_wire_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_tracer_wire_required")} defaultChecked={fields.is_tracer_wire_required && fields.is_tracer_wire_required.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="is_tracer_wire_requiredno">
                                                                                    {translate('calendar.no')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <span className="error text-danger">{this.state.errors["is_tracer_wire_required"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/** CTR */}
                                                            {(fields.department && fields.department.value === CTR_DEPARTMENT) &&
                                                                <>
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="traffic_control_requirements" className="form-label">{translate('calendar.traffic_control_requirements')} </label>
                                                                            <textarea type="date" className="form-control" name='traffic_control_requirements' id='traffic_control_requirements' value={fields["traffic_control_requirements"] ? fields["traffic_control_requirements"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "traffic_control_requirements")}></textarea>
                                                                            <span className="error text-danger">{this.state.errors["traffic_control_requirements"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="specialized_tools_ctr" className="form-label">{translate('calendar.specialized_tools')} </label>
                                                                            <Select
                                                                                className="custom-react-select"
                                                                                value={fields.specialized_tools_ctr  ? fields.specialized_tools_ctr : null}
                                                                                options={specializedToolsTtr}
                                                                                onChange={this.handleChange("specialized_tools_ctr")}
                                                                                closeMenuOnSelect={true}
                                                                                placeholder={<label htmlFor="specialized_tools_ctr">{translate('calendar.select_specialized_tools')}</label>}
                                                                            />
                                                                            <span className="error text-danger">{this.state.errors["specialized_tools_ctr"]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="final_inspection_status" className="form-label">{translate('calendar.final_inspection_status')} </label>
                                                                            <div className='form-group checkbox-row employee-checkbox-row'>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="final_inspection_status" id="final_inspection_statusyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "final_inspection_status")} defaultChecked={fields.final_inspection_status && fields.final_inspection_status.value === String(RADIO_YES) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="final_inspection_statusyes">
                                                                                        {translate('calendar.complete')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline custom-checkbox">
                                                                                    <input className="form-check-input" type="radio" name="final_inspection_status" id="final_inspection_statusno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "final_inspection_status")} defaultChecked={fields.final_inspection_status && fields.final_inspection_status.value === String(RADIO_NO) ? true :false} />
                                                                                    <label className="form-check-label" htmlFor="final_inspection_statusno">
                                                                                    {translate('calendar.Incomplete')}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <span className="error text-danger">{this.state.errors["final_inspection_status"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                :null}
                                            </>
                                        :null}
                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 justify-content-end d-flex flex-wrap job-data-savebtn'>
                                            {form_step !== "step_1" ?
                                                <button type="button" className="btn btn-fill me-auto" onClick={() => this.handlePrevFormState(preStep)}>
                                                    {translate('system.previous')}
                                                </button>
                                            :null}
                                            {isDraft &&
                                                <button type="button" className="btn btn-outline me-2" onClick={() => this.handleNextFormState(form_step,"saveAsDraft")}>
                                                    {translate('calendar.save_as_draft')} {submittedAsDraft && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                                </button>
                                            }
                                            {form_step === "step_3" ?
                                                <button type="button" className="btn btn-fill btn-success" onClick={() => this.handleNextFormState(form_step,"saveAsPublish")} >
                                                    {translate('calendar.save')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                                </button>
                                            :
                                                <button type="button" className="btn btn-fill" onClick={() => this.handleChangeFormStep(nextStep)}>
                                                    {translate('system.next')} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {lightBoxShow &&
                <Lightbox
                    mainSrc={lightBoxImgUrl}
                    onCloseRequest={() => this.setState({ lightBoxShow: false })}
                    toolbarButtons={[
                        <button key="download" onClick={this.handleDownload}>
                            <img src={downloadIcon} alt="Download" />
                        </button>
                    ]}
                />
            }

            <div className="modal-backdrop fade show"></div>
        </>
    }
}