import React, { Component } from "react";
import { translate } from './../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../helpers/common';
import { DEPARTMENT_DROPDOWN, ACTIVE  } from '../../config/global_constants';

export default class EquipmentDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('equipmentDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl } = this.props;

		return (
			<>
			<div className="modal fade crew-modal" id="equipmentDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{translate('calendar.details')}</h3><div className="list-group list-group-flush">
								<div className="row">
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.image')}</p>
											<div>
												<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
													{profileDetails && profileDetails.image ? (
														<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
													) : (
														upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
													)}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.equipment_code')}</p>
											<div>{profileDetails.equipment_code ? profileDetails.equipment_code : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.equipment_type')}</p>
											<div>{profileDetails.equipment_type ? profileDetails.equipment_type : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.description')}</p>
											<div>{profileDetails.description ? profileDetails.description : ""}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.equipment_make_model')}</p>
											<div>{profileDetails.equipment_make && profileDetails.equipment_model  ? profileDetails.equipment_make +' / '+ profileDetails.equipment_model : profileDetails.equipment_make ? profileDetails.equipment_make : profileDetails.equipment_model ? profileDetails.equipment_model : ""}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.location')}</p>
											<div>{profileDetails.location_title ? profileDetails.location_title : ""}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.department')}</p>
											<div>
												{(profileDetails.department && profileDetails.department.length) &&
													DEPARTMENT_DROPDOWN.filter(department => profileDetails.department.includes(department.label.toLowerCase()))
													.map(department => department.label)
													.join(',')
												}
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.assignee_name')}</p>
											<div>{profileDetails.assignee_name ? profileDetails.assignee_name : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('employee.status')}</p>
											<div>
												<span className={profileDetails.is_active ? "text-success" : "text-danger"}>
													{profileDetails.is_active ? "Active" : "Deactive"}
												</span>
											</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.is_rental')}</p>
											<div>{profileDetails.is_rental === ACTIVE ? "Yes" : "No"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.company_code')}</p>
											<div>{profileDetails.company_code ? profileDetails.company_code : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.tier_level')}</p>
											<div>{profileDetails.tier_name ? profileDetails.tier_name : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.year')}</p>
											<div>{profileDetails.year ? profileDetails.year : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.serial_number')}</p>
											<div>{profileDetails.serial_number ? profileDetails.serial_number : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.license_number')}</p>
											<div>{profileDetails.license_number ? profileDetails.license_number : "N/A"}</div>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="list-group-item full-view">
											<p>{translate('equipments.gvw_class')}</p>
											<div>{profileDetails.gvw_class ? profileDetails.gvw_class : "N/A"}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
