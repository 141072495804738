import React, { Component } from "react";
import { translate } from './../helpers/intl_helpers';
import MapModal from './../components/MapModal';

let googleMapsScriptLoaded = false;

class GoogleMap extends Component {
	constructor(props) {
        super(props);

        this.state = {
			map: null,
      		markers: [],
			showModal: false,
			jobDetails    : "",
			isJobDetail   : this.props.isJobDetail || false,
        };

		this.mapRef = React.createRef();
    }

	componentDidMount() {
		// Load the Google Maps script dynamically
		this.loadGoogleMapsScript();
	}

	componentWillUnmount() {
		// Clean up markers and InfoWindows
		this.state.markers.forEach(marker => {
			if (marker.infoWindow) {
				marker.infoWindow.close();
			}
			marker.setMap(null);
		});
	}

	loadGoogleMapsScript() {
		if (googleMapsScriptLoaded) {
			this.initMap();
			return;
		}

		// Create the google script element
		googleMapsScriptLoaded = true;
		this.initMap();
		window.initMap = this.initMap;
	}

	initMap = () => {
		// Initialize the map
		let mapInstance = new window.google.maps.Map(this.mapRef.current, {
			center: { lng: 47.08001, lat: -122.60321 },
			zoom: 10,
			mapTypeControl: false, // Hide map type control (satellite view option)
			streetViewControl: false, // Hide Street View control (Pegman)
		});

		this.setState({ map: mapInstance });
	};

	// Function to add a marker with job data
	geocodeLatLng(lat, lng, title, jobData) {
		const google = window.google;

		const location = new google.maps.LatLng(lat, lng); // Use lat/lng directly
		this.addMarker(location, title, jobData);
		this.state.map.setCenter(location);
	}

	// Function to add a marker with infoWindow
	addMarker(location, title, jobData) {
		const google = window.google;
		const marker = new google.maps.Marker({
			position: location,
			map: this.state.map,
			title: title ? String(title) : "",
		});

		// Open InfoWindow on marker click
		marker.addListener('click', () => {
			this.openModal(jobData);
		});

		// Save marker in the state
		this.setState((prevState) => ({
			markers: [...prevState.markers, marker]
		}));
	}

	// Method to initialize markers from props
	markerInitiated() {
		// Clean up existing markers
		if (this.state.markers && this.state.markers.length) {
			this.state.markers.forEach((marker) => {
				if (marker.infoWindow) {
					marker.infoWindow.close();
				}
				marker.setMap(null);
			});
		}

		this.setState({ markers: [] }, () => {
			// Assuming dataList has lat, lng, title, and job data
			if (this.props.dataList && this.props.dataList.length) {
				this.props.dataList.forEach(element => {
					// Pass lat, lng, title, and job data to geocodeLatLng
					this.geocodeLatLng(
						element.latitude,
						element.longitude,
						translate("map.total_jobs") + " - " + element.count,
						element.jobList
					);
				});
			}
		});
	}

	// Open the modal with the selected job data
	openModal = (jobData) => {
		this.setState({ showModal: true, jobDetails: jobData },()=>{
			window.openModal('mapModal');
		});
	};

	// Close the modal
	closeModal = () => {
		this.setState({ showModal: false, jobDetails: null });
	};

	render() {
		const { showModal, jobDetails, isJobDetail } = this.state;

		return (
			<div className="row">
				<div className="col-12">
					<div ref={this.mapRef} id="googleMapSection" className='w-100 h-500'></div>
				</div>
				{showModal && !isJobDetail && jobDetails && jobDetails.length && <MapModal jobDetails={jobDetails} closeModal={this.closeModal} />}
			</div>
		)
	}
}

export default GoogleMap;
