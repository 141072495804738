import React, { Component } from "react";
import { translate,formatDate  } from './../../helpers/intl_helpers';
import { callBackendAPI } from './../../helpers/common';
import { DATERANGE_DATE_TIME_FORMAT}  from './../../config/global_constants';

import NormalDataTable from './../NormalDataTable';

export default class LocateTicketList extends Component {

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type, searchStats }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "project_manager_id")  	apiReq.is_project_manager   =   true;
			if(type === "job_number") apiReq = {model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue :''};

			if(type === "work_order_number"){
				let jobId = searchStats && searchStats.job_number && searchStats.job_number.value ? searchStats.job_number.value :'';

				if(!jobId) return {options: [], hasMore: true, additional: {page: page, type: type }};

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobId, work_order_number: inputValue ? inputValue : '' };
			}

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/** Manage table custom render */
	customRender = (actionKey, rowData) => {
		if(!actionKey || !rowData || !rowData._id)  return "";

		let element = "";
		if(actionKey === "locate_expiration_date"){
			element	= rowData.locate_expiration_date ?	formatDate(rowData.locate_expiration_date , DATERANGE_DATE_TIME_FORMAT)  :"";
		}
		return element;
	};// end customRender()


	render() {
		let pageStats = {
			tableId :	'locateTicketList',
			title 	:	translate("locate_tickets.heading_title"),
			columns :	[
				{name: translate("locate_tickets.job_number"), selector: "job_number", sortable: true, width: "13%"},
				{name: translate("locate_tickets.work_order_number"), selector: "work_order_number", sortable: true, width: "10%"},
				{name: translate("locate_tickets.project_manager"), selector: "project_manager", sortable: true, width: "14%"},
                {name: translate("locate_tickets.ticket_number"), selector: "ticket_number", sortable: true, width: "30%"},
                {name: translate("locate_tickets.expire_date"), selector: "locate_expiration_date", sortable: true, width: "30%", is_date: true , customRender: this.customRender},
			],
			search :	[
				{name: translate("locate_tickets.job_number"), selector: "job_number", input_type: "asyncPaginate", child_selector:"work_order_number" },
				{name: translate("locate_tickets.work_order_number"), selector: "work_order_number", input_type: "asyncPaginate", parent_selector:"job_number" },
				{name: translate("reports.project_manager"), selector: "project_manager_id", input_type: "asyncPaginate"},
				{name: translate("locate_tickets.ticket_number"), selector: "ticket_number", input_type: "text", showLabel: true, inputClass:"input-with-label"},
				{name: translate("locate_tickets.expire_date"), selector: "locate_expiration_date", input_type: "date_range" , inputClass:"input-with-label"},
			]
		}
		return <>
			<NormalDataTable pageStats={pageStats} apiParm={{model: 'jobs', method: 'locateTicketsList'}} getDropDownList={this.getDropDownList} />
		</>
	}
}

