import React, { Component} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ADMIN, PROJECT_MANAGER, DISPATCHER} from './../config/global_constants';
import Auth from './../helpers/Auth';

import BlankLayout 		from "../layout/BlankLayout";
import Layout 			from "../layout/Layout";
import AfterLoginLayout from "./../layout/AfterLoginLayout";

/** Before Login page */
import Login 			from "./../components/Login";
import SSOLogin 		from "./../components/SSOLogin";
import ForgotPassword	from "./../components/ForgotPassword";
import ResetPassword 	from "./../components/ResetPassword";
import Page404			from "./../components/Page404";

/** After Login page */
import Dashboard	from "./../components/Dashboard";
import EditProfile	from "./../components/EditProfile";

import EmployeeList				from "./../components/Employee/EmployeeList";
import AddEmployee				from "./../components/Employee/AddEmployee";

import calendar 				from "./../components/calendar/cal/DragAndDrop";
import SubContractor			from "../components/SubContractor/List";

import CustomerList				from "./../components/Customer/List";
import CustomerJob				from "./../components/Customer/CustomerJob";

import EquipmentList			from "./../components/Equipments/List";
import AddEditEquipment			from "./../components/Equipments/AddEditEquipment";
import EquipmentCost			from "./../components/Equipments/EquipmentCost";


import MasterList				from "../components/Master/List";
import WorkOrderList			from "../components/work_order/WorkOrderList";

import DepartmentCost			from "../components/DepartmentCost";
import LocateTicketList			from "../components/LocateTickets/LocateTicketList";
import JobsMap					from "./../components/JobMap";

/** Reports Modules */
import ReportJobList			from "../components/Reports/JobList";
import ReportPTOList			from "../components/Reports/PTOList";
import JobLookup				from "../components/Reports/JobLookup";
import SubcontractorReport		from "../components/Reports/Subcontractor";
import EquipmentUtilization		from "../components/Reports/EquipmentUtilization";
import ResourcesAvailability	from "../components/Reports/ResourcesAvailability";
import EquipmentsAvailability	from "../components/Reports/EquipmentsAvailability";

class Routes extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path={["/","/calendar/:month","/calendar"]}>
						<BlankLayout>
							<PrivateRoute exact path="/" component={calendar}/>
							<PrivateRoute path="/calendar/:month" component={calendar}/>
							<PrivateRoute path="/calendar" component={calendar}/>
						</BlankLayout>
					</Route>
					<Route exact path={["/forgot-password","/reset-password/:validate_string","/admin-login"]}>
						<Layout>
							<PublicRoute path="/admin-login" component={Login}/>
							<PublicRoute path="/forgot-password" component={ForgotPassword}/>
							<PublicRoute path="/reset-password/:validate_string" component={ResetPassword}/>
						</Layout>
					</Route>
					<Route exact path={["/login"]}>
						<BlankLayout>
							<PublicRoute path="/login" component={SSOLogin}/>
						</BlankLayout>
					</Route>

					{/** AFter login routes  */}
					<Route exact path={[ "/dashboard", "/edit-profile", "/employee", "/employee/add", "/employee/update/:employee_id", "/sub-contractor" ,"/equipments", "/master/:dropdown_type", "/work-order","/department-cost","/locate-tickets", "/reports/job-list","/map","/auto-complete","/reports/timeoff-report","/reports/resources-availability","/customer","/customer/:customer_code","/reports/jobs-lookup","/reports/equipment-utilization", "/equipments-upload","/reports/subcontractor","/equipments/add","/equipments/update/:equipment_id","/job-map","/equipment-cost","/reports/available-equipments"]}>
						<AfterLoginLayout>
							{/* <PrivateRoute exact path="/" component={Dashboard}/> */}
							<PmAdminDispatcherRoute exact path="/dashboard" component={Dashboard}/>
							<PrivateRoute path="/edit-profile" component={EditProfile}/>

							<PmAdminDispatcherRoute exact path="/employee" component={EmployeeList}/>
							<PmAdminDispatcherRoute path="/employee/add" component={AddEmployee}/>
							<AdminDispatcherRoute path="/employee/update/:employee_id" component={AddEmployee}/>

							<PmAdminDispatcherRoute exact path="/customer" component={CustomerList}/>
							<PmAdminDispatcherRoute path="/customer/:customer_code" component={CustomerJob}/>

							<PmAdminDispatcherRoute path="/sub-contractor" component={SubContractor}/>
							<PrivateRoute path="/department-cost" component={DepartmentCost}/>
							<PrivateRoute path="/equipment-cost" component={EquipmentCost}/>

							<AdminDispatcherRoute exact path="/equipments" component={EquipmentList}/>
							<AdminDispatcherRoute path="/equipments/add" component={AddEditEquipment}/>
							<AdminDispatcherRoute path="/equipments/update/:equipment_id" component={AddEditEquipment}/>

							<PrivateRoute path="/locate-tickets" component={LocateTicketList}/>
							<PmAdminDispatcherRoute path="/master/:dropdown_type" component={MasterList}/>
							<AdminRoute path="/work-order" component={WorkOrderList}/>

							<PmAdminDispatcherRoute path="/reports/job-list" component={ReportJobList}/>
							<PmAdminDispatcherRoute path="/reports/timeoff-report" component={ReportPTOList}/>
							<PmAdminDispatcherRoute path="/reports/resources-availability" component={ResourcesAvailability}/>
							<PmAdminDispatcherRoute path="/reports/available-equipments" component={EquipmentsAvailability}/>
							<PmAdminDispatcherRoute path="/reports/jobs-lookup" component={JobLookup}/>
							<PmAdminDispatcherRoute path="/reports/equipment-utilization" component={EquipmentUtilization}/>
							<PmAdminDispatcherRoute path="/reports/subcontractor" component={SubcontractorReport}/>

							<PrivateRoute path="/job-map" component={JobsMap}/>
						</AfterLoginLayout>
					</Route>
					<BlankLayout>
						<CommonRoute path="*" component={Page404} />
					</BlankLayout>
				</Switch>
			</Router>
		);
	}
}

const PrivateRoute = ({ component: Component, ...rest }) => ( <Route{...rest} render={ props =>Auth.getAuth() ? (<Component {...props} />) : ( <Redirect to='/login'  />)} />);
const PublicRoute = ({ component : Component, ...rest }) => ( <Route{...rest} render={ props =>Auth.getAuth() ? (<Redirect to='/calendar'  />) : ( <Component {...props} />)} />);
const CommonRoute = ({ component : Component, ...rest }) => ( <Route{...rest} render={ props =>Auth.getAuth() ? (<Component {...props} /> ) : ( <Component {...props} />)} />);

const PmAdminDispatcherRoute = ({ component : Component, ...rest }) => ( <Route{...rest} render={ props =>([ ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(Auth.getPermission()) !== -1) ? (<Component {...props} /> ) : (<Redirect to='/calendar'  />)} />);
const AdminDispatcherRoute = ({ component : Component, ...rest }) => ( <Route{...rest} render={ props =>([ ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) ? (<Component {...props} /> ) : (<Redirect to='/calendar'  />)} />);
const AdminRoute = ({ component : Component, ...rest }) => ( <Route{...rest} render={ props =>([ ADMIN ].indexOf(Auth.getPermission()) !== -1) ? (<Component {...props} /> ) : (<Redirect to='/calendar'  />)} />);
export default Routes;
