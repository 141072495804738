import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import InfiniteScroll from "react-infinite-scroll-component";
import Select from 'react-select';
import { NavLink as Link} 	from "react-router-dom";

import { STATUS_SUCCESS, DEPARTMENT_DROPDOWN, JOB_TITLE_TYPE_DROPDOWN, CALENDAR_DATE_TIME_FORMAT } from '../../../config/global_constants'
import { callBackendAPI, handleFormInputs } from '../../../helpers/common';
import { translate, formatDate } from '../../../helpers/intl_helpers'

import searchImg from '../../../assets/images/search_icon.svg';
import filterImg from '../../../assets/images/filter-icon.svg';
import reset    from '../../../assets/images/reset.svg';
import closeImg from '../../../assets/images/close_icon.svg';

class MemberList extends Component{
    constructor(props){
        super(props);
        this.state = {
            crewListArray       :   [],
            totalRecords        : 	0,
            isLoading	        : 	true,
            searchInputStats    :   {},
            locationList        :   [],
            userDetail          :   this.props.userDetail
        };
    }

    static propTypes = {
        schedulerData   : PropTypes.object.isRequired,
        newEvent        : PropTypes.func.isRequired,
        memberDndSource : PropTypes.object.isRequired,
    }

    componentDidMount() {
        const {userDetail} = this.state;

        /** Set API request params */
        let apiReq = [{ model: 'home', method: 'masterList', type: ["location"] }]
		callBackendAPI(apiReq).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let locations               = response.data[0].result.location;
                let selectedLocArr          = [];
                let selectedDepArr          = [];
                let empLocationId           = (userDetail && userDetail.location)       ? userDetail.location     : [];
                let empLocationDepartment   = (userDetail && userDetail.department)     ? userDetail.department     : [];

                locations.forEach(val=>{
                    if(empLocationId.includes(val.value)){
                        selectedLocArr.push(val);
                    }
                });

                DEPARTMENT_DROPDOWN.forEach(option => {
                    let isSelected = empLocationDepartment.includes(option.value);
                    if (isSelected) {
                        selectedDepArr.push(option);
                    }
                });
                this.setState({
                    locationList : locations,
                    searchInputStats: {
                        ...this.state.searchInputStats,
                        location: selectedLocArr,
                        department: selectedDepArr,
                        job_title_type: {value: "field",label: "Field"}
                    }
                },()=>{
                    this.getCrewList();
                });
			}
		}).catch(err => console.log(err));
    }

    // Function to handle button click events for move shop item to  asset
    handleCrewListByCalendarDate = (isRefresh) => {
        const {schedulerData } = this.props;
        if(isRefresh) {
            this.getCrewList(true);
        }else{
            this.getCrewList(true,schedulerData.selectDate,schedulerData.selectDate);
        }
    };// end moveChildItem()

    /**
	* For get crew member listing
	*
	*/
	getCrewList = (freshListing,startDate,endDate)=> {
        const {updateCrewMemberCalendar } = this.props;
        const {crewListArray, searchInputStats } = this.state;
        this.setState({
            isLoading: true
        },()=>{

            let crewList   =   !freshListing && crewListArray ? crewListArray :[];
            let skip       =   !freshListing ? crewList.length :0;
            let sDate      =    startDate ? formatDate(startDate, CALENDAR_DATE_TIME_FORMAT) :null;
            let eDate      =    endDate ? formatDate(endDate, CALENDAR_DATE_TIME_FORMAT) :null;

            let apiParams = {model: 'calendar', method: 'employeeList', is_crew_member: true, skip: skip, start_date: sDate,end_date: eDate };

            if(searchInputStats){
                let locArr    = [];
                let deptArr    = [];
                if(searchInputStats.location && searchInputStats.location.length) locArr = searchInputStats.location.map(element => element.value);
                if(searchInputStats.department && searchInputStats.department.length) deptArr = searchInputStats.department.map(element => element.value);

                if(searchInputStats.location && searchInputStats.location.length)  apiParams.location       =   locArr;
                if(searchInputStats.department && searchInputStats.department.length)  apiParams.department  =   deptArr;
                if(searchInputStats.job_title_type && searchInputStats.job_title_type.value)  apiParams.job_title_type  =   searchInputStats.job_title_type.value;
                if(searchInputStats.emp_name && searchInputStats.emp_name.value)      apiParams.emp_name    =   searchInputStats.emp_name.value;
                if(searchInputStats.emp_code && searchInputStats.emp_code.value)      apiParams.emp_code    =   searchInputStats.emp_code.value;
                if(searchInputStats.emp_number && searchInputStats.emp_number.value)  apiParams.emp_number  =   searchInputStats.emp_number.value;
            }

            callBackendAPI([apiParams]).then(response => {
                if (response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                    let updatedCrewList =  crewList.concat(response.data[0].result);
                    updateCrewMemberCalendar(updatedCrewList);
                    this.setState({
                        crewListArray 	:	updatedCrewList,
                        totalRecords    : 	response.data[0].recordsTotal,
                        isLoading	    : 	false
                    });
                }
            }).catch(err => console.log(err));
        });
    }// end getCrewList()

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
        const {schedulerData } = this.props;
		let response	=	handleFormInputs(e, field, this.state.searchInputStats);
		this.setState({ response },()=>{
            if(field === "emp_name") this.getCrewList(true, schedulerData.selectDate,schedulerData.selectDate);
        });
	}// end handleFormInputs()

    /**
	* For update state fields values when change select box
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
    handleChange = (fieldName) => (fieldVal) => {
        let newObj = {[fieldName]: fieldVal};
        let updatedFields = {...this.state.searchInputStats, ...newObj};
        if(fieldName === "location"){
            updatedFields['department'] = null;
        }
        this.setState({
            searchInputStats: updatedFields
        });
    }// end handleChange()

    /**
	* For Clear input values
	*
	* @return null
	*/
    resetSearchInputStats = () => {
        if(this.state.isLoading) return false;

        const {schedulerData } = this.props;
        this.setState({searchInputStats : {}},()=>{
            this.getCrewList(true, schedulerData.selectDate,schedulerData.selectDate);
        });
    }

    render(){
        const {schedulerData, newEvent, memberDndSource, setAssetItem} = this.props;
        const { totalRecords, searchInputStats, crewListArray, isLoading, locationList  } = this.state;
        let DnDTaskItem = memberDndSource.getDragSource();
        let tasks = schedulerData.memberGroups;

        let memberList = tasks.map((item) => {
            return <DnDTaskItem key={item.id} image={item.state.image} task={item} newEvent={newEvent} schedulerData={schedulerData} setAssetItem={setAssetItem} />
        });

        return (
            <div className='sourcebox crew-members-sourcebox'>
                <div className='sourcebox-filter-wrap'>
                    <h4>{translate("calendar.crew_members")}  </h4>
                    <div className='sourcebox-filter'>
                        <div className="dropdown">
                            <Link to='#emp-filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="emp-filter-collapse">
                                <img src={filterImg} alt='Filter' width={18} height={20}/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="search-filter-section">
                    <input type='text' id='emp_name' name='emp_name' className='form-control' placeholder={translate("calendar.search_by_employee_name")} onChange={(event) => this.handleFormInputs(event, "emp_name")} value={searchInputStats.emp_name && searchInputStats.emp_name.value ? searchInputStats.emp_name.value :""} />
                    <button className="search-icon">
                    <img src={searchImg} alt='Search' width={20} height={20}/>
                    </button>
                </div>
                <div className="collapse filter-collapse" id="emp-filter-collapse">
                    <div className="card card-body">
                        <div className='dropdown-filter-header d-flex flex-wrap justify-content-between align-items-center mb-3'>
                            <strong>{translate("system.search")}</strong>
                            <div className='d-flex align-items-center'>
                                <Link to='#' className='reset-icon' onClick={()=> {this.resetSearchInputStats()}}>
                                    <img src={reset} alt='Reset' width={18} height={18}/>
                                </Link>
                                <Link className="ms-2" to='#emp-filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="emp-filter-collapse">
                                    <img src={closeImg} alt='Close' width={20} height={20}/>
                                </Link>
                            </div>
                        </div>
                        <div className='filter--form'>
                            <div className='form-group mb-2'>
                                <Select
                                    className="custom-react-select"
                                    placeholder={<label htmlFor="location">{translate("employee.location")}</label>}
                                    options={locationList}
                                    value={searchInputStats.location ? searchInputStats.location : null}
                                    closeMenuOnSelect={true}
                                    onChange={this.handleChange("location")}
                                    isMulti
                                    isClearable={true}
                                />
                            </div>
                            <div className='form-group mb-2'>
                                <Select
                                    className="custom-react-select"
                                    options={(searchInputStats.location && Array.isArray(searchInputStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
                                        searchInputStats.location.some(loc => loc.department && loc.department.includes(dept.value))
                                    ) : []}
                                    closeMenuOnSelect={true}
                                    isMulti
                                    placeholder={translate("calendar.all_department")}
                                    value={searchInputStats.department ? searchInputStats.department :null}
                                    onChange={this.handleChange("department")}
                                    isClearable={true}
                                />
                            </div>
                            <div className='form-group mb-2'>
                                <Select
                                    className="custom-react-select"
                                    placeholder={<label htmlFor="job_title_type">{translate("employee.job_title_type")}</label>}
                                    options={[...[{value: null, label: translate("calendar.all_job_type")}], ...JOB_TITLE_TYPE_DROPDOWN]}
                                    value={searchInputStats.job_title_type ? searchInputStats.job_title_type : {value: null, label: translate("calendar.all_job_type")}}
                                    closeMenuOnSelect={true}
                                    onChange={this.handleChange("job_title_type")}
                                />
                            </div>
                            <div className='button--filter'>
                                <button type='button' className='btn btn-fill w-100' onClick={()=>{this.getCrewList(true, schedulerData.selectDate,schedulerData.selectDate, true) }}>
                                    {translate("system.search")} {isLoading && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sourcebox-listing calendarScrollableDiv' id="crewsScrollableDiv">
                    <InfiniteScroll
                        key={crewListArray.length}
                        dataLength={crewListArray.length}
                        next={() => { this.getCrewList(false,schedulerData.selectDate,schedulerData.selectDate) }}
                        hasMore={(totalRecords && crewListArray.length && totalRecords > crewListArray.length) ? true :false}
                        scrollableTarget="crewsScrollableDiv"
                    >
                        {memberList.length ?
                            <ul>
                                {memberList}
                            </ul>
                        :
                            <div className="text-center">{translate("calendar.no_data_available")}</div>
                        }
                    </InfiniteScroll>
                </div>
            </div>
        )
    }
}
export default MemberList