import React, { Component } from "react";

import { callBackendAPIAndImageUpload, reFormatSuccess, handleFormInputs } from './../../helpers/common';
import { STATUS_SUCCESS } from './../../config/global_constants';
import { translate } from './../../helpers/intl_helpers';
import downloadIcon from '../../assets/images/download.png';

export default class BulkEquipment extends Component {

	constructor(props) {
		super(props)

		this.state = {
			errors		: {},
			fields		: {},
			submitted	: false,
			bulkErrors	: null,
		};

		this.closeModal			= this.closeModal.bind(this);
		this.handleFormInputs	= this.handleFormInputs.bind(this);
		this.handleUpdateEquipmentFormSubmit = this.handleUpdateEquipmentFormSubmit.bind(this);
	}

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial body class when that component is open.
	 */
	componentDidMount() {
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");
	}

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleUpdateEquipmentFormSubmit(e) {
		e.preventDefault();

		const { image } = this.state.fields;

		this.setState({
			submitted: true,
		}, async () => {
			if (image === "" || image === undefined) return this.setState({ errors: { "image": translate("equipments.please_select_file") }, submitted: false });


			/** Set form data */
			let formData = new FormData();
			formData.append("equipment_file", this.state.fields.image.value);

			/** Submit form to server */
			let responseData = await callBackendAPIAndImageUpload({ model: 'equipment', method: 'importEquipments', fileData: formData, formData: {} }, true);

			if(responseData.data.status === STATUS_SUCCESS){
				/** Show success flash message **/
				reFormatSuccess(responseData.data.message[0].msg);

				/** Remove disable attribute from submit button */
				this.setState({ errors: {}, fields: {}, submitted: false });

				/** Close the modal */
				this.closeModal()
			}else{
				let equipmentErrorsObj 	=	responseData.data.message;
				let equipmentErrors		=	"";

				if(Array.isArray(equipmentErrorsObj)){
					equipmentErrors = responseData.data.message[0].msg;
				}else{
					Object.keys(equipmentErrorsObj).map(row=>{
						equipmentErrors += `<br/> Row ${row + " <br/>" + equipmentErrorsObj[row]} <br/>`;
						return null;
					})
				}

				/** Remove disable attribute from submit button */
				this.setState({ submitted: false, bulkErrors: equipmentErrors });
			}
		});
	}// end handleUpdateEquipmentFormSubmit()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field) {
		let response = handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal() {
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");
		if (this.props.hideModal) this.props.hideModal();
	}// end closeModal()


	render() {
		const { submitted, fields, errors, bulkErrors } = this.state;

		return <>
			<div className={"modal fade show d-block view-modal equipment-edit"} tabIndex={-1} aria-labelledby="adduserModal" aria-hidden="true">
				<div className="modal-dialog  modal-lg">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={() => { this.closeModal() }}>X {translate("system.close")}</button>
						<div className="modal-body">
							<h3 className="modal-title">{translate("equipments.bulk_equipment")}</h3>
							<div className="adduser-form">
								<form className="adduser-form" onSubmit={(event) => this.handleUpdateEquipmentFormSubmit(event, 'updateEquipment')} noValidate>
									{bulkErrors ?
										<div className="row m-1 mb-2">
											<div className="col-12 bg-danger color-white pb-2" dangerouslySetInnerHTML={{__html: bulkErrors ? bulkErrors :""}} ></div>
										</div>
									:null}
									<div className="row mb-4">
										<div className='col-11'>
											<div className="form-group custom-upload">
												<label className="upload-source">
													<input type="file" name='file' id="file" className="d-none" onChange={(event) => this.handleFormInputs(event, "image")} />
													<span>{translate("system.upload")}</span>
													<p>
														{fields.image && fields.image.file_name
															?
															fields.image.file_name
															:
															<>
																{translate("system.select_file")}
																<i className="error text-danger">*</i>
															</>
														}
													</p>

												</label>
											</div>
											<span className="error">{errors["equipment_file"]}</span>
											<span className="error">{errors["image"]}</span>
										</div>
										<div className="col-1 p-0">
											<a href="/sampleFiles/equipment.csv" className="w-100 h-100" title={translate("system.download_sample_file")} download>
												<img src={downloadIcon} alt="img"/>
											</a>
										</div>
									</div>
									<button type={submitted ? "button" : "submit"} className="btn btn-fill" >
										{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3" />}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show"></div>
		</>
	}
}
