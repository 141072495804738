import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';

import EllipsisPagination from './../Pagination';
import { callBackendAPI, handleFormInputs, customFixedValue, setOrGetCookiesValue} from './../../helpers/common';

import { translate, formatDate, getCurrentWeekDates } from './../../helpers/intl_helpers';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, EXPORT_CSV, EXPORT_PDF, CALENDAR_DATE_TIME_FORMAT, CURRENCY_SYMBOL } from './../../config/global_constants';

import closeIcon 	from './../../assets/images/close_icon.svg';
import dataTable 	from './../../assets/images/data-table.svg';
import dataAsc 		from './../../assets/images/data-asc.svg';
import dataDesc 	from './../../assets/images/data-desc.svg';
import filterImg 	from './../../assets/images/filter.svg';
import loaderGif 	from './../../assets/images/pagination_loader.gif';

class EquipmentUtilization extends Component {

	constructor(props) {
        super(props);

		let startDate	=	getCurrentWeekDates().monday;
		let endDate		=	getCurrentWeekDates().friday;

		/** Get search stats from cookies  */
		let tmpSearchStats = {date_range :[startDate, endDate]};
		let tmpVal =  setOrGetCookiesValue('searchStatsEquipmentUtilization');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length){
			/** Convert into date format */
			if(tmpVal.date_range && tmpVal.date_range.length){
				let tmpStartDate	=	new Date(tmpVal.date_range[0]);
				let tmpEndDate		=	new Date(tmpVal.date_range[1]);
				tmpVal.date_range 	=	[tmpStartDate, tmpEndDate];
			}

			tmpSearchStats = tmpVal;
		}

        this.state = {
			rowsPerPage		: 	PAGING_LENGTH_MENU[0],
			defaultDateRange: 	[startDate, endDate],
			searchStats 	: 	tmpSearchStats,
            dataList			: 	[],
			totalRecords	: 	0,
			totalPages		:	0,
			isLoading		:	true,
			currentPage		:	1,
			locationList	:	[],
			sortStats		:	{},
        };

		this.getEquipmentList    	=	this.getEquipmentList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.manageSortingEvent		=	this.manageSortingEvent.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getMasterList();
		this.getEquipmentList();
    }

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue : ''};

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getEquipmentList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getEquipmentList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getEquipmentList();
		});
	};

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getEquipmentList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	* For update state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};
		let updatedFields = {...this.state.searchStats, ...newObj};

		if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;
		this.setState({searchStats: updatedFields});
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For get equipment utilization listing
	*/
	getEquipmentList = () => {
		const { rowsPerPage, currentPage, searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'reports', method: 'equipmentUtilizationList', length: rowsPerPage, start: skip};
			if(sortStats && sortStats.key) apiReq = {...apiReq, ...{sort_by: sortStats.key, sort_direction: sortStats.dir} };
			if(searchStats && Object.keys(searchStats).length){
				apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
				apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
				apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
				apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

				let locArr    = [];
                let deptArr   = [];
				if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
                if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
				if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
                if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsEquipmentUtilization', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						dataList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getEquipmentList()

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'reports', method: 'exportEquipmentUtilization', file_type: fileType};
		if(searchStats && Object.keys(searchStats).length){

			apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
			apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
			apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
			apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";
			let locArr    = [];
			let deptArr   = [];
			if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
			if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
			if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const link 		=	document.createElement('a');
					link.href 		=	response.data[0].file_url;
					link.rel 		=	'noopener noreferrer'; // Security for '_blank' links
					document.body.appendChild(link); // Required for Firefox
					link.click();
					document.body.removeChild(link);
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	render() {
		const {dataList, searchStats, currentPage, rowsPerPage, totalPages, isLoading,locationList, sortStats} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.equipment_utilization")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="date_range">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															className='form-control input-with-label'
															editable={false}
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.handleChange("date_range",[]) }}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_equipment">{translate('reports.equipment')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_equipment"
														value={searchStats.asset_id  ? searchStats.asset_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("asset_id")}
														additional={{ page: 1, type: "asset"}}
														isClearable={true}
														placeholder={translate('reports.search_by_equipment')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_state" className="form-label mb-1">{translate('reports.state')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('reports.search_by_state')} id='search_by_state' value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"state")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_city" className="form-label mb-1">{translate('reports.city')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_city')} id='search_by_city' value={searchStats["city"] ? searchStats["city"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"city")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_zip" className="form-label mb-1">{translate('reports.zip')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_zip')} id='search_by_zip' value={searchStats["zip"] ? searchStats["zip"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "zip")} />
												</div>
											</div>
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group d-flex align-items-center mb-3 table-count">
								{translate("system.show")}
								<Select
									className="custom-react-select mx-1"
									options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
									value={{label:rowsPerPage, value:rowsPerPage}}
									closeMenuOnSelect={true}
									onChange={this.handlePageLengthChange}
								/>
								{translate("system.entries")}
							</div>
						</div>
						<div className="col-lg-6 text-end">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									<th width='15%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "equipment_code", (sortStats && sortStats.key === "equipment_code" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.equipment_code')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "equipment_code") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "equipment_code" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='15%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "equipment_desc", (sortStats && sortStats.key === "equipment_desc" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.equipment_desc')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "equipment_desc") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "equipment_desc" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='15%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "equipment_type", (sortStats && sortStats.key === "equipment_type" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.equipment_type')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "equipment_type") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "equipment_type" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='15%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "department", (sortStats && sortStats.key === "department" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.department')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "department") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "department" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "hourly_rate", (sortStats && sortStats.key === "hourly_rate" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.cost_per_hour')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "hourly_rate") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "hourly_rate" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "total_hours", (sortStats && sortStats.key === "total_hours" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.total_hours')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "total_hours") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "total_hours" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
									<th width='10%' className="cursor-pointer" onClick={(event)=>{this.manageSortingEvent(event, "total_cost", (sortStats && sortStats.key === "total_cost" && sortStats.dir === "asc" ? "desc" :"asc")) }}>
										{translate('job_report.total_cost')}

										<span className="m-l-10">
											{(!sortStats || !sortStats.key) || (sortStats && sortStats.key !== "total_cost") ?
												<img src={dataTable} alt="sorting-opt" />
											:(sortStats && sortStats.key === "total_cost" ? (
												sortStats.dir === 'asc' ?
													<img src={dataAsc} alt="sorting-asc"  />
												:
												<img src={dataDesc} alt="sorting-desc" />
											):null)}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan="6" className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								:
								dataList && dataList && dataList.length  ?
									dataList.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											<td>{row.equipment_code ? row.equipment_code :"-"}</td>
											<td>{row.equipment_desc ? row.equipment_desc :"-"}</td>
											<td>{row.equipment_type ? row.equipment_type :"-"}</td>
											<td>{row.department ? row.department :""}</td>
											<td>{row.hourly_rate ? CURRENCY_SYMBOL+" "+customFixedValue(row.hourly_rate) :0}</td>
											<td>{row.total_hours ? row.total_hours :0}</td>
											<td>{row.total_cost ? CURRENCY_SYMBOL+" "+customFixedValue(row.total_cost) :0}</td>
										</tr>
									)
								:
									<tr>
										<td colSpan="6" className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>
					{dataList.length && totalPages >1 ?
						<div className='d-flex justify-content-center align-items-center'>
							<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
						</div>
					:null}
				</div>
			</div>
		</>
	}
}
export default EquipmentUtilization;
