import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Auth = {
	isAuthenticated: false,
	authenticate() {
		this.isAuthenticated = true;
	},
	signout() {
		this.isAuthenticated = false;
	},
	getAuth() {
		return cookies.get('user_details') ?  true : false;
	},
	getPermission() {
		const userDetails = cookies.get('user_details');
		return userDetails && userDetails.permission_level ?  userDetails.permission_level : "";
	}
};
export default Auth;