import { API_HEADER_AUTH_KEY,DEFAULT_LANGUAGE_ID }  from './global_constants';
import base64 	from 'base-64';
import Cookies 	from 'universal-cookie';
import axios 	from 'axios';
import utf8 	from 'utf8';

/* Creating axios instance */
var axiosInstance = axios.create({
	headers: {"authkey": API_HEADER_AUTH_KEY},
});

/* Request interceptor */
axiosInstance.interceptors.request.use((request)=>{
	const cookies 	=	new Cookies();
	let userDetails =	cookies.get('user_details');
	if(userDetails && userDetails._id) request.data['user_id'] = userDetails._id;
	if(userDetails && userDetails.token) request.headers['authorization'] = userDetails.token;

	/* To identify request is sending from web */
	if(!request.data.api_type) 		request.data.api_type 		= 	"web";
	if(!request.data.language_id) 	request.data.language_id 	=	cookies.get('preferred_language') || DEFAULT_LANGUAGE_ID;

	const jsonString 	= 	utf8.encode(JSON.stringify(request.data));
	const base64String 	=	base64.encode(jsonString);
	request.data 		=	{data: base64String};
	return request;
},(error)=>{
	/* Do something with request error */
	return Promise.reject(error);
});

/* Response interceptor */
axiosInstance.interceptors.response.use(response=> {
	const binaryBuffer =  base64.decode(response.data);

	const decodedBytes = new Uint8Array(binaryBuffer.length);
    for(let i = 0; i < binaryBuffer.length; i++) {
      	decodedBytes[i] = binaryBuffer.charCodeAt(i);
    }

    // Decode the Uint8Array to a JSON string
    const decodedJsonString = new TextDecoder().decode(decodedBytes);

	const decodedData 	=	JSON.parse(decodedJsonString);
	response.data		= 	decodedData;
	return response;
},(error)=>{
	/* Do something with response error */
	return Promise.reject(error);
});

export default axiosInstance;