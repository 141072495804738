import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";

import { translate } from './../helpers/intl_helpers';
import img404 	 from '../assets/images/404.png';

class Page404 extends Component {
	render() {
		return (
			<div className="login-page 404-page">
				<div className="container">
					<div className="row text-center">
						<div className="logo">
							<figure>
								<img src={img404} alt="404-img"/>
							</figure>
						</div>
						<Link to="/">
							<button type="button" className="btn btn-fill mt-3">{translate("system.go_to_home_page","Go To Home Page")}</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
export default Page404;
