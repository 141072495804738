import React, { Component } from "react";
import { translate  } from './../helpers/intl_helpers';

export default class MapModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('mapModal');
	}// end closeModal()

	render() {
		const { jobDetails } = this.props;

		return (
			<div className="modal fade mapModal" id="mapModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h3>{translate('calendar.job_list')}</h3>
							<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						</div>
						<div className="modal-body">
							<div className="list-group list-group-flush">
								{Array.isArray(jobDetails) && jobDetails.length > 0 ? (
									jobDetails.map((val, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-lg-12">
													<div className="list-group-item full-view">
														<p>{val.job_number && val.work_order_number ? `${val.job_number}-${val.work_order_number}` : val.job_number}</p>
														<div className="mapModal-job-list">
															<span><span className="left-heading">{translate('calendar.foreman')} :</span> {val.foreman_name ? val.foreman_name : "N/A"}</span>
															<span><span className="left-heading">{translate('calendar.project_manager')} :</span> {val.project_manager ? val.project_manager : "N/A"}</span>
															<span><span className="left-heading">{translate('calendar.jobsite_address')} :</span>
																{val.jobsite_building && val.jobsite_address
																	? `${val.jobsite_building}, ${val.jobsite_address}`
																	: val.jobsite_address || ''
																}
																{val.jobsite_address_zip && (
																	`, ${val.jobsite_address_zip}`
																)}
															</span>
														</div>
													</div>
												</div>
											</div>
										);
									})
								) : (
									<p>{translate('calendar.no_job_details')}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
