import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";

import { logout , upperCaseOfName , callBackendAPI } from './../helpers/common';
import { translate } from './../helpers/intl_helpers';
import { UserDetails }  from '../UserDetails';
import { STATUS_SUCCESS} from './../config/global_constants';
import hamburgerMenuIcon from '../assets/images/hamburgermenu.svg';

class Header extends Component {
	static contextType = UserDetails;
	constructor(props) {
		super(props);
		this.state = {
			userDetail	: {},
			imageUrl	: "",
			noImageUrl 	: "",
		};

		this.Logout =  this.Logout.bind(this);
		window.scrollTo(0, 0);
	}

	/**
	* For get user details
	*/
	componentDidMount() {
		callBackendAPI([{ model: 'my_account', method: 'getUserDetails'}]).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					userDetail	: response.data[0].result,
					imageUrl 	: response.data[0].file_url,
					noImageUrl 	: response.data[0].no_img_url
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For user logout
	*
	* @param null
	*
	* @return void
	*/
	Logout(){
		logout()
	}// end Logout()

	render() {
		const {userDetail,imageUrl, noImageUrl} = this.state;

		return (
			<div className="top-header">
				<button type="button" className="menu-btn" aria-labelledby="menu-button">
					<img src={hamburgerMenuIcon} alt="Menu Button" id="menu-button" width={25} height={18} />
				</button>
				<div className="top-header-right">
					<div className="header-profile">
						<div className="header-profile-inner">
							<div className="dropdown">
								<div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<span className={`${(userDetail && !userDetail.image) ? 'greenbg' : ''}`}>
										{userDetail && userDetail.image && imageUrl ? (
											<figure className="mb-0">
												<img src={`${imageUrl}${userDetail.image}`} alt="User profile" onError={(event) => event.target.src = noImageUrl } />
											</figure>
										) : (
											upperCaseOfName(userDetail.first_name ? userDetail.first_name : "", userDetail.last_name ? userDetail.last_name : "")
										)}
									</span>
									<div className="profile-info">
										<strong className="profile-name d-block">{(userDetail && userDetail.full_name) ? userDetail.full_name : ""}</strong>
									</div>
								</div>
								<ul className="dropdown-menu">
									<li><Link className="dropdown-item" to="/edit-profile">{translate("header.update_profile")} </Link></li>
									<li><Link className="dropdown-item" to="#" onClick={() => this.Logout()}>{translate("header.logout")}</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Header;
