import React, { Component } from "react";
import { translate  } from './../../helpers/intl_helpers';
import {STATUS_SUCCESS }  from './../../config/global_constants';
import { callBackendAPI, handleFormValidation,handleFormInputs, callBackendAPIAndImageUpload, reFormatSuccess, reFormatErrors } from './../../helpers/common';
import CropImageComponent from './../../elements/CropImage/CropImageComponent';

class EquipmentCost extends Component {

	constructor(props) {
        super(props)

        this.state = {
			equipmentTypes		:	[],
			errors				:	{},
			fields				:	{},
			imageSrc			: 	{},
			croppedImage		: 	{},
			equipmentImgUrl 	:	"",
			equipmentNoImgUrl	:	"",
			submitted			:	false,
        };

		this.handleFormInputs 	= this.handleFormInputs.bind(this);
		this.handleFormSubmit 	= this.handleFormSubmit.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch.
	 */
    componentDidMount() {
        this.fetchInitialDetails();
    }

	/** Its use to fetch equipment type list and last fill cost details. */
    fetchInitialDetails = () => {
		window.showLoader();
        callBackendAPI([
			{ model: 'equipment', method: 'equipmentData',is_all: true},
			{ model: 'equipment', method: 'equipmentCostList'}
		]).then(response => {
            if(response.success && response.data[0] && response.data[0].status === STATUS_SUCCESS) {
				let typeList 		= 	response.data[0].result;
				let typeCostList	=	response.data[1].result;
				let fieldStats 		=	{};

				if(typeCostList && typeCostList.length){
					typeList.forEach(records =>{
						let eqLabel 	=	records.label;
						let cost 		=	0;
						let colorCode 	=	"#ffffff";
						let image 		=	"";
						typeCostList.forEach(costRecords =>{
							if(costRecords.equipment_type === records.label){
								cost 	= 	costRecords.cost;
								colorCode 	= 	costRecords.color_code ? costRecords.color_code : colorCode;
								image 	=	costRecords.image;
							}
						});

						fieldStats[eqLabel+"_cost"] 		 = 	{value: cost };
						fieldStats[eqLabel+"_color_code"] 	 = 	{value: colorCode };
						fieldStats[eqLabel+"_original_image"]=	image;
					});
				}

                /** Update stats */
                this.setState({
					equipmentTypes		: 	typeList,
					fields				: 	fieldStats,
					equipmentImgUrl		: 	response.data[1].img_url 	? 	response.data[1].img_url	: "",
					equipmentNoImgUrl	:	response.data[1].no_img_url ?	response.data[1].no_img_url	: "",
				},()=>{
					window.hideLoader();
				});
            }
        }).catch(err => console.log(err));
    }

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		const {fields, croppedImage, equipmentTypes} = this.state;

		this.setState({ submitted: true },()=>{

			/** Validation Rules **/
			let validations = {}

			/** Api Requested Data **/
			let params = {model: 'equipment', method: 'saveUpdateEquipmentCost'};

			/** Set form data */
			let formData = new FormData();

			equipmentTypes.forEach(record=>{
				let type 	=	record.value;
				let costKey	=	type+"_cost";
				let colorCode	=	type+"_color_code";
				let eqImage	=	type+"_image";

				validations[costKey] = {"label": translate("equipment.hourly_rate"), "rules" :["required","float"]};

				if(croppedImage && croppedImage[eqImage]){
					formData.append([eqImage], croppedImage[eqImage], (fields[eqImage] && fields[eqImage].file_name ? fields[eqImage].file_name.split(".")[0] :'equipmentType-'+new Date().getTime())+'.jpg');
				}

				params[type] 	=	type;
				params[costKey] = 	fields[costKey] ? fields[costKey].value :"";
				params[colorCode] = 	fields[colorCode] ? fields[colorCode].value :"";
			});

			/** Handle validation **/
			handleFormValidation(validations,this.state.fields, [],false).then(async (response) =>{
				if(!response.success) return this.setState({ errors : response.errors, submitted: false});

				/** Submit form to server */
				let responseData = await callBackendAPIAndImageUpload({ model: 'equipment', method: 'saveUpdateEquipmentCost', fileData: formData, formData: params}, true);
				if (responseData.data.status === STATUS_SUCCESS){
					/** Show success flash message **/
					reFormatSuccess(responseData.data.message[0].msg);

					/** Remove disable attribute from submit button */
					this.setState({ errors: {} , fields: {} , submitted: false});

					/** Fetch details */
					this.fetchInitialDetails();
				}else{
					/** Remove disable attribute from submit button */
					this.setState({submitted: false , errors: reFormatErrors(responseData.data.message)});
				}
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field, type){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });

		if(type === "image"){
			this.setState({
				croppedImage:	{...this.state.croppedImage, ...{[field]: ""}}
			});

			const file = e.target.files[0];
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.setState({
					imageSrc	: 	{...this.state.imageSrc, ...{[field]: reader.result }},
					croppedImage:	{...this.state.croppedImage, ...{[field]: ""}}
				});
			});
			reader.readAsDataURL(file);

		}
	}// end handleFormInputs()

	/** Manage crop complete event */
	onCropComplete = (croppedImg, inputType) => {
		if(inputType){
			this.setState({
				croppedImage: {...this.state.croppedImage, ...{[inputType + "_image"]: croppedImg }}
			});
		}
	};

	render() {
		const {submitted, fields, errors, equipmentTypes, equipmentNoImgUrl, equipmentImgUrl, imageSrc, croppedImage } = this.state;

		return <>
			<div className="dashboard-content">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("equipment.equipment_cost")}</h1>
					</div>
				</div>
				<div className="add-employee department-cost">
					<div className="table-responsive">
						<form className="adduser-form" onSubmit={(event) => this.handleFormSubmit(event, 'addUser')} noValidate>
							<table className="table custom-table">
								<thead>
									<tr className="mb-5">
										<th><p>{translate("equipment.equipment_type")}</p></th>
										<th><p>{translate("equipment.hourly_rate")} ($)</p></th>
										<th><p>{translate("Color Code")} </p></th>
										<th><p>{translate("equipment.image")} </p></th>
									</tr>
								</thead>
								<tbody>
									{equipmentTypes.map(record =>
										<tr key={record.value}>
											<td width="15%">
												<div className="mt-4">
													<label htmlFor="contact_name">{record.label}</label>
												</div>
											</td>
											<td width="25%">
												<div className="form-floating">
													<div>
														<input
															id={"cost_"+record.value}
															type="text"
															className="form-control"
															placeholder={translate("equipment.hourly_rate")}
															value={fields[record.value + "_cost"] ? fields[record.value + "_cost"]["value"] : ""}
															onChange={(event) => this.handleFormInputs(event, record.value + "_cost")}
														/>
														<span className="error text-danger">{errors[record.value + "_cost"]}</span>
													</div>
												</div>
											</td>
											<td width="25%">
												<div className="form-floating">
													<input
														id={"color_code_"+record.value}
														type="color"
														className="form-control"
														placeholder={translate("master.master_color_code")}
														value={fields[record.value + "_color_code"] ? fields[record.value + "_color_code"]["value"] : ""}
														onChange={(event) => this.handleFormInputs(event, record.value + "_color_code")}
													/>
													<span className="error text-danger">{errors[record.value + "_color_code"]}</span>
												</div>
											</td>
											<td width="35%">
												<div className="form-group custom-upload">
													<label className="upload-source">
														<input type="file" name='file' id="file" className="d-none" accept=".png,.jpg,.jpeg" onChange={(event) => this.handleFormInputs(event, record.value + "_image", "image")} />
														<p>
															{fields[record.value + "_image"] && fields[record.value + "_image"].file_name
															?
																fields[record.value + "_image"].file_name
															:
																<>
																	{translate("system.select_image")}
																</>
															}
														</p>
														<span>{translate("system.upload")}</span>
													</label>
													{equipmentImgUrl && fields[record.value + "_original_image"] ?
														<>
															<button type="button" className="recent-img-box" onClick={()=>{ this.setState({lightBoxShow:true })}}>
																<img src={equipmentImgUrl+fields[record.value + "_original_image"]} alt="equipment" onError={(event) => event.target.src = equipmentNoImgUrl } />
															</button>

														</>
													:null}
												</div>
												<span className="error">{errors[record.value + "_image"]}</span>
												{imageSrc && imageSrc[record.value + "_image"] && !croppedImage[record.value + "_image"] && (
													<div className="comman-crop-popup">
														<CropImageComponent imageSrc={imageSrc[record.value + "_image"]} onCropComplete={this.onCropComplete} inputType={record.value} />
													</div>
												)}
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<div className="employee-submit d-flex flex-wrap align-items-center">
								<button type={submitted ? "button" : "submit"} className="btn btn-fill">
									{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	}
}
export default EquipmentCost;
