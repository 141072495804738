import 'rsuite/dist/rsuite.min.css';
import  './../assets/css/style.css';
import  './../assets/css/developer.css';
import 'react-toastify/dist/ReactToastify.css';

import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import { UserDetails }  from '../UserDetails';
import logoGif from '../assets/images/logogif.gif';

toast.configure({ autoClose: 8000, draggable: false });

export default class Layout extends Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
			user_details : (cookies.get('user_details') || {}),
		}
	}

    updateUserState = (value, cb) => {
		this.setState({user_details : value},()=>{
             if(cb) cb();
        });
	};

    render() {
		const childrenWithProps = React.Children.map(this.props.children, child =>
			React.cloneElement(child)
		);
        return (
			<UserDetails.Provider value={{ userDetails: this.state.user_details, updateUserState: this.updateUserState}}>
                <div className="page-loader d-none">
					<div className="logo-icon">
						<img src={logoGif} alt="img" />
					</div>
				</div>
                { childrenWithProps }
            </UserDetails.Provider>
        )
    }
}