import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import InfiniteScroll from "react-infinite-scroll-component";
import Select from 'react-select';

import { NavLink as Link} 	from "react-router-dom";

import { STATUS_SUCCESS, DEPARTMENT_DROPDOWN, CALENDAR_DATE_TIME_FORMAT } from '../../../config/global_constants'
import { callBackendAPI, handleFormInputs } from '../../../helpers/common';
import { translate, formatDate } from '../../../helpers/intl_helpers';

import searchImg from '../../../assets/images/search_icon.svg';
import filterImg from '../../../assets/images/filter-icon.svg';
import reset    from '../../../assets/images/reset.svg';
import closeImg from '../../../assets/images/close_icon.svg';

export default class AssetList extends Component{
    constructor(props){
        super(props);
        this.state = {
            locationList            :   [],
            assetListArray          :   [],
            totalRecords            : 	0,
            isLoading	            : 	true,
            searchInputStats        :   {},
            shopListArray           :   [],
            totalShopRecords        : 	0,
            isShopLoading           :   true,
            shopInputStats          :   {},
            tierLevelList          :    [],
        };
    }

    static propTypes = {
        schedulerData       :   PropTypes.object.isRequired,
        newEvent            :   PropTypes.func.isRequired,
        assetDndSource      :   PropTypes.object.isRequired,
        shopDndSource       :   PropTypes.object.isRequired,
    }

    componentDidMount() {
        const {userDetail} = this.props;

        /** Set API request params */
        callBackendAPI([{ model: 'home', method: 'masterList', type: ["location","equipment_tier_level"] }]).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){
                let locations =  response.data[0].result.location ? response.data[0].result.location :[];
                let tierArr =  response.data[0].result.location ? response.data[0].result.equipment_tier_level :[];
                let selectedLocArr = [];
                let selectedDepArr = [];
                let selectedTierArr = [];
                let empLocationId           = (userDetail && userDetail.location)   ? userDetail.location : [];
                let empLocationDepartment   = (userDetail && userDetail.department) ? userDetail.department : [];
                let notShowingTielLevelCat = response.data[0].equipment_tier_level;

                tierArr.map(val=>{
                    if(val.value !== notShowingTielLevelCat) selectedTierArr.push(val);
                });

                locations.forEach(val=>{
                    if(empLocationId.includes(val.value)) selectedLocArr.push(val);
                });

                DEPARTMENT_DROPDOWN.forEach(option => {
                    let isSelected = empLocationDepartment.includes(option.value);
                    if (isSelected) {
                        selectedDepArr.push(option);
                    }
                });

                this.setState({
                    locationList : locations,
                    tierLevelList : tierArr,
                    searchInputStats: {
                        ...this.state.searchInputStats,
                        location    :   selectedLocArr,
                        department  :   selectedDepArr,
                        tier_level  :   selectedTierArr,
                    },
                    shopInputStats: {
                        ...this.state.shopInputStats,
                        location    :   selectedLocArr,
                        department  :   selectedDepArr,
                    },
                },()=>{
                    this.getAssetsList();
                    this.getShopList();
                });
			}
		}).catch(err => console.log(err));
    }

     // Function to handle button click events for  asset
     handleAssetListByCalendarDate = (isRefresh) => {
        const {schedulerData } = this.props;
        if(isRefresh) {
            this.getAssetsList(true);
        }else{
            this.getAssetsList(true,schedulerData.selectDate,schedulerData.selectDate);
        }
    };// end moveChildItem()

    /**
	* For get assets listing
	*/
	getAssetsList = (freshListing,startDate,endDate)=> {
        const {updateEventCalendar } = this.props;
        const {assetListArray, searchInputStats } = this.state;

        this.setState({
            isLoading : true
        },()=>{
            let assetList   =   !freshListing && assetListArray ? assetListArray :[];
            let skip        =   !freshListing ? assetList.length :0;

            let sDate       =    startDate ? formatDate(startDate, CALENDAR_DATE_TIME_FORMAT) : null;
            let eDate       =    endDate ? formatDate(endDate, CALENDAR_DATE_TIME_FORMAT)     : null;

            let apiParams = {model: 'calendar', method: 'assetsList', skip: skip, start_date: sDate ,end_date: eDate};
            if(searchInputStats){
                let locArr    = [];
                let deptArr    = [];
                let tierArr    = [];
                if(searchInputStats.location && searchInputStats.location.length) locArr = searchInputStats.location.map(element => element.value);
                if(searchInputStats.department && searchInputStats.department.length) deptArr = searchInputStats.department.map(element => element.value);
                if(searchInputStats.tier_level && searchInputStats.tier_level.length) tierArr = searchInputStats.tier_level.map(element => element.value);

                if(searchInputStats.location && searchInputStats.location.length)  apiParams.location       =   locArr;
                if(searchInputStats.department && searchInputStats.department.length)  apiParams.department  =   deptArr;
                if(searchInputStats.tier_level && searchInputStats.tier_level.length)  apiParams.tier_level  =   tierArr;
                if(searchInputStats.equipment_description && searchInputStats.equipment_description.value)    apiParams.description    =   searchInputStats.equipment_description.value;
                if(searchInputStats.equipment_make && searchInputStats.equipment_make.value)    apiParams.equipment_make    =   searchInputStats.equipment_make.value;
                if(searchInputStats.equipment_model && searchInputStats.equipment_model.value)  apiParams.equipment_model   =   searchInputStats.equipment_model.value;
                if(searchInputStats.equipment_code && searchInputStats.equipment_code.value)    apiParams.equipment_code    =   searchInputStats.equipment_code.value;
            }

            callBackendAPI([apiParams]).then(response => {
                if (response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {

                    let updatedAssetList =  assetList.concat(response.data[0].result);
                    updateEventCalendar(updatedAssetList);
                    this.setState({
                        assetListArray 	:	updatedAssetList,
                        totalRecords    : 	response.data[0].recordsTotal,
                        isLoading	    : 	false
                    });
                }
            }).catch(err => console.log(err));
        });
    }// end getAssetsList()

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field,formType){
        let isShop  =   formType === "shop" ? true  :false;
        let formKey =   isShop ? "shopInputStats"   :"searchInputStats";

      	let response	=	handleFormInputs(e, field, this.state[formKey]);
		this.setState({ response },()=>{
            if(field === "equipment_code"){
                if(isShop) this.getShopList(true);
                // else  this.getAssetsList(true);
            }
        });
	}// end handleFormInputs()

    /**
	* For update state fields values when change select box
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
    handleChange = (fieldName) => (fieldVal) => {
        let newObj = {[fieldName]: fieldVal};
        let updatedFields = {...this.state.searchInputStats, ...newObj};
        if(fieldName === "location") updatedFields['department'] = null;

        this.setState({searchInputStats: updatedFields});
    }// end handleChange()

    /**
	* For update state fields values when change select box
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
    handleShopDepartmentChange = (fieldName) => (fieldVal) => {
        let newObj = {[fieldName]: fieldVal};
        let updatedFields = {...this.state.shopInputStats, ...newObj};
        if(fieldName === "location") updatedFields['department'] = null;

        this.setState({shopInputStats: updatedFields});
    }// end handleShopDepartmentChange()

    /**
	* For Clear input values
	*
	* @return null
	*/
    resetSearchInputStats = (type = "") => {
        let isShop      =   type === "shop" ? true :false;
        let loadKey     =   isShop ? "isShopLoading"  :"isLoading";
        let inputKey    =   isShop ? "shopInputStats" :"searchInputStats";

        if(this.state[loadKey]) return false;

        this.setState({[inputKey] : {}},()=>{
            if(isShop){
                this.getShopList(true);
            }else{
                this.getAssetsList(true);
            }
        });
    }

    /**
	* For get shop listing
	*/
	getShopList = (freshListing)=> {
        const {updateEventCalendar } = this.props;
        const {shopListArray, shopInputStats } = this.state;

        this.setState({
            isShopLoading : true
        },()=>{
            let shopList=   !freshListing && shopListArray ? shopListArray :[];
            let skip    =   !freshListing ? shopList.length :0;

            let apiParams = {model: 'calendar', method: 'assetsList', only_shop: true, skip: skip};
            if(shopInputStats){
                let locArr    = [];
                let deptArr    = [];
                if(shopInputStats.location && shopInputStats.location.length) locArr = shopInputStats.location.map(element => element.value);
                if(shopInputStats.department && shopInputStats.department.length) deptArr = shopInputStats.department.map(element => element.value);

                if(shopInputStats.location && shopInputStats.location.length)       apiParams.location                  =   locArr;
                if(shopInputStats.department && shopInputStats.department.length)   apiParams.department                =   deptArr;
                if(shopInputStats.equipment_type && shopInputStats.equipment_type.value)    apiParams.equipment_type    =   shopInputStats.equipment_type.value;
                if(shopInputStats.equipment_make && shopInputStats.equipment_make.value)    apiParams.equipment_make    =   shopInputStats.equipment_make.value;
                if(shopInputStats.equipment_model && shopInputStats.equipment_model.value)  apiParams.equipment_model   =   shopInputStats.equipment_model.value;
                if(shopInputStats.equipment_code && shopInputStats.equipment_code.value)    apiParams.equipment_code    =   shopInputStats.equipment_code.value;
            }

            callBackendAPI([apiParams]).then(response => {
                if (response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {

                    let updatedShopList =  shopList.concat(response.data[0].result);
                    updateEventCalendar(updatedShopList, "shop");
                    this.setState({
                        shopListArray 	:	updatedShopList,
                        totalShopRecords: 	response.data[0].recordsTotal,
                        isShopLoading	: 	false
                    });
                }
            }).catch(err => console.log(err));
        });
    }

    // Function to handle button click events for move shop item to  asset
    moveChildItem = () => {
        this.getAssetsList(true);
        this.getShopList(true);
    };// end moveChildItem()

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.getAssetsList(true); // Trigger search on Enter key press
        }
    };

    render(){
        const { schedulerData, newEvent, assetDndSource, shopDndSource, moveItem, setAssetItem} = this.props;
        const { totalRecords, searchInputStats, assetListArray, isLoading, shopListArray, isShopLoading, totalShopRecords, shopInputStats, locationList, tierLevelList } = this.state;

        let DnDTaskItem = assetDndSource.getDragSource();
        let tasks = schedulerData.assetGroups;

        let taskList = tasks.map((item, index) => {
            return <DnDTaskItem key={'assets'+item.id+index} image={item.state.image} task={item} moveItem={moveItem} setAssetItem={setAssetItem} newEvent={newEvent} schedulerData={schedulerData} />
        });

        let ShopTaskItem    =   shopDndSource.getDragSource();
        let shopTasks       =   schedulerData.shopGroups;
        let shopTaskList    =   shopTasks.map((item) => {
            return <ShopTaskItem key={item.id} image={item.state.image} task={item} moveItem={moveItem} setAssetItem={setAssetItem}  schedulerData={schedulerData} />
        });

        return (
            <>
                {/*  Assets listing */}
                <div className='sourcebox iron-members-sourcebox'>
                    <div className='sourcebox-filter-wrap'>
                        <h4>{translate("calendar.iron_assets")}  </h4>
                        <div className='sourcebox-filter'>
                            <div className="dropdown">
                                <Link to='#filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="filter-collapse">
                                    <img src={filterImg} alt='Filter' width={18} height={20}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-section">
                        <input type='text' id='equipment_code' name='equipment_code' className='form-control' placeholder={translate("calendar.search_by_equipment")} onChange={(event) => this.handleFormInputs(event, "equipment_code")} onKeyDown={this.handleKeyDown} value={searchInputStats.equipment_code && searchInputStats.equipment_code.value ? searchInputStats.equipment_code.value :""} />
                        <button className="search-icon" onClick={()=>{this.getAssetsList(true) }}>
                            <img src={searchImg} alt='Search' width={20} height={20}/>
                        </button>
                    </div>
                    <div className="collapse filter-collapse" id="filter-collapse">
                        <div className="card card-body">
                            <div className='dropdown-filter-header d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                <strong>{translate("system.search")}</strong>
                                <div className='d-flex align-items-center'>
                                    <Link to='#' className='reset-icon' onClick={()=> {this.resetSearchInputStats()}}>
                                        <img src={reset} alt='Reset' width={18} height={18}/>
                                    </Link>
                                    <Link className="ms-2" to='#filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="filter-collapse">
                                    <img src={closeImg} alt='Close' width={20} height={20}/>
                                    </Link>
                                </div>
                            </div>
                            <div className='filter--form'>
                                <div className='form-group mb-2'>
                                    <Select
                                        className="custom-react-select"
                                        placeholder={<label htmlFor="location">{translate("calendar.location")}</label>}
                                        options={locationList}
                                        value={searchInputStats.location ? searchInputStats.location : null}
                                        closeMenuOnSelect={true}
                                        onChange={this.handleChange("location")}
                                        isMulti
                                        isClearable={true}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <Select
                                        className="custom-react-select"
                                        options={(searchInputStats.location && Array.isArray(searchInputStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
                                            searchInputStats.location.some(loc => loc.department && loc.department.includes(dept.value))
                                        ) : []}
                                        closeMenuOnSelect={true}
                                        isMulti
                                        placeholder={translate("calendar.all_department")}
                                        value={searchInputStats.department ? searchInputStats.department :null}
                                        onChange={this.handleChange("department")}
                                        isClearable={true}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <Select
                                        className="custom-react-select"
                                        placeholder={<label htmlFor="location">{translate("calendar.tier_level")}</label>}
                                        options={tierLevelList}
                                        value={searchInputStats.tier_level ? searchInputStats.tier_level : null}
                                        closeMenuOnSelect={true}
                                        onChange={this.handleChange("tier_level")}
                                        isMulti
                                        isClearable={true}
                                    />
                                </div>
                                <div className='button--filter'>
                                    <button type='button' className='btn btn-fill w-100' onClick={()=>{this.getAssetsList(true) }}>
                                        {translate("system.search")} {isLoading && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='sourcebox-listing calendarScrollableDiv' id="assetsScrollableDiv">
                        <InfiniteScroll
                            key={assetListArray.length}
                            dataLength={assetListArray.length}
                            next={() => { this.getAssetsList() }}
                            hasMore={(totalRecords && assetListArray.length && totalRecords > assetListArray.length) ? true :false}
                            scrollableTarget="assetsScrollableDiv"
                        >
                            {taskList.length ? (
                                <ul>
                                    {taskList}
                                </ul>
                            ) : <div className="text-center">{translate("calendar.no_data_available")}</div>
                            }
                        </InfiniteScroll>
                    </div>
                </div>

                {/*  Shop listing */}
                <div className='sourcebox shop-members-sourcebox'>
                    <div className='sourcebox-filter-wrap'>
                        <h4>{translate("calendar.shop")}  </h4>
                        <div className='sourcebox-filter'>
                            <div className="dropdown">
                                <Link to='#shop-filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="shop-filter-collapse">
                                    <img src={filterImg} alt='Filter' width={18} height={20}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-section">
                        <input type='text' id='equipment_code' name='equipment_code' className='form-control' placeholder={translate("calendar.search_by_equipment_code")} onChange={(event) => this.handleFormInputs(event, "equipment_code","shop")} value={shopInputStats.equipment_code && shopInputStats.equipment_code.value ? shopInputStats.equipment_code.value :""} />
                        <button className="search-icon">
                            <img src={searchImg} alt='Search' width={20} height={20}/>
                        </button>
                    </div>
                    <div className="collapse filter-collapse" id="shop-filter-collapse">
                        <div className="card card-body">
                            <div className='dropdown-filter-header d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                <strong>{translate("system.search")}</strong>
                                <div className='d-flex align-items-center'>
                                    <Link to='#' className='reset-icon' onClick={()=> {this.resetSearchInputStats("shop")}}>
                                        <img src={reset} alt='Reset' width={18} height={18}/>
                                    </Link>
                                    <Link className="ms-2" to='#shop-filter-collapse' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="filter-collapse">
                                        <img src={closeImg} alt='Close' width={20} height={20}/>
                                    </Link>
                                </div>
                            </div>
                            <div className='filter--form'>
                                <div className='form-group mb-2'>
                                    <Select
                                        className="custom-react-select"
                                        placeholder={<label htmlFor="location">{translate("employee.location")}</label>}
                                        options={locationList}
                                        value={shopInputStats.location ? shopInputStats.location : null}
                                        closeMenuOnSelect={true}
                                        onChange={this.handleShopDepartmentChange("location")}
                                        isMulti
                                        isClearable={true}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <Select
                                        className="custom-react-select"
                                        options={(shopInputStats.location && Array.isArray(shopInputStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
                                            shopInputStats.location.some(loc => loc.department && loc.department.includes(dept.value))
                                        ) : []}
                                        closeMenuOnSelect={true}
                                        isMulti
                                        placeholder={translate("calendar.all_department")}
                                        value={shopInputStats.department ? shopInputStats.department :null}
                                        onChange={this.handleShopDepartmentChange("department")}
                                        isClearable={true}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <input type='text' placeholder={translate("calendar.search_by_equipment_type")} className='form-control' onChange={(event) => this.handleFormInputs(event, "equipment_type","shop")} value={shopInputStats.equipment_type && shopInputStats.equipment_type.value ? shopInputStats.equipment_type.value :""} />
                                </div>
                                <div className='button--filter'>
                                    <button type='button' className='btn btn-fill w-100' onClick={()=>{this.getShopList(true) }}>
                                        {translate("system.search")} {isShopLoading && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sourcebox-listing calendarScrollableDiv' id="shopsScrollableDiv">
                        <InfiniteScroll
                            key={shopListArray.length}
                            dataLength={shopListArray.length}
                            next={() => { this.getShopList() }}
                            hasMore={(totalShopRecords && shopListArray.length && totalShopRecords > shopListArray.length) ? true :false}
                            scrollableTarget="shopsScrollableDiv"
                        >
                            {shopTaskList.length ?
                                <ul>
                                    {shopTaskList}
                                </ul>
                            :
                                <div className="text-center">{translate("calendar.no_data_available")}</div>
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </>
        )
    }
}

