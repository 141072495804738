import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";
import BlockUi from '@availity/block-ui';

import { handleFormInputs, handleFormValidation, callBackendAPI } from '../helpers/common';
import { STATUS_SUCCESS}  from './../config/global_constants';
import { translate } from '../helpers/intl_helpers';

class ResetPassword extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoading	:	true,
			fields		: 	{},
			errors		: 	{},
			submitted	:	false
		};

		this.handleFormInputs 	=	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
		window.scrollTo(0, 0);
	}

	/** This function invoked immediately after a component is mounted.
	 * Its use to check validate string is valid or not
	 */
	componentDidMount(){
		const {validate_string} = this.props.match.params;

		/** Check string is valid or not  */
		callBackendAPI([{model: 'registration', method: 'checkValidateString',validate_string:validate_string}]).then(response=>{
			if(!response.success || response.data[0].status !== STATUS_SUCCESS){
				return this.props.history.push('/admin-login');
			}

			this.setState({
				isLoading	:	false,
				fields		:	{validate_string : validate_string}
			});
		}).catch(err => console.log(err));
	}// end componentDidMount()

	/**
	* For manage form submit event
	*
	* @param e  as current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();
		const {password,confirm_password,validate_string} = this.state.fields;

		this.setState({submitted: true},()=>{
			/** Api Requested Data **/
			let params = [{
				model					: 	'registration',
				method					: 	'resetPassword',
				password				: 	(password && password.value)				?	password.value			:"",
				confirm_password		: 	(confirm_password && confirm_password.value)?	confirm_password.value	:"",
				validate_string			: 	(validate_string)	?	validate_string	:"",
			}];

			/** Validation Rules **/
			let validations = {
				password			: 	{"label": translate('user.password'), "rules" :["required","minLength"]},
				confirm_password	: 	{"label": translate('user.confirm_password'), "rules" :["required","minLength","equal"]},
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params).then(response=>{
				/** Remove disable attribute from submit button */
				this.setState({submitted: false});
				if(!response.success) return this.setState({ errors : response.errors});

				/** Redirect to login page **/
				this.props.history.push("/admin-login");
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted, isLoading, fields, errors} = this.state;
		return (
			<BlockUi tag="div" blocking={isLoading}>
				<div className="login-box">
					<form onSubmit={(event) => this.handleFormSubmit(event)} noValidate>
						<h1>{translate("users.reset_password")}</h1>
						<div className="form-floating">
							<input type="password" name="password" placeholder={translate('user.password')} className="form-control" onChange={(event) => this.handleFormInputs(event, "password")} value={ fields["password"] ? fields["password"]["value"] : ""}/>
							<label htmlFor="email">{translate("user.password")}<span className="error text-danger">*</span></label>
							<span className="error">{errors["password"]}</span>
						</div>
						<div className="form-floating">
							<input type="password" name="confirm_password" placeholder={translate('user.confirm_password')} className="form-control" onChange={(event) => this.handleFormInputs(event, "confirm_password")} value={ fields["confirm_password"] ? fields["confirm_password"]["value"] : ""}/>
							<label htmlFor="email">{translate("user.confirm_password")}<span className="error text-danger">*</span></label>
							<span className="error">{errors["confirm_password"]}</span>
						</div>

						<div className="d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
							<p> {translate('user.already_registered') } <Link to="/admin-login" >{ translate('user.sign_in') }</Link> </p>
							<button type={submitted ? "button" : "submit"} className="btn btn-fill" >
								{translate('system.submit')} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
							</button>
						</div>
					</form>
				</div>
			</BlockUi>
		);
	}
}
export default ResetPassword;
