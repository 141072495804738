
import 'rsuite/dist/rsuite.min.css';
import  './../assets/css/style.css';
import  './../assets/css/developer.css';
import 'react-toastify/dist/ReactToastify.css';

import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import {ErrorBoundary} from './../components/ErrorBoundary';

import Header from './../elements/Header';
import LeftMenu from './../elements/LeftMenu';
import { UserDetails }  from '../UserDetails';
import logoGif from '../assets/images/logogif.gif';

toast.configure({ autoClose: 8000, draggable: false });

class Layout extends Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
			user_details : (cookies.get('user_details') || {}),
		}
	}

    updateUserState = (value, cb) => {
		this.setState({user_details : value},()=>{
            if(cb) cb();
        });
	};

	componentDidMount(){
		document.body.classList.remove('calendar-view-page');
		document.body.classList.remove('side-menu-open');
	}

    render() {
		const childrenWithProps = React.Children.map(this.props.children, child =>
			React.cloneElement(child)
		);

        return (
			<ErrorBoundary>
				<UserDetails.Provider value={{ userDetails: this.state.user_details, updateUserState: this.updateUserState }}>
						<div className="dashboard-wrapper">
							<LeftMenu />
							<div className="dashboard-right">
								<Header />
								{childrenWithProps}
							</div>
							<div className="page-loader d-none">
								<div className="logo-icon">
									<img src={logoGif} alt="img" />
								</div>
							</div>
						</div>
				</UserDetails.Provider>
			</ErrorBoundary>
        )
    }
}
export default Layout;