import React, {Component} from 'react'
import { translate, formatDate, formatTime } from '../../../helpers/intl_helpers'
import { handleFormValidation, handleFormInputs,  callBackendAPI, customConfirmBox, reFormatSuccess } from '../../../helpers/common';


class MoveShopModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors      :   {},
            fields      :   {},
            submitted   :   false,

            notesList   :   [],
            totalRecords: 	0,
        };
        this.isMountedFlag = false;
        this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.handleNotesFormSubmit 	=  	this.handleNotesFormSubmit.bind(this);
    }


    /**
	* For manage form submit event
	*
	* @param e as current form object
	*
	* @return null
	*/
	handleNotesFormSubmit(e){
		e.preventDefault();

		this.setState({submitted: true},()=>{

            const { description } = this.state.fields;

            /** Api Requested Data **/
            let params = [{
                model		: 	'calendar',
                method		: 	'moveToShop',
                asset_id    :   this.props.movedAssetId ? this.props.movedAssetId :"",
                description : 	(description && description.value)	? description.value :"",

            }];

            /** Validation Rules **/
            let validations = {description	: {"label": translate('notes.maintenance'), "rules" :["required"]}}

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params,true , true, true ).then(response =>{
                this.setState({submitted: false});
                if(!response.success) return this.setState({ errors : response.errors});

                /** Remove disable attribute from submit button */
                this.setState({ errors: {} , fields: {} , submitted: false},()=>{
                    reFormatSuccess(response.message);
                    this.props.refreshAssetOnMoveToShope();
                    window.closeModal('moveShopModal');
                });

            }).catch(err => console.log(err));
        });
	}

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

    /**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        this.setState({ submitted: false, errors: {}, fields: {} });
        window.closeModal('moveShopModal');
	}// end closeModal()

    render() {
        const {submitted, fields, errors} 	= this.state;

        return (
            <div className="modal fade  crew-modal" id="moveShopModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-l">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
                        <div className="modal-body">
                            <h3>{translate("notes.add_maintenance")}</h3>
                            <form className="add-job-title-form" onSubmit={(event) => this.handleNotesFormSubmit(event)} noValidate>
                                <div className='add-job-group'>
                                    <div className="form-group form-floating ">
                                        <textarea  className="form-control" onChange={(event) => this.handleFormInputs(event, "description")} name='description' id='description' value={fields["description"] ? fields["description"]["value"] : ""}></textarea>
                                        <label htmlFor="description">{translate("notes.why_is_this_equipment_out_of_service")}<span className="error text-danger">*</span></label>
                                        <span className="error text-danger">{errors["description"]}</span>
                                    </div>
                                    <button type={submitted ? "button" :"submit"} className="btn btn-fill width-initial mt-0">
                                        {translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                    </button>

                                    <button type='button' className="btn btn-fill width-initial mt-0 ms-1" onClick={()=>{ this.setState({ errors: {} , fields: {} , submitted: false}); }}>
                                        {translate("system.reset")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MoveShopModal
