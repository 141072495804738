import "react-datepicker/dist/react-datepicker.css";
import 'react-image-lightbox/style.css';

import React, { Component } from 'react'
import Dropzone from 'react-dropzone';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { components } from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Lightbox from 'react-image-lightbox';

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  } from 'react-places-autocomplete';
import { DateRangePicker } from 'rsuite';

import { translate, formatTime, formatDate } from '../../../helpers/intl_helpers'
import { STATUS_SUCCESS, CALENDAR_DATE_TIME_FORMAT, TIME_PICKER_FORMAT, RADIO_YES, RADIO_NO, ACTIVE, DATE_RANGE_PICKER_FORMAT, ALLOWED_IMAGE_EXTENSIONS, DEPARTMENT_DROPDOWN, CTR_DEPARTMENT} from '../../../config/global_constants'

import { handleFormValidation, handleFormInputs,  callBackendAPI, callBackendAPIAndImageUpload, reFormatSuccess, reFormatErrors, manageShiftTime, isWeekendDay, customConfirmBox, manageCTRShiftTime } from '../../../helpers/common';

import DeleteIcon 	 from '../../../assets/images/delete-icon.svg';
import documentIcon  from '../../../assets/images/document.svg';
import downloadIcon	 from '../../../assets/images/download_icon.svg';
import infoIcon      from '../../../assets/images/info-icon.svg';
import boxRemoveIcon from '../../../assets/images/box-remove-Icon.svg';

const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        ref={ref}
        value={value}
        onClick={onClick}
        readOnly
        placeholder="Select time range"
        className="form-control"
        style={{ cursor: 'pointer' }}
    />
));

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        {translate("calendar.file_extension_validation")}
    </Tooltip>
);

const customStyles = {
    menu: base => ({
      ...base,
      top: 'unset', // Reset the 'top' property
      bottom: '100%', // Position the dropdown above the select input
      marginTop: '-1px' // Add a slight offset to avoid overlap
    })
};

const customComponents = {
    Menu: props => (
      <components.Menu {...props} />
    )
};

export default class AddSubContractorJob extends Component {
	constructor(props) {
        super(props);
        let dateObj = (this.props.dateObj) ? this.props.dateObj :"";
		let startDate 	=	dateObj.start_date 	?	dateObj.start_date 	:new Date();
		let endDate 	=	dateObj.end_date 	? 	dateObj.end_date 	:new Date();

		/** Set stats */
		let inputFields	=	{
			job_group_color:{value: "#6f0e0e"},
			start_time	: 	{value: manageShiftTime()},
			end_time	: 	{value: manageShiftTime(true)},
			start_date	: 	{value: startDate},
			end_date	: 	{value: endDate},
			is_have_order:  {value: String(RADIO_NO)},
			is_hide_weekend_date:  {value: String(RADIO_NO)},
			is_flagger_required :  {value: String(RADIO_NO)},
			latitude                    :   "",
            longitude                   :   "",
            jobsite_address             :   "",
			date_range 	: 	[new Date(startDate), new Date(endDate)],
			image: {
                file_count: 0,
                files: []
            },
			is_tbd      :   {value: String(RADIO_YES)},
		}

		/** Set values in edit or copy the job */
		let jobDetails 	=	(this.props.jobDetails) ? this.props.jobDetails :{};
		let isEdit		=	jobDetails.isEdit ? jobDetails.isEdit : false;
		let isCopy		=	jobDetails.isCopy ? jobDetails.isCopy : false;
		let jobId		=	"";
		let isMobile	=	(dateObj && dateObj.isMobileScreen) ? dateObj.isMobileScreen : false;

		if(jobDetails && jobDetails._id){
			let tmpDepartment = jobDetails.department;
			if(isEdit){
				jobId =  jobDetails._id;
				if(jobDetails.date && formatDate(jobDetails.date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
					inputFields.date_range	=	[new Date(jobDetails.date), new Date(jobDetails.date)];
				}

				if(jobDetails.fileUrl) 		inputFields.fileUrl 	= 	jobDetails.fileUrl;
				if(jobDetails.attachment) 	inputFields.attachment 	=	jobDetails.attachment;

				inputFields.job_group_id    =   {value: jobDetails.job_group_id ? jobDetails.job_group_id :"" };
                if(jobDetails.job_group_color) inputFields.job_group_color =  {value: jobDetails.job_group_color };
			}

            if(isCopy && jobDetails.selected_date && formatDate(jobDetails.selected_date, CALENDAR_DATE_TIME_FORMAT) >= formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT)){
                inputFields.start_date	=	{value: jobDetails.selected_date};
                inputFields.end_date	=	{value: jobDetails.selected_date};
            }

			if(jobDetails.start_time_24_hour) 	inputFields.start_time 	=	{value: manageShiftTime(null, jobDetails.start_time_24_hour)};
			if(jobDetails.end_time_24_hour) 	inputFields.end_time 	= 	{value: manageShiftTime(true, jobDetails.end_time_24_hour)};

			if(tmpDepartment === CTR_DEPARTMENT) {
                if(jobDetails.start_time_24_hour) 	inputFields.start_time 	=	{value: manageCTRShiftTime(null, jobDetails.start_time_24_hour)};
			    if(jobDetails.end_time_24_hour) 	inputFields.end_time 	= 	{value: manageCTRShiftTime(true, jobDetails.end_time_24_hour)};
            }

			if(jobDetails.location && jobDetails.location_title)    inputFields.location   =	{value: jobDetails.location, label: jobDetails.location_title, department: (jobDetails.location_department) ? jobDetails.location_department : []};
			if(tmpDepartment && jobDetails.department_title)        inputFields.department =	{value: tmpDepartment, label: jobDetails.department_title};

			if(jobDetails.is_have_order)  inputFields.is_have_order =   {value: String(jobDetails.is_have_order)};
			if(jobDetails && jobDetails.is_tbd !== "")  inputFields.is_tbd =   {value: String(jobDetails.is_tbd)};

			if(jobDetails.is_hide_weekend_date)  inputFields.is_hide_weekend_date =   {value: String(jobDetails.is_hide_weekend_date)};

			if(jobDetails.is_flagger_required)      inputFields.is_flagger_required         =   {value: String(RADIO_YES)};
			if(jobDetails.flagger_subcontractor_id && jobDetails.flagger_subcontractor_name) inputFields.flagger_subcontractor_id = jobDetails.flagger_subcontractor_name_list;
			if(jobDetails.materials) 	            inputFields.materials	            =	{value: jobDetails.materials};
			if(jobDetails.additional_equipment) 	inputFields.additional_equipment	=	{value: jobDetails.additional_equipment};

			if(jobDetails.subcontractor_id && jobDetails.subcontractor_name) 		inputFields.subcontractor_id=	{value: jobDetails.subcontractor_id, label: jobDetails.subcontractor_name};
			if(jobDetails.project_manager_id && jobDetails.project_manager_name) 	inputFields.project_manager	=	{value: jobDetails.project_manager_id, label: jobDetails.project_manager_name};
			if(jobDetails.job_number_id && jobDetails.job_number) 					inputFields.job_number	=	{value: jobDetails.job_number_id, label: jobDetails.job_number, job_number: jobDetails.job_number};
			if(jobDetails.work_order_id && jobDetails.work_order_number) inputFields.work_order_number= {value: jobDetails.work_order_id, label: jobDetails.work_order_number, work_order_number: jobDetails.work_order_number};
			if(jobDetails.contact_name)  			inputFields.contact_name			=	{value: jobDetails.contact_name};
			if(jobDetails.contact_email) 			inputFields.contact_email 			=	{value: jobDetails.contact_email};
			if(jobDetails.contact_phone) 			inputFields.contact_phone 			=	{value: jobDetails.contact_phone};
			if(jobDetails.daily_project_value) 		inputFields.daily_project_value 	=	{value: jobDetails.daily_project_value};
			if(jobDetails.project_name) 			inputFields.project_name 			=	{value: jobDetails.project_name};

			if(jobDetails.latitude) 	            inputFields.latitude 	            =	{value: jobDetails.latitude};
            if(jobDetails.longitude) 	            inputFields.longitude 	            =	{value: jobDetails.longitude};
            if(jobDetails.jobsite_address) 	        inputFields.jobsite_address 	    =	{value: jobDetails.jobsite_address};
			if(jobDetails.jobsite_address_city) 	inputFields.jobsite_address_city 	=	{value: jobDetails.jobsite_address_city};
			if(jobDetails.jobsite_address_zip) 		inputFields.jobsite_address_zip 	=	{value: jobDetails.jobsite_address_zip};
			if(jobDetails.jobsite_address_state)	inputFields.jobsite_address_state 	=	{value: jobDetails.jobsite_address_state};
			if(jobDetails.jobsite_address_street) 	inputFields.jobsite_address_street	=	{value: jobDetails.jobsite_address_street};
			if(jobDetails.jobsite_building) 	    inputFields.jobsite_building	    =	{value: jobDetails.jobsite_building};
			if(jobDetails.scope_of_work_type_title)        inputFields.scope_of_work_type  =	{value: jobDetails.scope_of_work_type, label: jobDetails.scope_of_work_type_title};
            if(jobDetails.scope_of_work) inputFields.scope_of_work = {value: jobDetails.scope_of_work};
		}

        this.state = {
			fields		: 	inputFields,
            workOrderList: 	[],
           	errors      : 	{},
            loading     : 	true,
            submitted	:	false,
			jobId  		: 	jobId,
			isEdit  	: 	isEdit,
			isCopy  	: 	isCopy,
			isMobileScreen: isMobile,
			scopeWorkType       : [],
			location            : [],
			job_data            : (this.props.jobDetails) ? this.props.jobDetails :{},
            fileUrl		        : (this.props.jobDetails && this.props.jobDetails.file_url) ? this.props.jobDetails.file_url :"",
			lightBoxShow	: false,
			lightBoxImgUrl	: "",
        };

        this.handleFormInputs 	= 	this.handleFormInputs.bind(this);
		this.handleChange 		= 	this.handleChange.bind(this);
		this.getDropDownList 	=	this.getDropDownList.bind(this);
		this.closeModal			=  	this.closeModal.bind(this);
    }

	/**
	* For initial data form API
	*/
	componentDidMount() {
        const { fields, isEdit} = this.state;

		if(fields.job_number) this.getWorkOrderList(fields.job_number);

		let apiParams = [{model: 'home', method: 'masterList', type: ["scope_of_work_type","location"] }];
		if(!isEdit) apiParams.push({model: 'jobs', method: 'generate-group-id'});

		window.showLoader();

		/** Call API */
		callBackendAPI(apiParams).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				let tmpstamp	=	{scopeWorkType: response.data[0].result.scope_of_work_type,location     : response.data[0].result.location};
				if(!isEdit) tmpstamp.fields = {...fields, ...{job_group_id: {value: response.data[1].group_id}}};
				this.setState(tmpstamp,()=>{
					window.hideLoader();
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	 * Handle dropdown value state
	 */
	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "start_time") 	fieldVal = {value: fieldVal};
		if(fieldName === "end_time") 	fieldVal = {value: fieldVal};
		let newObj = {[fieldName]: fieldVal};

		let updatedFields = {...this.state.fields, ...newObj};

        if(fieldName === "job_number"){
			updatedFields['work_order_number'] = null;
			this.getWorkOrderList(fieldVal);
			updatedFields.project_name = (fieldVal && fieldVal.project_name) ? {value: fieldVal.project_name}  : "";
		}

		if (fieldName === "date_range") {
            let startDate = fieldVal[0];
            if (startDate) updatedFields['is_hide_weekend_date'] = { value: isWeekendDay(startDate) ? String(RADIO_YES) : String(RADIO_NO) };
        }

		if(fieldName === "department" && fieldVal.value === CTR_DEPARTMENT){
            updatedFields.start_time    = {value: manageCTRShiftTime()}
            updatedFields.end_time      = {value: manageCTRShiftTime(true)}
        }
        if(fieldName === "department" && fieldVal.value !== CTR_DEPARTMENT){
            updatedFields.start_time    = {value: manageShiftTime()}
            updatedFields.end_time      = {value: manageShiftTime(true)}
        }

		/** Set values according to subcontractor */
		if(fieldName === "subcontractor_id"){
			if(fieldVal.contact_name) updatedFields['contact_name'] =	{value: fieldVal.contact_name};
			if(fieldVal.email)  updatedFields['contact_email']  	=	{value: fieldVal.email};
			if(fieldVal.mobile) updatedFields['contact_phone']	 	= 	{value: fieldVal.mobile};
		}

        this.setState({fields: updatedFields});
    }; // end handleChange()

	/**
	* For get drop down list
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiParams  = null;
			if(type === "jobNumber") 	 apiParams = { model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : '', current_page: page };
			if(type === "projectManager")apiParams = { model: 'calendar', method: 'employeeList', is_project_manager: true, emp_name: inputValue ? inputValue : '', current_page: page };
			if(type === "subcontractor") apiParams = {model: 'calendar', method: 'employeeList', is_subcontractor: true, emp_name: inputValue ? inputValue :'', current_page: page};
			if(type === "flagger")       apiParams = { model: 'calendar', method: 'employeeList', is_flagger: true, is_subcontractor: true, emp_name: inputValue ? inputValue : '', current_page: page };

			if(!apiParams) return {options: [], hasMore: false, additional: {page: page}};

            let response = await callBackendAPI([apiParams]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: false, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For get work order listing
	*/
    getWorkOrderList = (fieldVal) =>{
		/** Call API for work order list */
	   	callBackendAPI([{ model: 'calendar', method: 'workOrderList', job_id: fieldVal.value, is_all: true }]).then(response => {
		   	if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){

			   	this.setState({
				   workOrder: response.data[0].result,
			   	});
		   	}
	   }).catch(err => console.log(err));
   	}// end getWorkOrderList()

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('assSubContractorJob');
        this.setState({ submitted: false, errors: {}, fields: {} });
		this.props.handleCloseTaskModal();
	}// end closeModal()

	 /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response =	handleFormInputs(e, field, this.state.fields);
		this.setState({ response },()=>{
			if(field === "is_have_order" && this.state.fields.is_have_order && this.state.fields.is_have_order.value !== "1"){
				this.setState({ fields: {...this.state.fields, ...{work_order_number: null }} });
			}
			if(field === "is_tbd" && this.state.fields.is_tbd && this.state.fields.is_tbd.value !== "1"){
                this.setState({ fields: {...this.state.fields, ...{job_number: null },...{is_have_order: {type: "radio",value: "0"} },...{work_order_number: null }} });
            }
		});
	}// end handleFormInputs()

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		const { start_time, end_time, subcontractor_id, contact_name, contact_email, contact_phone, project_manager, daily_project_value, job_number, work_order_number, project_name, jobsite_address_street, jobsite_address_city, jobsite_address_zip, jobsite_address_state, image, notes, is_have_order, jobsite_address, latitude, longitude, date_range, is_hide_weekend_date, jobsite_building, is_flagger_required, flagger_subcontractor_id, materials, additional_equipment, job_group_id, job_group_color, scope_of_work_type, scope_of_work, location, department, is_tbd } = this.state.fields;

		let flaggerSubcontractorArr   = [];
		if(flagger_subcontractor_id && flagger_subcontractor_id.length) flaggerSubcontractorArr = flagger_subcontractor_id.map(element => element.value);
		this.setState({
			submitted: true,
			fields      :   {
                ...this.state.fields,
                ...{
                    flagger_subcontractor: {value: flaggerSubcontractorArr.length ? flaggerSubcontractorArr : null}
                }
            }
		}, () =>{

			/** Api Requested Data **/

			let params = {
				model					: 	'jobs',
				method					: 	'addSubcontractorJob',
				job_id		    		: 	this.state.jobId ?	this.state.jobId :"",

				job_group_id		    :	(job_group_id && job_group_id.value)        				?   job_group_id.value      :"",
                job_group_color		    :	(job_group_color && job_group_color.value)  				?   job_group_color.value   :"",
				start_date				: 	(date_range && date_range[0])								?	formatDate(date_range[0],CALENDAR_DATE_TIME_FORMAT)		:"",
				end_date				:	(date_range && date_range[1])								?	formatDate(date_range[1],CALENDAR_DATE_TIME_FORMAT)		:"",
				start_time				:	(start_time && start_time.value)							?	formatTime(start_time.value,"HH:mm")					:"",
				end_time				:	(end_time && end_time.value)								?	formatTime(end_time.value,"HH:mm")						:"",

				subcontractor_id		:	(subcontractor_id && subcontractor_id.value)				?	subcontractor_id.value			:"",
				contact_name			:	(contact_name && contact_name.value)						?	contact_name.value				:"",
				contact_email			:	(contact_email && contact_email.value)						?	contact_email.value				:"",
				contact_phone			:	(contact_phone && contact_phone.value)						?	contact_phone.value				:"",
				project_manager			:	(project_manager && project_manager.value)					?	project_manager.value			:"",
				daily_project_value		:	(daily_project_value && daily_project_value.value)			?	daily_project_value.value		:"",
				is_have_order 			:   (is_have_order && is_have_order.value) 						? 	is_have_order.value 			:"",
				is_tbd 			        :   (is_tbd && is_tbd.value) 						            ? 	is_tbd.value 			        :"",
				job_number_id		    : 	(job_number && job_number.value)            				?	job_number.value	            :"",
                job_number		        : 	(job_number && job_number.job_number)       				?	job_number.job_number	        :"",
                work_order_id		    : 	(work_order_number && !work_order_number["__isNew__"] && work_order_number.value)	?	work_order_number.value	:"",
                work_order_number		: 	(work_order_number && work_order_number.work_order_number)	?	work_order_number.work_order_number	:(work_order_number && work_order_number["__isNew__"] ? work_order_number.value :""),
				project_name			:	(project_name && project_name.value)?	project_name.value	:"",
				latitude	            : 	(latitude && latitude.value)                                ?	latitude.value	                :"",
                longitude	            : 	(longitude && longitude.value)                              ?	longitude.value	                :"",
                jobsite_address	        : 	(jobsite_address && jobsite_address.value)                  ?	jobsite_address.value	        :"",
				jobsite_address_street	:	(jobsite_address_street && jobsite_address_street.value)	?	jobsite_address_street.value	:"",
				jobsite_address_city	:	(jobsite_address_city && jobsite_address_city.value)		?	jobsite_address_city.value		:"",
				jobsite_address_zip		:	(jobsite_address_zip && jobsite_address_zip.value)			?	jobsite_address_zip.value		:"",
				jobsite_address_state	:	(jobsite_address_state && jobsite_address_state.value)		?	jobsite_address_state.value		:"",
				notes					:	(notes && notes.value)										?	notes.value						:"",
				is_hide_weekend_date	:   (is_hide_weekend_date && is_hide_weekend_date.value) 		? 	is_hide_weekend_date.value 		:"",
				jobsite_building	    : 	(jobsite_building && jobsite_building.value)                ?	jobsite_building.value	    	:"",
				is_flagger_required		: 	(is_flagger_required && is_flagger_required.value)          ?	is_flagger_required.value	    :"",
				flagger_subcontractor_id: 	flaggerSubcontractorArr,
				materials				: 	(materials && materials.value)								?	materials.value					:"",
				additional_equipment	: 	(additional_equipment && additional_equipment.value)		?	additional_equipment.value		:"",
				scope_of_work_type		: 	(scope_of_work_type && scope_of_work_type.value)            ?	scope_of_work_type.value	    :"",
                scope_of_work		    : 	(scope_of_work && scope_of_work.value)                      ?	scope_of_work.value	            :"",
				location			    : 	(location && location.value)        						?   location.value		            :"",
				department			    : 	(department && department.value)    						?   department.value		        :"",
			};

			/** Validation Rules **/
			let validations = {
				// job_group_id    		:   {label: translate('calendar.job_group_id'), "rules" :["required"] },
                // job_group_color 		:   {label: translate('calendar.job_group_color'), "rules" :["select"] },
				start_time				:	{label: translate('calendar.start_time'), rules :["required"]},
				end_time				:	{label: translate('calendar.end_time'), rules :["required"]},
				subcontractor_id		:	{label: translate('calendar.subcontractor'), rules :["select"]},
				contact_name			:	{label: translate('calendar.contact_name'), rules :["required"]},
				contact_email			:	{label: translate('calendar.contact_email'), rules :["required","email"]},
				contact_phone			:	{label: translate('calendar.contact_phone'), rules :["required","mobile_number"]},
				daily_project_value		:	{label: translate('calendar.daily_project_value'), rules :["required","number"]},
				project_manager			:	{label: translate('calendar.project_manager'), rules :["select"]},
				project_name			:	{label: translate('calendar.project_name'), rules :["required"]},
				jobsite_address			:	{label: translate('calendar.jobsite_address'), rules :["required"]},
				scope_of_work_type			:	{label: translate('calendar.scope_of_work_type'), rules :["select"]},
				scope_of_work			:	{label: translate('calendar.scope_of_work'), rules :["required"]},
				location	    		:   {label: translate('calendar.location'), "rules" :["select"] },
				department	    		:   {label: translate('calendar.department'), "rules" :["select"] },
			}

			if(is_tbd && is_tbd.value === String(RADIO_YES)){
                validations['job_number'] = { "label": translate('job.job_number'), "rules" :["select"] };
                if(is_have_order && is_have_order.value === String(RADIO_YES)) validations['work_order_number'] = { "label": translate('calendar.work_order'), "rules" :["select"] };
            }

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, [], false,true,true).then(async(response)=>{
				if(!response.success) return this.setState({ errors : response.errors, submitted: false});

				let filesArray = [];
				if (image && image.files  && image.files.length) {
					filesArray = image.files;
				}

				/** Set form data */
				let formData = new FormData();
				filesArray.forEach(file => {
					formData.append("attachment", file);
				});

				callBackendAPIAndImageUpload({ model: 'jobs', method: 'addSubcontractorJob', fileData: formData, formData: params},true).then(response => {
					if(response.success && response.data.status === STATUS_SUCCESS) {
						/** Hide loader */
						this.closeModal();

						/** Show success flash message **/
						reFormatSuccess(response.data.message);

						/** Call parent function */
						if(this.props.updateEventCalendar) this.props.updateEventCalendar(null,"calendar");

					}else if(response.data.message){
						this.setState({ errors: reFormatErrors(response.data.message),submitted: false });
					}
				}).catch(err => console.log(err));
			}).catch(err => console.log(err));
		});
	}

	handlePlaceChange = (fieldName) => (fieldVal) => {
        let newObj = {[fieldName]: {value: fieldVal}};
        let updatedFields = {...this.state.fields, ...newObj};

        this.setState({
            fields: updatedFields
        });
    };

    handleSelect = jobsite_address => {
        let updatedFields = { ...this.state.fields };

        geocodeByAddress(jobsite_address).then(results => {
            // Extract latitude and longitude
            return getLatLng(results[0]).then(latLng => {
                // Extract address components
                const addressComponents = results[0].address_components;
                let city = '';
                let state = '';
                let streetName = '';
                let zipCode = '';

                addressComponents.forEach(component => {
                    if (component.types.includes('locality')) {
                        city = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name; // State code (e.g., CA)
                    }
                    if (component.types.includes('route')) {
                        streetName = component.long_name; // Street name (e.g., Main St)
                    }
                    if (component.types.includes('postal_code')) {
                        zipCode = component.long_name; // ZIP code (e.g., 94103)
                    }
                });

                // Update state with extracted information
                updatedFields = {
                    ...updatedFields,
                    jobsite_address: { value: jobsite_address },
                    jobsite_address_state: { value: state },
                    jobsite_address_city: { value: city },
                    jobsite_address_street: { value: streetName },
                    jobsite_address_zip: { value: zipCode },
                    latitude: (latLng && latLng.lat) ? { value : latLng.lat} : "",
                    longitude: (latLng && latLng.lng) ? { value : latLng.lng} : "",
                };

                this.setState({
                    fields: updatedFields
                });
            });
        }).catch(error => console.error('Error', error));
    };

	handleDrop = (acceptedFiles) => {
		this.setState(prevState => ({
			fields: {
			...prevState.fields,
			image: {
				file_count: acceptedFiles.length,
				files: acceptedFiles
			}
			},
			errors: { ...prevState.errors, image: '' } // Reset image errors on change
		}));
	};

	/**
	* For delete attachment image
	*/
	deleteAttachment = (e,job_id,attachment_id,image_id,parentIndex,childIndex) =>{
		e.preventDefault();
        const { job_data } = this.state;
		let confirmation = customConfirmBox(translate("calendar.you_want_to_delete_this_attachment"));
		confirmation.then((confirmed) => {
            if (confirmed) {
				callBackendAPI([{ model: 'jobs', method: 'deleteAttachment',job_id: job_id, attachment_id: attachment_id, image_id: image_id}]).then(response=>{
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						/** Show success flash message **/
						reFormatSuccess(response.data[0].message);

                        job_data.attachment[parentIndex].image.splice(childIndex, 1);
						this.setState({
                            job_data : job_data
                        });

					}
				}).catch(err => console.log(err));
			}
		});
	}// end deleteAttachment()

    /**
	* For delete whole attachment data
	*/
	deleteJobAttachment = (e,job_id,attachment_id,indexPos) =>{
		e.preventDefault();
        const { job_data } = this.state;
		let confirmation = customConfirmBox(translate("calendar.you_want_to_delete_this_attachment"));
		confirmation.then((confirmed) => {
            if (confirmed) {
				callBackendAPI([{ model: 'jobs', method: 'deleteJobAttachment',job_id: job_id, attachment_id: attachment_id}]).then(response=>{
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						/** Show success flash message **/
						reFormatSuccess(response.data[0].message);

                       job_data.attachment.splice(indexPos, 1);
						this.setState({
                            job_data : job_data
                        });
					}
				}).catch(err => console.log(err));
			}
		});
	}// end deleteJobAttachment()

	/** Function to handle image lightbox */
	showImageLightbox = (imageUrl)=>{
		this.setState({
			lightBoxShow	:	true,
			lightBoxImgUrl	:	imageUrl
		});
	}

	/** For download file */
	handleDownload = () => {
		const {lightBoxImgUrl} = this.state;
		const link = document.createElement('a');
		link.href = lightBoxImgUrl;
		link.target = "_new";
		link.download = lightBoxImgUrl;
		link.click(); // Trigger the download
	};

	/**Handle onclick function for remove job */
    handleRemoveJob = (e) =>{
        e.preventDefault();
        const { jobId } = this.state;

        let confirmation = customConfirmBox(translate("calendar.you_want_to_remove_this_job"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [ { model: 'jobs', method: 'deleteJob', job_id: jobId},]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        reFormatSuccess(response.data[0].message)
                        this.closeModal()
                        this.props.updateEventCalendar(null,"calendar");
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

	render() {
		const {submitted, fields, workOrder, errors, isCopy, isEdit, isMobileScreen, scopeWorkType, job_data, fileUrl, lightBoxShow, lightBoxImgUrl, location} = this.state;

		return <>
			<div className="modal fade crew-modal" id="assSubContractorJob" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="assSubContractorJobLabel" aria-hidden="true">
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<button type="button" className="btn-close" onClick={() => this.closeModal()} data-dismiss="modal">X Close</button>
					{isEdit &&
						<button type="button" className="job-delete-btn" onClick={(e)=> { this.handleRemoveJob(e)}}><img src={DeleteIcon} alt="img" /></button>
					}
					<div className="modal-body">
						<h3>{isCopy ? translate('calendar.clone_subcontractor_job') :(isEdit ? translate('calendar.update_subcontractor_job') :translate('calendar.add_subcontractor_job'))}</h3>
						<form onSubmit={(event) => this.handleFormSubmit(event, 'AddSubContractorJob')} noValidate>
								<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
									<div className="related-label-fields">
										<label className="related-label-head" htmlFor="fields-1">{translate('calendar.basic_details')}</label>
										<div className="row">
											{/* <div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="job_group_id" className="form-label">{translate('calendar.job_group_id')} <span className="error text-danger">*</span></label>
													<input type="text" className="form-control" name='job_group_id' placeholder={translate('calendar.job_group_id')}id='job_group_id' value={fields["job_group_id"] ? fields["job_group_id"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "job_group_id")}/>
													<span className="error text-danger">{this.state.errors["job_group_id"]}</span>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="job_group_color" className="form-label">{translate('calendar.job_group_color')} <span className="error text-danger">*</span></label>
													<input type="color" className="form-control" name='job_group_color' placeholder={translate('calendar.job_group_color')} id='job_group_color' value={fields["job_group_color"] ? fields["job_group_color"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "job_group_color")}/>
													<span className="error text-danger">{this.state.errors["job_group_color"]}</span>
												</div>
											</div> */}
											<div className="col-lg-6">
												<div className='form-group'>
													<label htmlFor="location" className="form-label">{translate('calendar.location')} <span className="error text-danger">*</span></label>
													<Select
														className="custom-react-select"
														value={fields.location  ? fields.location : null}
														options={location}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														placeholder={<label htmlFor="location">{translate('calendar.select_location')}</label>}
													/>
													<span className="error text-danger">{this.state.errors["location"]}</span>
												</div>
											</div>
											<div className="col-lg-6">
												<div className='form-group'>
													<label htmlFor="department" className="form-label">{translate('calendar.department')} <span className="error text-danger">*</span></label>
													<Select
														className="custom-react-select"
														value={fields.department ? fields.department: null}
														options={(fields.location && fields.location.department) ? DEPARTMENT_DROPDOWN.filter(department => fields.location.department.includes(department.value)) : []}
														onChange={this.handleChange("department")}
														closeMenuOnSelect={true}
														placeholder={<div><label htmlFor="department">{translate('calendar.select_department')}</label></div>}
													/>
													<span className="error text-danger">{this.state.errors["department"]}</span>
												</div>
											</div>
											{!isEdit ?
												<>
													<div className="col-lg-6">
														<div className="form-group">
															<label htmlFor="start_date" className="form-label">{translate('calendar.start_date')}<span className="error text-danger">*</span></label>
															<DateRangePicker
																value={fields.date_range ? fields.date_range :null}
																format={DATE_RANGE_PICKER_FORMAT}
																size="lg"
																className='form-control input-with-label date-range-picker-common'
																onChange={this.handleChange("date_range")}
																placeholder={translate('reports.search_by_date')}
																cleanable={false}
																ranges={[]}
																editable={false}
																showOneCalendar={isMobileScreen ? true : false}
															/>
															<span className="error text-danger">{errors["date_range"]}</span>
														</div>
													</div>
													<div className="col-lg-6">
														<div className='form-group'>
															<label htmlFor="is_hide_weekend_date" className="form-label">{translate('calendar.is_hide_weekend_date')} </label>
															<div className='form-group checkbox-row order-checkbox-row'>
																<div className="form-check form-check-inline custom-checkbox">
																	<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_YES) ? true :false}/>
																	<label className="form-check-label" htmlFor="is_hide_weekend_dateyes">
																	{translate('calendar.yes')}
																	</label>
																</div>
																<div className="form-check form-check-inline custom-checkbox">
																	<input className="form-check-input" type="radio" name="is_hide_weekend_date" id="is_hide_weekend_dateno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_hide_weekend_date")} checked={fields.is_hide_weekend_date && fields.is_hide_weekend_date.value === String(RADIO_NO) ? true :false} />
																	<label className="form-check-label" htmlFor="is_hide_weekend_dateno">
																	{translate('calendar.no')}
																	</label>
																</div>
															</div>
														</div>
													</div>
													</>
											: null}
											<div className="col-lg-3">
												<div className="form-group">
													<label htmlFor="start_time" className="form-label">{translate('calendar.start_time')}</label>
													<DatePicker
														selected={fields.start_time && fields.start_time.value ? fields.start_time.value : null}
														onChange={this.handleChange('start_time')}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={1}
														timeCaption={translate("calendar.time")}
														dateFormat={TIME_PICKER_FORMAT}
														timeFormat={TIME_PICKER_FORMAT}
														className="form-control"
														placeholderText={translate('calendar.start_time')}
														customInput={<CustomTimeInput />}
													/>
													<span className="error text-danger">{errors["start_time"]}</span>
												</div>
											</div>
											<div className="col-lg-3">
												<div className="form-group">
													<label htmlFor="end_time" className="form-label">{translate('calendar.end_time')}</label>
													<DatePicker
														selected={fields.end_time && fields.end_time.value ? fields.end_time.value : null}
														onChange={this.handleChange('end_time')}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={1}
														timeCaption={translate("calendar.time")}
														dateFormat={TIME_PICKER_FORMAT}
														timeFormat={TIME_PICKER_FORMAT}
														className="form-control"
														placeholderText={translate('calendar.end_time')}
														customInput={<CustomTimeInput />}
													/>
													<span className="error text-danger">{errors["end_time"]}</span>
												</div>
											</div>
										</div>
									</div>
								</div>

							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								<div className="related-label-fields">
									<label className="related-label-head" htmlFor="fields-1">{translate('calendar.job_details')}</label>
									<div className="row">
										<div className="col-lg-4">
											<div className='form-group'>
												<label htmlFor="subcontractor" className="form-label">{translate('calendar.subcontractor')} <span className="error text-danger">*</span></label>
												<AsyncPaginate
													className="custom-react-select"
													value={fields.subcontractor_id  ? fields.subcontractor_id : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("subcontractor_id")}
													additional={{ page: 1,type: "subcontractor"}}
													placeholder={<label htmlFor="subcontractor_id">{translate('calendar.select_subcontractor')}</label>}
													styles={customStyles}
													components={customComponents}
												/>
												<span className="error text-danger">{errors["subcontractor_id"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="form-group">
												<label htmlFor="contact_name" className="form-label">{translate('calendar.contact_name')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='contact_name' placeholder={translate('calendar.contact_name')} id='contact_name' value={fields["contact_name"] ? fields["contact_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_name")} />
												<span className="error text-danger">{errors["contact_name"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="form-group">
												<label htmlFor="contact_phone" className="form-label">{translate('calendar.contact_phone')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='contact_phone' placeholder={translate('calendar.contact_phone')} id='contact_phone' value={fields["contact_phone"] ? fields["contact_phone"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_phone")} />
												<span className="error text-danger">{errors["contact_phone"]}</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-4">
											<div className="form-group">
												<label htmlFor="contact_email" className="form-label">{translate('calendar.contact_email')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='contact_email' placeholder={translate('calendar.contact_email')} id='contact_email' value={fields["contact_email"] ? fields["contact_email"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "contact_email")} />
												<span className="error text-danger">{errors["contact_email"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="form-group">
											<label htmlFor="project_manager" className="form-label">{translate('calendar.project_manager')} <span className="error text-danger">*</span></label>
												<AsyncPaginate
													className="custom-react-select"
													value={fields.project_manager  ? fields.project_manager : null}
													loadOptions={this.getDropDownList}
													onChange={this.handleChange("project_manager")}
													additional={{ page: 1, type: "projectManager"}}
													placeholder={<label htmlFor="project_manager">{translate('calendar.select_project_manager')}</label>}
													styles={customStyles}
													components={customComponents}
												/>
												<span className="error text-danger">{errors["project_manager"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="form-group">
												<label htmlFor="daily_project_value" className="form-label">{translate('calendar.daily_project_value')} ($) <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='daily_project_value' placeholder={translate('calendar.daily_project_value')} id='daily_project_value' value={fields["daily_project_value"] ? fields["daily_project_value"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "daily_project_value")} />
												<span className="error text-danger">{errors["daily_project_value"]}</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-4">
											<div className='form-group'>
												<label htmlFor="is_tbd" className="form-label">{translate('calendar.is_tbd')} </label>
												<div className='form-group checkbox-row order-checkbox-row'>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_tbd" id="is_tbdyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_tbd")} defaultChecked={fields.is_tbd && fields.is_tbd.value === String(RADIO_YES) ? true :false}/>
														<label className="form-check-label" htmlFor="is_tbdyes">
														{translate('calendar.yes')}
														</label>
													</div>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_tbd" id="is_tbdno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_tbd")} defaultChecked={fields.is_tbd && fields.is_tbd.value === String(RADIO_NO) ? true :false} />
														<label className="form-check-label" htmlFor="is_tbdno">
														{translate('calendar.no')}
														</label>
													</div>
												</div>
											</div>
										</div>
										{(fields.is_tbd && fields.is_tbd.value === String(ACTIVE)) &&
											<>
												<div className="col-lg-4">
													<div className="form-group">
														<label htmlFor="job_number" className="form-label">{translate('calendar.job_number')} # <span className="error text-danger">*</span></label>
														<AsyncPaginate
															className="custom-react-select"
															value={fields.job_number  ? fields.job_number : null}
															loadOptions={this.getDropDownList}
															onChange={this.handleChange("job_number")}
															additional={{ page: 1, type: "jobNumber"}}
															placeholder={<label htmlFor="job_number">{translate('calendar.select_job_number')}</label>}
															styles={customStyles}
															components={customComponents}
														/>
														<span className="error text-danger">{errors["job_number"]}</span>
														<span className="error text-danger">{errors["job_number_id"]}</span>
													</div>
												</div>
												<div className="col-lg-4">
													<div className='form-group'>
														<label htmlFor="is_have_order" className="form-label">{translate('calendar.not_have_work_order')} </label>
														<div className='form-group checkbox-row order-checkbox-row'>
															<div className="form-check form-check-inline custom-checkbox">
																<input className="form-check-input" type="radio" name="is_have_order" id="is_have_orderyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_have_order")} defaultChecked={fields.is_have_order && fields.is_have_order.value === String(RADIO_YES) ? true :false}/>
																<label className="form-check-label" htmlFor="is_have_orderyes">
																{translate('calendar.yes')}
																</label>
															</div>
															<div className="form-check form-check-inline custom-checkbox">
																<input className="form-check-input" type="radio" name="is_have_order" id="is_have_orderno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_have_order")} defaultChecked={fields.is_have_order && fields.is_have_order.value === String(RADIO_NO) ? true :false} />
																<label className="form-check-label" htmlFor="is_have_orderno">
																{translate('calendar.no')}
																</label>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-4">
													<div className="form-group">
														<label htmlFor="work_order_number" className="form-label">{translate('calendar.work_order')} <span className="error text-danger">*</span></label>
														<Creatable
															className="custom-react-select"
															value={fields.work_order_number  ? fields.work_order_number : null}
															options={workOrder}
															onChange={this.handleChange("work_order_number")}
															closeMenuOnSelect={true}
															placeholder={<label htmlFor="work_order_number">{translate('calendar.select_work_order')}</label>}
															styles={customStyles}
															components={customComponents}
															isDisabled={(fields.is_have_order && fields.is_have_order.value !== String(ACTIVE)) ? true : false}
														/>
														<span className="error text-danger">{errors["work_order_number"]}</span>
													</div>
												</div>
											</>
										}
										<div className="col-lg-4">
											<div className="form-group">
												<label htmlFor="project_name" className="form-label">{translate('calendar.project_name')} <span className="error text-danger">*</span></label>
												<input type="text" className="form-control" name='project_name' placeholder={translate('calendar.project_name')} id='project_name' value={fields["project_name"] ? fields["project_name"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "project_name")}/>
												<span className="error text-danger">{errors["project_name"]}</span>
											</div>
										</div>
										<div className="col-lg-4">
											<div className='form-group'>
												<label htmlFor="is_flagger_required" className="form-label">{translate('calendar.is_flagger_required')} </label>
												<div className='form-group checkbox-row employee-checkbox-row'>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_flagger_required" id="is_flagger_requiredyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_flagger_required")} defaultChecked={fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_YES) ? true :false} />
														<label className="form-check-label" htmlFor="is_flagger_requiredyes">
														{translate('calendar.yes')}
														</label>
													</div>
													<div className="form-check form-check-inline custom-checkbox">
														<input className="form-check-input" type="radio" name="is_flagger_required" id="is_flagger_requiredno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_flagger_required")} defaultChecked={fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_NO) ? true :false} />
														<label className="form-check-label" htmlFor="is_flagger_requiredno">
														{translate('calendar.no')}
														</label>
													</div>
												</div>
												<span className="error text-danger">{this.state.errors["is_flagger_required"]}</span>
											</div>
										</div>
										{(fields.is_flagger_required && fields.is_flagger_required.value === String(RADIO_YES)) &&
											<>
												<div className="col-lg-4">
													<div className='form-group'>
														<label htmlFor="subcontractor" className="form-label">{translate('calendar.flagging_subcontractor')} </label>
														<AsyncPaginate
															className="custom-react-select"
															value={fields.flagger_subcontractor_id  ? fields.flagger_subcontractor_id : null}
															loadOptions={this.getDropDownList}
															onChange={this.handleChange("flagger_subcontractor_id")}
															additional={{ page: 1,type: "flagger"}}
															placeholder={<label htmlFor="flagger_subcontractor_id">{translate('calendar.select_subcontractor')}</label>}
															isMulti
															styles={customStyles}
															components={customComponents}
														/>
														<span className="error text-danger">{this.state.errors["flagger_subcontractor"]}</span>
														<span className="error text-danger">{this.state.errors["flagger_subcontractor_id"]}</span>
													</div>
												</div>
											</>
										}
									</div>
								</div>
							</div>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								<div className="related-label-fields related-label-fields-2">
									<label className="related-label-head" htmlFor="site_details-fields">{translate('calendar.site_address')}</label>
									<div className="row">
										<div className="col-lg-6">
											<div className="form-group google_field">
												<label htmlFor="jobsite_address" className="form-label">{translate('calendar.jobsite_address')} <span className="error text-danger">*</span></label>
												<PlacesAutocomplete
													value={fields["jobsite_address"] ? fields["jobsite_address"]["value"] : ""}
													onChange={this.handlePlaceChange("jobsite_address")}
													onSelect={this.handleSelect}
													>
													{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
														<div>
															<input
																{...getInputProps({
																placeholder: 'Search Places..',
																className: 'location-search-input form-control',
																})}
															/>
															<div className="autocomplete-dropdown-container">
																{loading && <div>Loading...</div>}
																{suggestions.map((suggestion,index) => {
																	const className = suggestion.active
																		? 'suggestion-item--active'
																		: 'suggestion-item';
																	// inline style for demonstration purpose
																	const style = suggestion.active
																		? { backgroundColor: '#ddd', cursor: 'pointer' }
																		: { backgroundColor: '#ffffff', cursor: 'pointer' };
																	return (
																		<div
																			{...getSuggestionItemProps(suggestion, {
																				className,
																				style,
																			})}
																			key={suggestion.placeId || index}
																		>
																			<span>{suggestion.description}</span>
																		</div>
																	);
																})}
															</div>
														</div>
													)}
												</PlacesAutocomplete>

												<span className="error text-danger">{this.state.errors["jobsite_address"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="jobsite_building" className="form-label">{translate('calendar.jobsite_building')} #</label>
												<input type="text" className="form-control" placeholder="Building/Suite #" name='jobsite_building' id='jobsite_building' value={fields["jobsite_building"] ? fields["jobsite_building"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "jobsite_building")} />
												<span className="error text-danger">{this.state.errors["jobsite_building"]}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								<div className="related-label-fields">
									<label className="related-label-head" htmlFor="fields-1">{translate('calendar.scope_of_work')}</label>
									<div className="row" id="fields-1">
										<div className="col-lg-6">
											<div className='form-group'>
												<label htmlFor="location" className="form-label">{translate('calendar.scope_of_work_type')} <span className="error text-danger">*</span></label>
												<Select
													className="custom-react-select"
													value={fields.scope_of_work_type  ? fields.scope_of_work_type : null}
													options={scopeWorkType}
													onChange={this.handleChange("scope_of_work_type")}
													closeMenuOnSelect={true}
													placeholder={<label htmlFor="scope_of_work_type">{translate('calendar.select_scope_of_work_type')}</label>}
												/>
												<span className="error text-danger">{this.state.errors["scope_of_work_type"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
											<label htmlFor="scope_of_work" className="form-label">{translate('calendar.scope_of_work')} <span className="error text-danger">*</span></label>
												<textarea type="scope_of_work" className="form-control" name='scope_of_work' id='scope_of_work' value={fields["scope_of_work"] ? fields["scope_of_work"]["value"] : ""} placeholder={translate('calendar.scope_of_work')} onChange={(event) => this.handleFormInputs(event, "scope_of_work")}></textarea>
												<span className="error text-danger">{this.state.errors["scope_of_work"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="materials" className="form-label">{translate('calendar.materials')} </label>
												<textarea type="date" className="form-control" placeholder={translate('calendar.materials')} name='materials' id='materials' value={fields["materials"] ? fields["materials"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "materials")}></textarea>
												<span className="error text-danger">{this.state.errors["materials"]}</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="additional_equipment" className="form-label">{translate('calendar.additional_equipment')} </label>
												<textarea type="date" className="form-control" placeholder={translate('calendar.additional_equipment')} name='additional_equipment' id='additional_equipment' value={fields["additional_equipment"] ? fields["additional_equipment"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "additional_equipment")}></textarea>
												<span className="error text-danger">{this.state.errors["additional_equipment"]}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
								<div className="related-label-fields">
									<label className="related-label-head" htmlFor="attachment-fields">{translate('calendar.attachment')}</label>
									<div className="row">
										<div className="col-lg-6">
											<label htmlFor="attachment" className="form-label">
												{translate('calendar.attachment')}
												<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip} >
													<img src={infoIcon} className='ms-1' alt="info-icon" />
												</OverlayTrigger>
											</label>
											<Dropzone onDrop={this.handleDrop} multiple>
												{({ getRootProps, getInputProps }) => (
												<div
													{...getRootProps({ className: 'dropzone' })}
													style={{ border: '2px dashed #ccc', padding: '25px', textAlign: 'center' }}
												>
													<input {...getInputProps()} />
													<p>
													{fields.image && fields.image.file_count ? `${fields.image.file_count} Attachment` : translate("system.select_attachment")}
													</p>
												</div>
												)}
											</Dropzone>
											<span className="error text-danger">{this.state.errors["image"]}</span>
											<span className="error text-danger">{errors["attachment"]}</span>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<label htmlFor="notes" className="form-label">{translate('calendar.description')} </label>
												<textarea type="notes" className="form-control" name='notes' id='notes' value={fields["notes"] ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
												<span className="error text-danger">{errors["notes"]}</span>
											</div>
										</div>
									</div>
									{job_data.attachment && job_data.attachment.length ?
										<div className="attachmentBoxes-wrap">
											<button class="attachmentBoxes-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
												All Attachment
											</button>
											<div class="collapse" id="collapseExample">
												<div class="card card-body p-0">
													<div className="attachment--boxes">
														{job_data.attachment.map((val,index) => (
															<div className="attachment--boxe">
																{val.image && val.image.length ?
																	<div className="images-column">
																		{val.image.map((fileData,childIndex) => (
																			fileData.file_name && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileData.file_name.split('.').pop().toLowerCase()) !== -1 ?

																				(<div className="image--box">
																					<div className="image--box_iner">
																						<figure>
																							<img src={`${fileUrl}${fileData.name}`} alt="Img"  className="sort-name" onClick = {()=>{this.showImageLightbox(`${fileUrl}${fileData.name}`)}} />
																						</figure>
																						<button class="box-remove-btn" onClick={(e) => {this.deleteAttachment(e,job_data._id,val.id,fileData.id,index,childIndex)}}><img src={boxRemoveIcon} alt="img"/></button>
																					</div>
																				</div>)
																			:
																			(<>
																				<a className="image--box document--box" href={`${fileUrl}${fileData.name}`} target="_blank" rel="noreferrer">
																					<div className="image--box_iner">
																						<figure><img src={documentIcon} alt="" width="50" height="50"/></figure>
																						<button class="box-remove-btn" onClick={(e) => {this.deleteAttachment(e,job_data._id,val.id,fileData.id,index,childIndex)}}><img src={boxRemoveIcon} alt="img"/></button>
																					</div>
																				</a>
																			</>)
																		))}
																	</div>
																	: ""
																}
																{val.notes &&
																	<div className="description-column">
																		<p>{val.notes}</p>
																	</div>
																}
																<div className="attachmentActive-btn">
																	<span class="action-btn" onClick={(e) => {this.deleteJobAttachment(e,job_data._id,val.id,index)}}>
																		<img src={DeleteIcon} alt="user" width="16" height="16"/>
																	</span>
																</div>
															</div>
														))}

													</div>
												</div>
											</div>
										</div> : ""
									}
								</div>
							</div>
							<div className='col-xl-12 d-flex'>
								<button type={submitted ? "button" :"submit"} className="btn btn-fill">
									{translate('system.submit')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<button type="button" className="btn btn-outline ms-1" onClick={() => this.closeModal()} data-dismiss="modal">
									{translate('system.close')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{lightBoxShow &&
				<Lightbox
					mainSrc={lightBoxImgUrl}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
					toolbarButtons={[
						<button key="download" onClick={this.handleDownload}>
							<img src={downloadIcon} alt="Download" />
						</button>
					]}
				/>
			}

			<div className="modal-backdrop fade show"></div>
		</>
	}
}

