
import React, {Component} from 'react'

import NoteBody from './NoteBody';

class NotesModal extends Component {

    /**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        this.setState({ submitted: false, errors: {}, fields: {} });
        window.closeModal('notesModal');
	}// end closeModal()

    render() {
        return (
            <div className="modal fade  crew-modal" id="notesModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={()=>{this.closeModal()}}>X Close</button>
                        <div className="modal-body">
                            <NoteBody />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NotesModal
