import "react-datepicker/dist/react-datepicker.css";
import '@availity/block-ui/src/BlockUi.css';
import 'react-image-lightbox/style.css';

import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import BlockUi from '@availity/block-ui';
import Lightbox from 'react-image-lightbox';
import {AsyncPaginate} from 'react-select-async-paginate';
import { components } from 'react-select';

import { callBackendAPI, handleFormValidation, callBackendAPIAndImageUpload, reFormatSuccess,reFormatErrors, handleFormInputs, CustomInput } from './../../helpers/common';
import { STATUS_SUCCESS , PERMISSION_LEVEL_DROPDOWN, DEPARTMENT_DROPDOWN, RADIO_YES, RADIO_NO, JOB_TITLE_TYPE_DROPDOWN, CALENDAR_DATE_TIME_FORMAT} from './../../config/global_constants';
import { translate, formatDate } from './../../helpers/intl_helpers';
import CropImageComponent from './../../elements/CropImage/CropImageComponent';

const customStyles = {
    menu: base => ({
      ...base,
      top: 'unset', // Reset the 'top' property
      bottom: '100%', // Position the dropdown above the select input
      marginTop: '-1px' // Add a slight offset to avoid overlap
    })
};

const customComponents = {
	Menu: props => (
		<components.Menu {...props} />
	)
};

export default class AddUserForm extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isLoading			: 	true,
			errors				:	{},
			fields				:	{display_on_calendar: {value: String(RADIO_NO)} , assigned_equipment: {value: String(RADIO_NO)}},
			submitted			:	false,
			companyList			:	[],
			jobTitleList		:	[],
			skillSetList		:	[],
			locationList		: 	[],
			permissionLevel 	: 	[],
			fileUrl				: 	"",
			imageSrc			:	"",
			noImageUrl 			:   "",
			croppedImage		: 	"",
			driverTierLevelList :   [],
			lightBoxShow		: 	false,
		};

		this.onCropComplete				= this.onCropComplete.bind(this);
		this.handleFormInputs 			= this.handleFormInputs.bind(this);
		this.handleAddUserFormSubmit 	= this.handleAddUserFormSubmit.bind(this);
		this.fetchDataForEdit 			= this.fetchDataForEdit.bind(this);
		this.loadEquipmentOptions 		= this.loadEquipmentOptions.bind(this);
	}

	/**
	* For load equipments options
	*
	* @param inputValue		as 	search term
	* @param loadedOptions	as 	old options array
	* @param page			as 	page count
	*
	* @return json
	*/
	async loadEquipmentOptions(inputValue, loadedOptions, { page }) {
		try{
			let response = await callBackendAPI([{ model: 'calendar', method: 'assetsList',current_page: page, asset_name: inputValue}]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];

			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		:	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1},
			}

		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options		: 	[],
				hasMore		:	false,
				additional	: 	{page: page},
			};
		}
	}

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch.
	 */
	componentDidMount() {
		if (this.props.match.params ? this.props.match.params.employee_id : null) {
			this.fetchDataForEdit(this.props.match.params.employee_id);
		}

		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["job_title","driver_tier_level" , "location","company","skill_set"]}]).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading			: false,
					jobTitleList		: response.data[0].result.job_title,
					driverTierLevelList : response.data[0].result.driver_tier_level,
					locationList 		: response.data[0].result.location,
					companyList 		: response.data[0].result.company,
					skillSetList 		: response.data[0].result.skill_set
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	 * This function invoked immediately after updating occurs. This method is not called for the initial render.
	 * Its call when another update employee form open
	 */
	componentDidUpdate(prevProps) {
		const { employee_id } = this.props.match.params ? this.props.match.params : {};

		if (employee_id !== prevProps.match.params.employee_id) {
			this.fetchDataForEdit(employee_id);
		}
	}

	/**
	 * fetch employee data for show prefill in form
	 *
	 * @param employeeId as current employee id
	 */
	fetchDataForEdit(employeeId){
		/** Update loading stats */
		this.setState({ isLoading : true},()=>{

			/** Set API request params */
			let apiReq = [{ model: 'home', method: 'masterList', type: ["job_title","driver_tier_level" , "location","company","skill_set"] }]
			if(employeeId) apiReq.push({ model: 'employee', method: 'employeeDetails', employee_id: employeeId });

			callBackendAPI(apiReq).then(response =>{
				if(!response.success || response.data[0].status !== STATUS_SUCCESS || (employeeId && response.data[1].status !== STATUS_SUCCESS)) {
					/** Redirect to employee page */
					this.props.history.push('/employee');
				}

				if(response.success && response.data[0].status === STATUS_SUCCESS) {
					let tmpStats = {isLoading: false};

					/** Set masters */
					let locationList = response.data[0].result && response.data[0].result.location ? response.data[0].result.location :[];
					if(response.data[0].result && response.data[0].result.location) tmpStats.locationList = locationList;
					if(response.data[0].result && response.data[0].result.job_title) tmpStats.jobTitleList = response.data[0].result.job_title;
					if(response.data[0].result && response.data[0].result.company_name) tmpStats.companyList = response.data[0].result.company;
					if(response.data[0].result && response.data[0].result.driver_tier_level) tmpStats.driverTierLevelList = response.data[0].result.driver_tier_level;
					if(response.data[0].result && response.data[0].result.skill_set) tmpStats.skillSetList = response.data[0].result.skill_set;

					if(response.data[1].status === STATUS_SUCCESS){
						tmpStats.fileUrl = response.data[1].file_url
						tmpStats.noImageUrl = response.data[1].no_img_url

						let employeeData=	response.data[1].result || {};
						let userDeprt 	= employeeData.department ? employeeData.department :[];
						let lctData 	= employeeData.location ? employeeData.location :[];

						console.log("employeeData",employeeData)

						let addedDepartments = new Set();
						let equipArr = [];
						let userDeprtList = [];
						let userLocationList = [];
						if(locationList.length && userDeprt.length){
							locationList.forEach(element=>{
								if(lctData.indexOf(element.value) !== -1){
									userLocationList.push({value: element.value ,label: element.label, department: element.department});

									element.department.forEach(item=>{
										if(userDeprt.indexOf(item) !== -1){
											let matchDepartment = DEPARTMENT_DROPDOWN.find(dept => dept.value === item);
											if (matchDepartment) {
												if (!addedDepartments.has(item)) {
													userDeprtList.push(matchDepartment);
													addedDepartments.add(item); // Mark the department as added
												}
											}
										}
									});
								}
							});
						}

						if(employeeData.equipment_details && employeeData.equipment_details.length){
							employeeData.equipment_details.forEach(element => {
								let labelVal = element.equipment_code+"-"+element.equipment_model+"-"+element.description;
								equipArr.push({_id: element._id, value: element._id, label: labelVal});
							});
						}

						tmpStats.fields = {
							employee_id			: { value: employeeData._id || "" },
							first_name			: { value: employeeData.first_name || "" },
							last_name			: { value: employeeData.last_name || "" },
							email				: { value: employeeData.email || "" },
							mobile_number		: { value: employeeData.mobile || "" },
							job_title			: { value: employeeData.job_title || "" },
							company_name		: { value: employeeData.company_name || "" },
							location_id			: userLocationList,
							department_id		: userDeprtList,
							driver_tier_level	: { value: employeeData.driver_tier_level || "" },
							driver				: { value: employeeData.driver || "" },
							line_of_business	: { value: employeeData.line_of_business || "" },
							employee_number		: { value: employeeData.employee_number || "" },
							display_on_calendar	: { value: String(employeeData.display_on_calendar) || "" },
							hire_date			: { value: employeeData.hire_date || "" },
							termination_date	: { value: employeeData.termination_date || "" },
							assigned_equipment	: { value: employeeData.assigned_equipment ? String(employeeData.assigned_equipment) : String(RADIO_NO) },
							equipment_id	    : equipArr,
							image				: { value: employeeData.image || "" },
							old_image			: { value: employeeData.image || "" },
							skill_set			: employeeData.skill_set ? { value: employeeData.skill_set || "" } : ""
						};

						let tmpPermissionLevel		=	employeeData.permission_level || "";
						let tmpPermissionLevelLabel	=	"";
						let tmpJobTitleType			=	employeeData.job_title_type || "";
						let tmpJobTitleTypeLabel	=	"";

						PERMISSION_LEVEL_DROPDOWN.forEach(element => {
							if(element.value === tmpPermissionLevel) tmpPermissionLevelLabel = element.label;
						});
						JOB_TITLE_TYPE_DROPDOWN.forEach(element => {
							if(element.value === tmpJobTitleType) tmpJobTitleTypeLabel = element.label;
						});
						if(tmpPermissionLevel && tmpPermissionLevelLabel) 			 tmpStats.fields.permission_level 	= 	{value: tmpPermissionLevel|| "", label: tmpPermissionLevelLabel };

						if(tmpJobTitleType && tmpJobTitleTypeLabel) 			 	 tmpStats.fields.job_title_type 	= 	{value: tmpJobTitleType|| "", label: tmpJobTitleTypeLabel };

						if(employeeData.location && employeeData.location_title) 	 tmpStats.fields.location 			= 	userLocationList;
						if(employeeData.job_title && employeeData.job_title_name) 	 tmpStats.fields.job_title 	    	= 	{value: employeeData.job_title, label: employeeData.job_title_name};
						if(employeeData.company_name && employeeData.company_title)  tmpStats.fields.company_name 		= 	{value: employeeData.company_name, label: employeeData.company_title};
						if(employeeData.driver_tier_level && employeeData.driver_tier_level_title)  tmpStats.fields.driver_tier_level 	= 	{value: employeeData.driver_tier_level, label: employeeData.driver_tier_level_title};
						if(employeeData.skill_set && employeeData.skill_set_name) 	 tmpStats.fields.skill_set 	    	= 	{value: employeeData.skill_set, label: employeeData.skill_set_name};
					}

					/** Update stats */
					this.setState(tmpStats);
				}
			}).catch(err => console.log(err));
		});
	}//fetchDataForEdit

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleAddUserFormSubmit(e) {
		e.preventDefault();

		const {employee_id,employee_number, first_name, last_name, email, mobile_number, job_title, company_name, permission_level, hire_date, termination_date, department_id,location_id, display_on_calendar, driver_tier_level,assigned_equipment,equipment_id, job_title_type, skill_set} = this.state.fields;

		let locationArr   	= [];
		let departmentArr   = [];
		let equipArr   		= [];
		if (location_id && location_id.length) locationArr = location_id.map(element => element.value);
		if (department_id && department_id.length) departmentArr = department_id.map(element => element.value);
		if (equipment_id && equipment_id.length) equipArr = equipment_id.map(element => element.value);

		this.setState({
			submitted: true,
			fields : {
                ...this.state.fields,
                ...{
                    location: {value: locationArr.length ? locationArr : null},
                    department: {value: departmentArr.length ? departmentArr : null},
                    equipment: {value: equipArr.length ? equipArr : null},
                }
            }
		 },()=>{

			/** Api Requested Data **/
			let params	 =	[{
				model					:	'employee',
				method					:	'addEditEmployee',
				employee_id				:	employee_id && employee_id.value  ? employee_id.value	:"",
				employee_number			:	employee_number && employee_number.value 			?	employee_number.value 		:"",
				first_name				:	(first_name && first_name.value) 					?	first_name.value 			:"",
				last_name				:	(last_name && last_name.value) 	 					?	last_name.value 			:"",
				email					:	(email && email.value) 								?	email.value 				:"",
				mobile_number			:	(mobile_number && mobile_number.value) 				?	mobile_number.value 		:"",
				job_title				:	(job_title && job_title.value) 						?	job_title.value 			:"",
				company_name			:	(company_name && company_name.value) 				?	company_name.value 			:"",
				location				:	locationArr,
				permission_level		:	(permission_level && permission_level.value) 		?	permission_level.value 		:"",
				job_title_type			:	(job_title_type && job_title_type.value) 			?	job_title_type.value 		:"",
				hire_date				:	(hire_date && hire_date.value) 						?	formatDate(hire_date.value,CALENDAR_DATE_TIME_FORMAT) :"",
				termination_date		:	(termination_date && termination_date.value) 		?	formatDate(termination_date.value,CALENDAR_DATE_TIME_FORMAT) :"",
				department				:	departmentArr,
				display_on_calendar		:	(display_on_calendar && display_on_calendar.value) 	?	display_on_calendar.value 	:"",
				driver_tier_level		:   (driver_tier_level && driver_tier_level.value) 		? 	driver_tier_level.value  	:"",
				assigned_equipment 		:   (assigned_equipment && assigned_equipment.value) 	? 	assigned_equipment.value 	:"",
				equipment_id 			: 	equipArr,
				skill_set 				: 	(skill_set && skill_set.value) 						? 	skill_set.value 			: ""
			}];

			/** Validation Rules **/
			let validations = {
				employee_number		:	{label: translate('employee.employee_number'), "rules": ["required"] },
				first_name			:	{label: translate('employee.first_name'), "rules": ["required"] },
				last_name			:	{label: translate('employee.last_name'), "rules": ["required"] },
				email				:	{label: translate('login.email'), "rules": ["required", "email"] },
				job_title			:	{label: translate('employee.job_title'), "rules": ["select"] },
				company_name		:	{label: translate('employee.company_name'), "rules": ["select"] },
				location			:	{label: translate('employee.location'), "rules": ["select"] },
				permission_level	:	{label: translate('employee.permission_level'), "rules": ["select"] },
				job_title_type		:	{label: translate('employee.job_title_type'), "rules": ["select"] },
				department			:	{label: translate('employee.department'), "rules": ["select"] },
				hire_date			:	{label: translate('employee.hire_date'), "rules": ["required"] },
				display_on_calendar	:	{label: translate('employee.display_on_calendar'), "rules": ["select"] },
				driver_tier_level 	:   {label: translate('employee.driver_tier_level'), "rules": ["select"] },
			}

			if(assigned_equipment && assigned_equipment.value === String(RADIO_YES)) validations["equipment"] = { "label": translate('equipment'), "rules": ["select"] };

			/** Handle validation and api response **/
			handleFormValidation(validations, this.state.fields, params,false).then(async (response) => {
				if(!response.success) return this.setState({ errors: response.errors, submitted: false });

				/** Set form data */
				let formData = new FormData();
				if(this.state.croppedImage) formData.append("image", this.state.croppedImage, 'croppedImage.jpg');

				/** Submit form to server */
				let responseData = await callBackendAPIAndImageUpload({ model: 'employee', method: 'addEditEmployee', fileData: formData , formData: params[0]}, true);
				if(responseData.data.status === STATUS_SUCCESS) {
					/** Show success flash message **/
					reFormatSuccess(responseData.data.message[0].msg);

					/** Remove disable attribute from submit button */
					this.setState({ submitted: false, errors: {}, fields: {} });

					/** Redirect to employee page */
					this.props.history.push('/employee');
				} else {
					/** Remove disable attribute from submit button */
					this.setState({ submitted: false, errors: reFormatErrors(responseData.data.message) });
				}
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field) {
		let response = handleFormInputs(e, field, this.state.fields);
		this.setState({ response });

		if(field === "image"){
			this.setState({croppedImage: null})
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.setState({ imageSrc: reader.result });
			});
			reader.readAsDataURL(file);
		}
	}// end handleFormInputs()

	/** Manage input change event */
	handleChange = (fieldName) => (fieldVal) => {
		this.setState((prevState) => {
			const newFields = { ...prevState.fields, [fieldName]: fieldVal };

			// Reset department if location is changed
			if (fieldName === 'location_id') newFields.department_id = null;
			return { fields: newFields };
		});
	};

	/** Manage crop complete event */
	onCropComplete = (croppedImage) => {
		this.setState({ croppedImage });
	};

	render() {
		const { submitted,fields, errors,fileUrl,noImageUrl,jobTitleList,driverTierLevelList,locationList,companyList,croppedImage,imageSrc, isLoading , lightBoxShow, skillSetList} = this.state;
		const employeeID = (fields && fields.employee_id && fields.employee_id.value) ? fields.employee_id.value : "";

		return <>
			<BlockUi tag="div" blocking={isLoading}>
				<div className="dashboard-content">
					<div className="dashboard-heading d-flex justify-content-between align-items-center">
						<div className="left-heading">
							<h1>{employeeID ? translate("employee.update_employee") : translate("employee.add_employee")}</h1>
						</div>
						<div className="right-heading">
							<Link to="/employee" className={"btn btn-fill"}>
								{translate('system.back')}
							</Link>
						</div>
					</div>
					<div className="add-employee">
						<form className="adduser-form" onSubmit={(event) => this.handleAddUserFormSubmit(event, 'addUser')} noValidate>
							<div className="row ">
								<div className='col-6'>
									<div className="form-floating">
										<div className="form-group custom-upload">
											<label className="upload-source">
												<input type="file" name='file' id="file" className="d-none" accept=".png,.jpg,.jpeg" onChange={(event) => this.handleFormInputs(event, "image")} />
												<p>
													{fields.image && fields.image.file_name ?
														fields.image.file_name
													:
														<>
															{translate("system.select_image")}
														</>
													}
												</p>
												<span>{translate("system.upload")}</span>
											</label>
											{fileUrl && fields.old_image && fields.old_image.value &&(
												<div className="recent-img-box" onClick={()=>{ this.setState({lightBoxShow:true })}}>
													<img src={fileUrl + fields.old_image.value} alt="employee" onError={(event) => event.target.src = noImageUrl }/>
												</div>
											)}

										</div>
										<span className="error">{errors["image"]}</span>
										{imageSrc && !croppedImage ?
											<div className="comman-crop-popup">
												<CropImageComponent imageSrc={imageSrc} onCropComplete={this.onCropComplete} />
											</div>
										:null}
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="employee_number"
											type="text"
											name="employee_number"
											className="form-control"
											placeholder={translate("employee.employee_number")}
											defaultValue={fields["employee_number"] ? fields["employee_number"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "employee_number")}
										/>
										<span className="error text-danger">{errors["employee_number"]}</span>
										<label htmlFor="employee_number">{translate("employee.employee_number")}<span className="error text-danger">*</span></label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="first_name"
											type="text"
											name="first_name"
											className="form-control"
											placeholder={translate("employee.first_name")}
											defaultValue={fields["first_name"] ? fields["first_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "first_name")}
										/>
										<label htmlFor="first_name">{translate("employee.first_name")}<span className="error text-danger">*</span></label>
										<span className="error text-danger">{errors["first_name"]}</span>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="last_name"
											type="text"
											name="last_name"
											className="form-control"
											placeholder={translate("employee.last_name")}
											defaultValue={fields["last_name"] ? fields["last_name"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "last_name")}
										/>
										<span className="error text-danger">{errors["last_name"]}</span>
										<label htmlFor="last_name">{translate("employee.last_name")}<span className="error text-danger">*</span></label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<input
											id="mobile_number"
											type="text"
											name="mobile_number"
											className="form-control"
											placeholder={translate("employee.mobile_number")}
											defaultValue={fields["mobile_number"] ? fields["mobile_number"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "mobile_number")}
										/>
										<span className="error text-danger">{errors["mobile_number"]}</span>
										<label htmlFor="mobile_number">{translate("employee.mobile_number")}<span className="error text-danger">*</span></label>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<input
											id="email"
											type="email"
											name="email"
											className="form-control"
											placeholder={translate("employee.email")}
											defaultValue={fields["email"] ? fields["email"]["value"] : ""}
											onChange={(event) => this.handleFormInputs(event, "email")}
										/>
										<span className="error text-danger">{errors["email"]}</span>
										<label htmlFor="email">{translate("employee.email")}<span className="error text-danger">*</span></label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="job_title">{translate("employee.job_title")}<span className="error text-danger">*</span></label>}
												options={jobTitleList}
												value={fields.job_title ? fields.job_title : null}
												closeMenuOnSelect={true}
												onChange={this.handleChange("job_title")}
											/>
										</div>
										<span className="error">{errors["job_title"]}</span>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="company_name">{translate("employee.company_name")}<span className="error text-danger">*</span></label>}
												options={companyList}
												value={fields.company_name ? fields.company_name : null}
												closeMenuOnSelect={true}
												onChange={this.handleChange("company_name")}
											/>
										</div>
										<span className="error">{errors["company_name"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="location">{translate("employee.location")}<span className="error text-danger">*</span></label>}
												options={locationList}
												value={fields.location_id ? fields.location_id : null}
												closeMenuOnSelect={true}
												isMulti
												onChange={this.handleChange("location_id")}
											/>
										</div>
										<span className="error">{errors["location"]}</span>
									</div>
								</div>
								<div className="col-lg-6">
									<div className='form-floating'>
										<label htmlFor="department" className="form-label">{translate('calendar.department')} <span className="error text-danger">*</span></label>
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												value={fields.department_id ? fields.department_id : null}
												options={(fields.location_id) ? DEPARTMENT_DROPDOWN.filter(dept => fields.location_id && fields.location_id.some(loc => loc.department.includes(dept.value))) : []}
												onChange={this.handleChange("department_id")}
												closeMenuOnSelect={true}
												isMulti
												placeholder={<label htmlFor="department">{translate("employee.department")}<span className="error text-danger">*</span></label>}
											/>

										</div>
										<span className="error text-danger">{this.state.errors["department"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="permission_level">{translate("employee.permission_level")}<span className="error text-danger">*</span></label>}
												options={PERMISSION_LEVEL_DROPDOWN}
												value={fields.permission_level ? fields.permission_level : null}
												closeMenuOnSelect={true}
												onChange={this.handleChange("permission_level")}
											/>
										</div>
										<span className="error">{errors["permission_level"]}</span>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<div className="custom-form-group form-group">
											<Select
												className="custom-react-select"
												placeholder={<label htmlFor="job_title_type">{translate("employee.job_title_type")}<span className="error text-danger">*</span></label>}
												options={JOB_TITLE_TYPE_DROPDOWN}
												value={fields.job_title_type ? fields.job_title_type : null}
												closeMenuOnSelect={true}
												onChange={this.handleChange("job_title_type")}
											/>
										</div>
										<span className="error">{errors["job_title_type"]}</span>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<DatePicker
											className="form-control"
											selected={fields.hire_date && fields.hire_date.value ? fields.hire_date.value :null}
											onChange={(date) => {this.setState({ fields : {...fields, ...{hire_date:{value: date} }} }) }}
											placeholderText={translate("employee.select_hire_date")+"*"}
											maxDate={fields.termination_date && fields.termination_date.value ? fields.termination_date.value :null}
											dropdownMode="select"
											showMonthDropdown
											showYearDropdown
											customInput={<CustomInput />}
										/>
										<span className="error text-danger">{errors["hire_date"]}</span>
									</div>
								</div>
								<div className="col-6">
									<div className="form-floating">
										<DatePicker
											className="form-control"
											selected={fields.termination_date && fields.termination_date.value ? fields.termination_date.value :null}
											onChange={(date) => {this.setState({ fields : {...fields, ...{termination_date:{value: date} }} }) }}
											placeholderText={translate("employee.select_termination_date")}
											dropdownMode="select"
											showMonthDropdown
											showYearDropdown
											minDate={fields.hire_date && fields.hire_date.value ? fields.hire_date.value :null}
											customInput={<CustomInput />}
											isClearable
										/>
										<span className="error text-danger">{errors["termination_date"]}</span>
									</div>
								</div>
							</div>
							<div className="row mb-4">

								<div className="col-lg-6">
									<div className="custom-form-group form-group">
										<Select
											className="custom-react-select"
											placeholder={<label htmlFor="driver_tier_level">{translate("employee.driver_tier_level")}<span className="error text-danger">*</span></label>}
											options={driverTierLevelList}
											value={fields.driver_tier_level ? fields.driver_tier_level : null}
											closeMenuOnSelect={true}
											onChange={this.handleChange("driver_tier_level")}
											styles={customStyles}
											components={customComponents}
										/>
									</div>
									<span className="error">{errors["driver_tier_level"]}</span>
								</div>
								<div className="col-lg-6">
									<div className="custom-form-group form-group">
										<Select
											className="custom-react-select"
											value={fields.skill_set  ? fields.skill_set : null}
											options={skillSetList}
											onChange={this.handleChange("skill_set")}
											closeMenuOnSelect={true}
											placeholder={<label htmlFor="skill_set">{translate('skill_set.select_skill_set')}</label>}
											isClearable
										/>
										<span className="error text-danger">{errors["skill_set"]}</span>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-check-group">
										<label htmlFor="display_on_calendar">{translate("employee.display_on_calendar")}<span className="error text-danger">*</span></label>
										<div className="form-group checkbox-row employee-checkbox-row">
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="show_on_calendar" name="display_on_calendar" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "display_on_calendar")} checked={fields.display_on_calendar && fields.display_on_calendar.value === String(RADIO_YES) ? true : false} />
												<label className="form-check-label" htmlFor="show_on_calendar">{translate("employee.yes")}</label>
											</div>
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="not_show_on_calendar" name="display_on_calendar" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "display_on_calendar")} checked={fields.display_on_calendar && fields.display_on_calendar.value === String(RADIO_NO) ? true : false} />
												<label className="form-check-label" htmlFor="not_show_on_calendar">{translate("employee.no")}</label>
											</div>
										</div>
										<span className="error text-danger">{errors["display_on_calendar"]}</span>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="form-check-group">
										<label htmlFor="assigned_equipment">{translate("employee.assigned_equipment")}</label>
										<div className="form-group checkbox-row employee-checkbox-row">
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="assigned_equipment" name="assigned_equipment" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "assigned_equipment")} checked={fields.assigned_equipment && fields.assigned_equipment.value === String(RADIO_YES) ? true :false}  />
												<label className="form-check-label" htmlFor="assigned_equipment">{translate("employee.yes")}</label>
											</div>
											<div className="form-check form-check-inline custom-checkbox">
												<input className="form-check-input" type="radio" id="not_assigned_equipment" name="assigned_equipment" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "assigned_equipment")} checked={fields.assigned_equipment && fields.assigned_equipment.value === String(RADIO_NO) ? true :false}  />
												<label className="form-check-label" htmlFor="not_assigned_equipment">{translate("employee.no")}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row ">
								{fields.assigned_equipment && fields.assigned_equipment.value === String(RADIO_YES) ?
									<div className="col-lg-6">
										<div className="form-floating">
											<div className="custom-form-group form-group">
												<AsyncPaginate
													className="custom-react-select"
													value={fields.equipment_id ? fields.equipment_id : null}
													loadOptions={this.loadEquipmentOptions}
													onChange={this.handleChange("equipment_id")}
													placeholder={<label htmlFor="equipment_id">{translate("employee.select_equipment")}<span className="error text-danger">*</span></label>}
													additional={{ page: 1 }}
													styles={customStyles}
													isMulti
													components={customComponents}
												/>
												<span className="error">{errors["equipment_id"]}</span>
												<span className="error">{errors["equipment"]}</span>
											</div>
										</div>
									</div>
								:null}
							</div>

							<div className="employee-submit d-flex flex-wrap align-items-center">
								<button type={submitted ? "button" : "submit"} className="btn btn-fill">
									{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
								</button>
								<div>
									<Link to="/employee" className="btn btn-outline">{translate("system.cancel")}</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</BlockUi>

			{
				lightBoxShow &&
				<Lightbox
					mainSrc={fileUrl+fields.image.value}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
		</>
	}
}

