import React, { Component } from "react";
import { NavLink as Link} from "react-router-dom";
import { handleFormInputs, handleFormValidation } from '../helpers/common';
import { translate } from './../helpers/intl_helpers';

export default class ForgotPassword extends Component {

	constructor(props){
		super(props);
		this.state = {fields: {}, errors: {}, submitted: false};
		this.handleFormInputs 	=	this.handleFormInputs.bind(this);
		this.handleFormSubmit 	=  	this.handleFormSubmit.bind(this);
		window.scrollTo(0, 0);
	}

	/**
	* For manage form submit event
	*
	* @param e  as current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		this.setState({submitted: true}, ()=>{

			/** Api Requested Data **/
			const { email } = this.state.fields;
			let params = [{
				model	:	'registration',
				method	:	'forgotPassword',
				email	:	(email && email.value)	?	email.value	:"",
			}];

			/** Validation Rules **/
			let validations = {
				email: {"label": translate('user.email_address'), "rules" :["required", "email"]},
			}

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params).then(response=>{
				/** Remove disable attribute from submit button */
				this.setState({submitted: false});

				if(!response.success) return this.setState({ errors: response.errors});

				/** Redirect to login page */
				this.props.history.push("/admin-login");
			}).catch(err => console.log(err));
		});
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	render() {
		const {submitted, fields, errors} = this.state;

		return (
			<div className="login-box">
				<div className="sign-heading">
					<h4>{ translate('user.forgot_password') }</h4>
					<p>
						{translate('forgot_password.header_message')}
					</p>
				</div>
				<form onSubmit={(event) => this.handleFormSubmit(event, 'login')} noValidate>
                    <div className="form-floating mt-5">
						<input
							type="text"
							name="email"
							placeholder={translate('user.email_address')}
							className="form-control"
							onChange={(event) => this.handleFormInputs(event, "email")}
							value={ fields["email"] ? fields["email"]["value"] : ""}
						/>
						<label htmlFor="email">{translate("user.email_address")}<span className="error text-danger">*</span></label>
						<span className="error">{errors["email"]}</span>
					</div>
					<div className="d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
						<p>{ translate('user.already_registered') } <Link to="/admin-login" >{ translate('user.sign_in') }</Link></p>
						<button type={submitted ? "button" : "submit"} className="btn btn-fill" >
							{translate('system.submit') } {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
						</button>
					</div>
				</form>
			</div>
		);
	}
}